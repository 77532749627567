import { getSetting } from '../services/settings/settings'

const MILLISECONDS_IN_MONTH = 31 * 24 * 60 * 60 * 1000

export const shouldShowWelcomeDialog = (location) => {
  return !getSetting('formsV1')('welcomeDialogShown') || location?.welcome === 'convert'
}

export const shouldShowFormUsageDialog = (user) => {
  const currentDate = Date.now()
  const dateUseCaseAsked = Date.parse(user?.dateUseCaseAsked)

  return !user?.dateUseCaseAsked || Math.abs(currentDate - dateUseCaseAsked) >= 6 * MILLISECONDS_IN_MONTH
}

export const shouldLaunchClassicBuilder = () => {
  const getFormManagerSetting = getSetting('formManager')
  return  !getFormManagerSetting('showNewForms' || false)
}

export const removeHasModalClassname = () => {
  const app = document.querySelector('.app')
  app?.classList?.toggle('has-modal', null)
}
