import './Dummy.sass'

import React from 'react'
import PropTypes from 'prop-types'

import { range } from '../../utils/number'

export const Dummy = props => {
  const { cols, rows } = props

  return (
    <div className='dummy'>
      {range(rows).map(i => {
        return (
          <div key={`dummy-${i}`} className='dummy-row'>
            {range(cols).map(j => {
              return (
                <div key={`dummy-${j}`} className='dummy-cell' />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

Dummy.propTypes = {
  cols: PropTypes.number,
  rows: PropTypes.number
}

Dummy.defaultProps = {
  cols: 1,
  rows: 1
}

export default Dummy
