import { getFieldIds, getFieldProperty } from './entries'


export const getFieldSetByField = (fieldSets, field) => {
  return field && fieldSets.find(fieldSet => getFieldIds(fieldSet).includes(field.id))
}

export const getFieldSetOptions = (fieldSet) => {
  return fieldSet?.fields?.[0]?.options || {}
}


/****************
  Fieldset Choice
 ****************/
/*
A detailed example is provided below to help understand what those model functions do.
Currently the fieldSet structures for checkbox, radio and dropdown question look like this:

fieldSets: [{
  fields: [{
    title: 'Checkbox Question',
    choices: [{
      id: 100,
      title: 'First Choice'
    }, {
      id: 101,
      title: 'Second Choice'
    }, {
      id: 102,
      title: 'Third Choice'
    }]
  }]
}, {
  fields: [{
    title: 'Radio Question',
    id: 103
    choices: [{
      title: 'First Choice'
    }, {
      title: 'Second Choice'
    }, {
      title: 'Other',
      options: {
        isOther: true
      }
    }]
  }]
}, {
  fields: [{
    title: 'Dropdown Question',
    id: 104
    choices: [{
      title: 'First Choice'
    }, {
      title: 'Second Choice'
    }, {
      title: 'Third Choice'
    }]
  }]
}]

==============================================================================
For RADIO questions, there are a few scenarios when respondents submit entries
==============================================================================

-----------
Scenario #1
-----------
A respondent selected "First Choice" radio, and submitted an entry.
The entry data will look like this:

entry: {
  fields: [{
    id: 103,
    title: 'Radio Question',
    value: 'First Choice'
  }]
}

-----------
Scenario #2
-----------
A respondent selected "Other" radio, and typed "This is other choice" as input.
The entry data will look like this:

entry: {
  fields: [{
    id: 103,
    title: 'Radio Question',
    value: 'This is other choice'
  }]
}

-----------
Scenario #3
-----------
A respondent selected "Other" radio, and did not type anything as input
The entry data will look like this:

entry: {
  fields: [{
    id: 103,
    title: 'Radio Question',
    value: 'Other'
  }]
}

-----------
Scenario #4
-----------
After the above respondents submitted their entries,
Form owner changed "First Choice" to "First Choice NEW", and "Other" to "Other NEW"
The entry data will look like this:

In this case, the title of these two choices will be updated in the fieldSet data
While the values in those entries will not be changed

Since there is no way to track what the old choice titles were, for Scenario #1, #2 and #3
we will show their selections under "Other (NEW)" radio, with the old choice title as input

*/

export const getChoiceProperty = (choice, propertyName) => {
  return choice?.[propertyName] || ''
}

export const getFieldSetChoices = (fieldSet) => {
  return fieldSet?.fields?.[0]?.choices || []
}

export const getSelectedChoice = (fieldSet, field) => {
  // According the example above, a choice is selected if any of the two conditions is met
  // 1) the entry field value matches the title of the choice
  // 2) the entry field value does not match the titles of any choices, but the choice is "Other" type with user input
  const choices = getFieldSetChoices(fieldSet)
  const matchedChoice = choices.find(choice => choice.title === getFieldProperty(field, 'value'))
  const otherChoice = choices.find(isOtherChoice)
  return matchedChoice || otherChoice || {}
}

export const isOtherChoice = (choice) => {
  return choice?.options?.isOther || false
}