import { createStore, applyMiddleware, compose } from 'redux'
import createRootReducer from '../reducers'
import history from '../services/history'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

import DevTools from '../components/DevTools'


export default createStore(
  createRootReducer(history),
  {},
  compose(
    applyMiddleware(
      thunk,
      routerMiddleware(history)
    ),
    DevTools.instrument()
  )
)

