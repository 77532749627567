import SplunkOtel from '@splunk/otel-web'
import { isTruthy } from '../../utils/boolean'


export const initSplunkOtel = () => {
  if (isTruthy(process.env.REACT_APP_OTEL_TRACE_ENABLED)) {
    SplunkOtel.init({
      beaconUrl: process.env.REACT_APP_SPLUNK_BEACON_URL,
      rumAuth: process.env.REACT_APP_SPLUNK_RUM_TOKEN,
      app: process.env.REACT_APP_OTEL_SERVICE_NAME,
      environment: process.env.REACT_APP_ENVIRONMENT,
      version: process.env.REACT_APP_VERSION,
      debug: isTruthy(process.env.REACT_APP_SPLUNK_DEBUG)
    })
  } else {
    // eslint-disable-next-line no-console
    console.warn(`tracing skipped with OTEL_TRACE_ENABLED=${process.env.REACT_APP_OTEL_TRACE_ENABLED}`)
  }
}

export const setOtelUserMetadata = user => {
  SplunkOtel.setGlobalAttributes({
    'user.id': user?.id,
    'user.email': user?.email,
    'user.name': user?.name,
  })
}
