
export const ENTER = 'Enter'
export const ESCAPE = 'Escape'
export const ESC = 'Esc'
export const SPACE = ' '
export const TAB = 'Tab'

export const KEY_A = 'a'
export const KEY_B = 'b'
export const KEY_I = 'i'
export const KEY_L = 'l'
export const KEY_U = 'u'
export const KEY_Z = 'z'
