import './UpgradeModal.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import {
  Align,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@sm/wds-react'

import { UpgradeButton } from '../../../components/Buttons/UpgradeButton'

export const UpgradeModal = props => {
  const { t, handleClose, header } = props

  return (
    <div className='upgrade-modal'>
      <ModalHeader
        header={(
          <h1 className='wds-modal__title'>{header}</h1>
        )}
      />

      <ModalBody>
        <h3 className='text-center pb-2'>
          {t('upgrade_modal_description')}
        </h3>

        <div className='text-center mb-5'>
          <UpgradeButton
            size='md'
            location='Upgrade Modal'
            upgradeTextKey='UPGRADE_FOR_MORE'
            tabIndex={-1}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Align placement='right'>
          <Button
            variant='ghost'
            color='secondary'
            onClick={handleClose}
          >
            {t('CLOSE')}
          </Button>
        </Align>
      </ModalFooter>
    </div>
  )
}

UpgradeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.node,
  t: PropTypes.func
}

export default withTranslation('modals')(UpgradeModal)
