import './ToastBodies.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Typography } from '@sm/wds-react'


export const PrepareDownloadBody = ({ onPromiseCancel, t }) => (
  <div className='wds-flex wds-flex--x toast-body-prepare' >
    <Typography className='toast-body-prepare-message' color='light'>
      {t('export_loading_toast')}
    </Typography>

    <Typography
      className='toast-cancel'
      color='light'
      onClick={onPromiseCancel}
    >
      <b>
        {t('CANCEL')}
      </b>
    </Typography>
  </div>
)

PrepareDownloadBody.propTypes = {
  t: PropTypes.func.isRequired,
  onPromiseCancel: PropTypes.func
}

export default withTranslation('global')(PrepareDownloadBody)
