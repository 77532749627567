
// Only call the supplied callback when it
// was a click event or the Enter key was pressed
export const a11yClick = func => {
  return event => {
    if (event.key === 'Enter' || !event.key) {
      func(event)
    }
  }
}

// Only call the supplied callback when
// the specified key is pressed
export const keyPressed = (key, func) => {
  return event => {
    if (event.key === key) {
      func(event)
    }
  }
}

// Immediately blur the target component before
// running the supplied callback
export const blurClick = func => {
  return event => {
    if (event.currentTarget) {
      event.currentTarget.blur()
      func(event)
    }
  }
}

export const debounce = (func, delay) => {
  let debounceFunc
  return event => {
    clearTimeout(debounceFunc)
    debounceFunc = setTimeout(() => func(event), delay)
  }
}

export const throttle = (func, delay) => {
  let throttled
  return event => {
    if (!throttled) {
      func(event)
      throttled = true
      setTimeout(() => throttled = false, delay)
    }
  }
}
