import './EditableField.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { IconCheck, IconPencilPaper, IconX } from '@sm/wds-icons'
import { Icon } from '../Icon'
import { keyPressed } from '../../utils/a11y'

export class EditableField extends React.Component {

  handleEdit = () => {
    const { handleEdit } = this.props
    const { newValue } = this.state

    handleEdit(newValue)
    this.setState({ isEditing: false })
  }

  handleCancel = (value) => () => {
    const { handleCancel } = this.props

    if (handleCancel) {
      handleCancel()
    }

    this.setState({ isEditing: false, newValue: value })
  }

  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      newValue:  props.value
    }
  }

  componentDidMount() {
    const { editing } = this.props
    const input = this.input

    if (editing && input) {
      input.focus()
      input.setSelectionRange(0, input.value.length)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const input = this.input

    if ((this.isUncontrolledEditing(prevState) || this.isControlledEditing(prevProps)) && input) {
      input.focus()
      input.setSelectionRange(0, input.value.length)
    }
  }

  isControlledEditing = (prevProps) => {
    const { editing } = this.props
    return editing !== prevProps.editing && editing
  }

  isUncontrolledEditing = (prevState) => {
    const { isEditing } = this.state
    return isEditing !== prevState.isEditing && isEditing
  }

  render() {
    const {
      t,
      canEdit,
      editLabel,
      editing,
      mode,
      pending,
      value
    } = this.props

    const { isEditing, newValue } = this.state

    const isControlled = editing === true || editing === false
    const showEditField = editing || isEditing

    const inputProps = {
      className: 'editable-field-input',
      ref: input => this.input = input,
      onChange: e => this.setState({ newValue: e.target.value }),
      onKeyPress: keyPressed('Enter', this.handleEdit),
      onKeyUp: keyPressed('Escape', this.handleCancel(value)), // keyPress does not work for escape key
      value: newValue
    }

    if (!canEdit) {
      return (
        <div className='editable-field'>
          {value}
        </div>
      )
    }

    return (
      <div className='editable-field'>
        {!showEditField &&
          <React.Fragment>
            <div
              className='editable-field-text'
              title={value}
            >
              {value}
            </div>

            {!isControlled &&
              <Icon
                className='editable-field-edit-icon'
                clickable
                id='files-edit-icon'
                handleClick={() => this.setState({ isEditing: true, newValue: value })}
                label={editLabel}
              >
                <IconPencilPaper />
              </Icon>
            }
          </React.Fragment>
        }

        {showEditField &&
          <React.Fragment>
            {mode === 'input' &&
              <input {...inputProps} id='value-edit-input' disabled={pending} />
            }

            {mode === 'textarea' &&
              <textarea {...inputProps} id='files-edit-textarea' disabled={pending} />
            }

            <Icon
              className='editable-field-edit-icon'
              clickable
              id='value-edit-cancel'
              handleClick={this.handleCancel(value)}
              label={t('Cancel')}
              isDisabled={pending}
            >
              <IconX />
            </Icon>

            <Icon
              className='editable-field-edit-icon'
              clickable
              id='value-edit-save'
              handleClick={this.handleEdit}
              label={t('Save')}
              isDisabled={pending}
            >
              <IconCheck />
            </Icon>
          </React.Fragment>
        }
      </div>
    )
  }
}

EditableField.propTypes = {
  canEdit: PropTypes.bool,
  editLabel: PropTypes.string,
  editing: PropTypes.bool, // Makes this element 'controlled'
  handleCancel: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([ 'input', 'textarea' ]),
  pending: PropTypes.bool,
  t: PropTypes.func,
  value: PropTypes.string.isRequired
}

EditableField.defaultProps = {
  canEdit: true,
  pending: false,
  mode: 'input'
}

export default withTranslation('global')(EditableField)
