
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon
} from '@sm/wds-react'
import { KEY_A, KEY_Z } from '../../../constants/keyboardEvents'

export class ConfigContent extends React.Component {

  handleKeyPress = e => {
    if (e.key >= KEY_A && e.key <= KEY_Z) {
      e.preventDefault()
    }
  }

  render() {
    const { configs, onSave, onChange } = this.props
    return (
      <div className='config-content'>
        {configs.map(({ key, label, value, isNumber, defaultValue }) => {
          return (
            <div className='content-item' key={key}>
              <span className='item-title'>{label}</span>
              <div className='item-input'>
                <InputGroup>
                  <Input
                    {...(isNumber && {
                      onKeyPress: this.handleKeyPress,
                      type: 'number'
                    })}
                    onChange={e => onChange(key, isNumber ? Number(e.target.value) || defaultValue : e.target.value)}
                    value={value || defaultValue}
                  />
                  <InputGroupAddon>
                    <Button
                      size='sm'
                      onClick={() => onSave(key, value)}
                    >
                      Save
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

ConfigContent.propTypes = {
  configs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ConfigContent
