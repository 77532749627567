import React from 'react'
import { i18n } from '../services/i18n'
import { PrepareDownloadBody, ReadyForDownloadBody } from '../containers/Toasts/exports'


export const getToastConfig = (handlePromiseCancel, options = {}) => {
  const { getErrorMessage, hideOnErrorKey } = options
  return {
    loadingMessage: i18n.t('global:export_loading_toast'),
    successMessage: i18n.t('global:export_success_head_toast'),
    errorMessage: i18n.t('global:export_failure_toast'),
    loadingOptions: {
      title: <PrepareDownloadBody onPromiseCancel={handlePromiseCancel} />
    },
    successOptions: {
      dismissible: true,
      autoClose: false,
      title: i18n.t('global:export_success_head_toast'),
      body: <ReadyForDownloadBody />
    },
    errorOptions: {
      getErrorMessage: e => getErrorMessage(e, i18n.t),
      hideOnError: e => (e === hideOnErrorKey || e?.message === hideOnErrorKey),
      icon: 'warning'
    }
  }
}
