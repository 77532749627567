import isEmpty from 'lodash.isempty'
import { FILE_SIZE_LIMIT, FILE_UPLOAD_STATUS, IMAGE_EXTENSIONS } from '../constants/files'


export const isImage = ext => {
  return IMAGE_EXTENSIONS.includes(ext)
}

export const getFileType = file => {
  if (file.type === 'folder') {
    return 'folder'
  }
  if (file.extension) {
    return file.extension.replace('.', '')
  }
  if (file.name) {
    return file.name.split('.').pop().toLowerCase()
  }
  return 'unknown'
}

const getFileFromFiles = (fileId, files) => {
  if (!files || !files?.length) {
    return null
  }
  for (let i = 0; i < files.length; i++) {
    const currentFile = files[i]
    if (currentFile.id === fileId) {
      return currentFile
    }
    if (currentFile?.files) {
      const childFile = getFileFromFiles(fileId, currentFile.files)
      if (childFile) {
        return childFile
      }
    }
  }
}

export const getFilesTotalSize = (fileIds, files) => { //eslint-disable-line
  if (!fileIds || !fileIds?.length) {
    return 0
  }
  let totalSize = 0
  fileIds.forEach(fileId => {
    const file = getFileFromFiles(fileId, files)
    totalSize += file ? file.size : 0
  })
  return totalSize
}

export const getSelectedFolderId = (selectedFolders, folders) => {
  let id = selectedFolders.id
  if (!id) {
    const selectedFolder = folders[selectedFolders.parentId].files.find(folder => folder.filename === selectedFolders.filename)
    id = selectedFolder && selectedFolder.id
  }
  return id
}

export const isFileRequestPending = (files) => {
  return files.fileRequest && files.fileRequest.status === 'pending'
}

export const isFileUpload = (files) => {
  return files.fileRequest && files.fileRequest.action === 'upload'
}

export const isSingleFileUpload = (files) => {
  return files.fileRequest && files.fileRequest.action === 'upload_single_file'
}

export const isFileRequestedFromMenu = (files) => {
  return files.fileRequest && files.fileRequest.location === 'menu'
}

export const isFolderEmpty = folder => {
  return folder ? folder.files.length === 0 : true
}

export const isFolderOpened = (id, folders) => {
  return folders[id] ? folders[id].status === 'open' : false
}

export const isRefreshPending = (files) => {
  return files.isRefreshPending === true
}

export const isSelected = (selectedFolder, id, filename, parentId) => {
  return (selectedFolder.id && id) ? selectedFolder.id === id :
    (filename === selectedFolder.filename && selectedFolder.parentId === parentId)
}

export const isFileSizeOverLimit = (files) => {
  return files.findIndex(file => file.size > FILE_SIZE_LIMIT) !== -1
}

export const shouldBeExcluded = (folder, excludedFolders) => {
  return excludedFolders ? excludedFolders.findIndex(id => folder.id === id) !== -1 : false
}

export const shouldDisplayEditingField = (newFolder, parentId) => {
  return newFolder && newFolder.parentId === parentId && newFolder.status === 'editing'
}

export const shouldRefreshFolder = (refreshFolder) => {
  return refreshFolder && ('id' in refreshFolder)
}

export const shouldUpdateFilename = (newName, oldName) => {
  return !isEmpty(newName.trim()) && newName !== oldName
}

export const shouldUpdateDescription = (newDescription, oldDescripion) => {
  return newDescription !== oldDescripion
}

export const toAPIParentId = (parentId) => {
  return parentId === 'root' ? '' : parentId
}

export const getCompletedUploadCount = (files) => {
  if (!files?.length) {
    return 0
  }

  let count = 0
  files.forEach(file => {
    if (file.status === FILE_UPLOAD_STATUS.COMPLETE) {
      count++
    }
  })
  return count
}

export const isBulkUploadComplete = (files) => {
  if (!files?.length) {
    return true
  }

  let complete = true
  for (let i= 0; i < files.length; i++) {
    if (files[i].status !== FILE_UPLOAD_STATUS.COMPLETE
      && files[i].status !== FILE_UPLOAD_STATUS.ERROR) {
      complete = false
      break
    }
  }
  return complete
}

export const getUpdatedUploadFileList = (oldFileList, newFileList, status, fileId) => {
  return oldFileList.map(oldFile => {
    const file = newFileList.find(newFile => newFile.name === oldFile.name)
    if (file && oldFile.status !== FILE_UPLOAD_STATUS.COMPLETE) {
      oldFile.fileId = oldFile?.fileId || fileId
      oldFile.status = status
    }
    return oldFile
  })
}

export const getUpdatedUploadFile = (file, fileList) => {
  return fileList.find(oldFile => oldFile.name === file.name)
}

export const getSelectedFiles = (files, selections, fileIds) => {
  const selectedIds = selections.files.length > 0 ? selections.files : fileIds
  const rootLevelFiles = files.files && files.files.filter(f => selectedIds.includes(f.id))
  if (isEmpty(rootLevelFiles)) {
    if (selectedIds.includes(files.selectedFile?.id)) {
      return [ files.selectedFile ]
    }
    return files.selectedFile && files.selectedFile.files && files.selectedFile.files.filter(f => selectedIds.includes(f.id))
  }
  return rootLevelFiles
}
