import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal as WDSModal } from '@sm/wds-react'

import { UpgradeModal } from '../UpgradeModal'

export class Modal extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  render() {
    const {
      children,
      isUpgrade,
      header,
      size,
      ...rest
    } = this.props

    const content = (
      isUpgrade ? (
        <UpgradeModal
          header={header}
          handleClose={this.handleClose}
        />
      ) : children
    )

    return (
      <WDSModal
        show={true}
        size={size}
        onClose={this.handleClose}
        {...rest}
      >
        {content}
      </WDSModal>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  isUpgrade: PropTypes.bool,
  header: PropTypes.string,
  size: PropTypes.string
}

Modal.defaultProps = {
  isUpgrade: false,
}

const select = state => ({
  modal: state.modal
})

export default connect(select)(Modal)
