import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Align
} from '@sm/wds-react'

import { Modal } from '../Modal'

export class ConfirmModal extends React.Component {

  closeModal = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  render() {
    let {
      cancelButton,
      children,
      buttonAlign,
      hasFooter,
      header,
      okayButton,
      ...rest
    } = this.props

    return (
      <Modal {...rest}>
        <ModalHeader
          header={(
            <span className='wds-modal__title'>{header}</span>
          )}
        />

        <ModalBody>
          {children}
        </ModalBody>

        {hasFooter && (
          <ModalFooter>
            <Align placement={buttonAlign}>
              {cancelButton}
              {okayButton}
            </Align>
          </ModalFooter>
        )}
      </Modal>
    )
  }
}

ConfirmModal.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func,
  hasFooter: PropTypes.bool,
  header: PropTypes.node,
  cancelButton: PropTypes.node,
  okayButton: PropTypes.node,
  buttonAlign: PropTypes.string,
  t: PropTypes.func
}

ConfirmModal.defaultProps = {
  buttonAlign: 'right',
  cancelButton: null,
  hasFooter: true,
  okayButton: null
}

const select = state => ({
  modal: state.modal
})

export default connect(select)(ConfirmModal)

