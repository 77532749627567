import HttpStatus from 'http-status-codes'
import moment from 'moment'

import { api } from '../services/api'
import { initializeUsabilla } from '../services/telemetry/usabilla'
import { mixpanel } from '../services/telemetry/mixpanel'
import { setOtelUserMetadata } from '../services/telemetry/splunk'
import store from '../store/reduxStore'

export const getUser = () => {
  return dispatch => {
    api.get('/user')
      .then(({ data }) => {
        dispatch({ type: 'SET_CURRENT_USER', data })
        setOtelUserMetadata(data)
        mixpanel.identify(data)
        mixpanel.registerUserSupers(data)
        initializeUsabilla(data)
      })
  }
}

export const optOutOfBeta = () => {
  return dispatch => {
    api.put('/features/beta', { enabled: false })
      .then(() => {
        dispatch({ type: 'OPTED_OUT_OF_BETA' })
      })
      .catch(() => {})
  }
}

export const getSystemNotifications = () => {
  return dispatch => {

    api.get('/system_notifications')
      .then(({ data }) => {
        dispatch({ type: 'SET_SYSTEM_NOTIFICATIONS', data: {
          systemNotifications: data.systemNotifications
        }})
      })
      .catch(() => {})
  }
}

export const markSystemNotificationAsRead = id => {
  return dispatch => {
    const state = store.getState()

    dispatch({ type: 'SET_SYSTEM_NOTIFICATIONS', data: {
      systemNotifications: state.user.systemNotifications.filter(notification => notification.id !== id)
    }})

    api.post('/notifications/requests/mark_read', { system_notification_ids: [ id ]})
      .catch(() => {})
  }
}

export const sendVerificationEmail = ({ successMessage, warningMessage, errorMessage }) => {
  return dispatch => {
    api.post('/auth/requests/email_verification')
      .then(() => {
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'success',
          title: successMessage
        }})
      })
      .catch(err => {
        const status = err.response && err.response.status
        if (status === HttpStatus.FORBIDDEN) {
          return dispatch({ type: 'SHOW_TOAST', data: {
            type: 'warning',
            title: warningMessage
          }})
        }
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'error',
          title: errorMessage
        }})
      })
  }
}

export const sendUserUseCase = data => {
  return async dispatch => {
    try {
      // remove empty values from request body
      Object.keys(data).forEach(key => (data[key] === null || data[key]?.length === 0) && delete data[key])

      await api.post('/user/requests/update_use_case', data)
      dispatch({ type: 'SET_CURRENT_USER', data: { dateUseCaseAsked: moment().format('YYYY-MM-DD') }})
    } catch (e) {
      // will not prevent user from closing the modal for now
    } finally {
      dispatch({ type: 'CLOSE_MODAL' })
    }
  }
}
