import { default as _store } from 'store'

export const store = {
  get: (key, def=undefined) => {
    const val = _store.get(key)
    return val === undefined ? def: val
  },
  set: (key, value) => {
    _store.set(key, value)
  },
  remove: (key) => {
    _store.remove(key)
  }
}

/**
* KEYS
*/

const key = text => `WUFOO_KEY_${text}`

// Authentication
export const JWT_TOKEN = key('JWT_TOKEN')
export const JWT_EXPIRY = key('JWT_EXPIRY')

// wutools
export const FLAGS = key('FLAGS')

// user settings
export const USER_SETTING = key('USER_SETTING')
