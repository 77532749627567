import moment from 'moment'
import {
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_DMY,
  DATE_FORMAT_MDY,
  DATE_FORMAT_YMD
} from '../constants/formats'


const API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

// Adds an hour to the timezone offset if in DST
export const adjustTimezoneForDST = (timezoneOffset, dt=null) => {
  let tz
  if (dt) {
    tz = moment(new Date(dt)).local().isDST() ? timezoneOffset + 1 : timezoneOffset
  }
  else {
    tz = moment().local().isDST() ? timezoneOffset + 1 : timezoneOffset
  }
  return tz
}

// Take a user time and convert it back to UTC for the API
export const apiDateTime = datetime => {
  const _datetime = datetime || moment()
  return moment.utc(_datetime)
}

// Format datetime in the format expected by the API
export const apiFormat = datetime => {
  return datetime.format(API_DATETIME_FORMAT)
}

// Take a datetime in UTC and return a datetime in the specified timezone
export const getDateTimeForTimeZone = (datetime, tz=0) => {
  // Since our timezones are stored WITH the DST added offset,
  // we need to compensate
  const adjustedTZ = adjustTimezoneForDST(tz, datetime)
  return moment.utc(datetime).utcOffset(adjustedTZ)
}

export const getNormalizedDateString = (date, format) => {
  const array = date.split('')
  if (array.length === DATE_FORMAT_DEFAULT.length - 2 && array.indexOf('/') === -1) {
    const slashSymbol = '/'
    let firstSlashPosition = 2
    let secondSlashPosition = 5

    switch (format) {
      case DATE_FORMAT_MDY:
      case DATE_FORMAT_DMY:
        break
      case DATE_FORMAT_YMD:
        firstSlashPosition = 4
        secondSlashPosition = 7
        break
      default: {
        break
      }
    }
    if (array.length > firstSlashPosition && array[firstSlashPosition] !== slashSymbol) {
      array.splice(firstSlashPosition, 0, slashSymbol)
    }
    if (array.length > secondSlashPosition && array[secondSlashPosition] !== slashSymbol) {
      array.splice(secondSlashPosition, 0, slashSymbol)
    }
  }
  return array.join('')
}

export const userDateTime = (datetime, format, tz=0) => {
  const dt = moment.utc(datetime).utcOffset(tz)
  const dtInTimeZone = moment(dt).local().isDST() ? dt.add(1, 'hour') : dt
  return dtInTimeZone.format(format)
}
