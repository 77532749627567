import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isEqual from 'lodash.isequal'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { AppErrorBoundary } from '../../../components/AppErrorBoundary'
import { BillingBanner } from '../../../components/Banners/BillingBanner'
import DevTools from '../../../components/DevTools'
import { EmailVerificationBanner } from '../../../components/Banners/EmailVerificationBanner'
import { Footer } from '../../../components/Footer'
import { LegacyRedirect } from '../../LegacyRedirect'
import { MobileView } from '../../../components/MobileView'
import { Status404 } from '../../../components/Status'
import { SystemNotifications } from '../../SystemNotifications'
import { WuTools } from '../../../containers/WuTools'

import { Modals } from '../../Modals'
import { Sheets } from '../../Sheets'
import { Toasts } from '../../Toasts'
import { Header } from '../../Header'

import { urls, isLoggedOutExperience, shouldShowCookieBanner } from '../../../utils/urls'
import { initializeAuthentication, isMobileBypassEnabled } from '../../../services/api'
import { OneTrustLoader } from '../../../services/oneTrust'
import { split } from '../../../services/codesplitting'
import { Loader } from '../../../components/Loader'
import { APP_STATE_PROPS, FLAGS_STATE_PROPS, MODAL_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class InnerApp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
      isMobileBypassed: isMobileBypassEnabled()
    }

    const dontRerender = (prevProps, nextProps) => {
      return prevProps?.location?.pathname === nextProps?.location?.pathname
    }

    this.AppContent = React.memo(() => (
      <React.Fragment>
        <main tabIndex='-1' ref={main => this.main = main}>
          <BillingBanner />
          <EmailVerificationBanner />
          <SystemNotifications />

          <Switch>
            <Route exact path={urls.HOME} component={split(() => import('../../Home/Home'))} />
            <Route exact path={urls.LOGOUT} component={split(() => import('../../Logout/Logout'))} />
            <Route path={urls.FORM_MANAGER_OLD} component={split(() => import('../../FormManagerWrapper/FormManagerRouter/FormManagerRouter'))} />
            <Route path={urls.FORM_MANAGER} component={split(() => import('../../FormManagerWrapper/FormManagerRouter/FormManagerRouter'))} />
            <Route path={urls.ENTRY_MANAGER} component={split(() => import('../../EntryManager/EntryManagerRouter/EntryManagerRouter'))} />
            <Route path={urls.FILE_MANAGER} component={split(() => import('../../FileManager/FileManager'))} />
            <Route path={urls.REDIRECT} component={LegacyRedirect} />
            <Route path={urls.HOME} component={Status404} />
          </Switch>

        </main>

        <Modals />
        <Sheets />
        <Toasts />
      </React.Fragment>
    ), dontRerender)
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    const isLoggedIn = initializeAuthentication(!isLoggedOutExperience(location))
    dispatch({ type: 'SET_AUTHENTICATED', data: isLoggedIn })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { app, location, modal } = this.props
    const { hasError } = this.state

    const errorSwitched = !isEqual(hasError, nextState.hasError)
    const hasAppChanged = !isEqual(app, nextProps.app)
    const hasModalChanged = !isEqual(modal, nextProps.modal)
    const hasLocationChanged = location.pathname !== nextProps.location.pathname

    if (hasError && (hasAppChanged || hasLocationChanged)) {
      this.setState({ hasError: false })
    }

    return errorSwitched || hasAppChanged || hasLocationChanged || hasModalChanged
  }

  handleError = () => {
    this.setState({ hasError: true })
  }

  handleSkip = () => {
    if (this.main) {
      this.main.focus()
    }
  }

  render() {
    const { app, flags, location, modal } = this.props
    const { hasError, isMobileBypassed } = this.state
    const isLoggedOut = isLoggedOutExperience(location)
    const showCookieBanner = shouldShowCookieBanner(location)

    if (!app.isAuthenticated) {
      return <Loader loading={true} fixed={true} />
    }

    return (
      <React.Fragment>
        <Helmet>
          {isMobileBypassed && (
            <meta name='viewport' content='width=992' />
          )}
        </Helmet>
        <AppErrorBoundary
          handleError={this.handleError}
          forceClear={!hasError}
        >
          {isLoggedOut && (
            <Switch>
              <Route exact path={urls.LOGIN} component={split(() => import('../../Login/Login'))} />
            </Switch>
          )}
          {!isLoggedOut && (
            <div
              key={app.language}
              className={classnames(
                'app',
                { 'mobile-bypassed': isMobileBypassed },
                { 'has-modal':  modal?.activeModal }
              )}
            >
              <Header
                hasError={hasError}
                handleSkip={this.handleSkip}
              />
              <div className='app-content'>
                <this.AppContent location={location} />
              </div>

              <Footer currentLanguage={app.language} />

              {flags.isEnabled && flags.configs.debugMode && <DevTools />}
              {flags.isEnabled && <WuTools />}
            </div>
          )}

          {!isLoggedOut && <MobileView />}
          {showCookieBanner && <OneTrustLoader />}
        </AppErrorBoundary>
      </React.Fragment>
    )
  }
}

InnerApp.propTypes = {
  app: PropTypes.shape(APP_STATE_PROPS).isRequired,
  dispatch: PropTypes.func.isRequired,
  flags: PropTypes.shape(FLAGS_STATE_PROPS).isRequired,
  location: PropTypes.object.isRequired,
  modal: PropTypes.shape(MODAL_STATE_PROPS).isRequired,
}

const select = state => ({
  app: state.app,
  flags: state.flags,
  modal: state.modal,
})

InnerApp = withRouter(connect(select)(InnerApp))

export default InnerApp
