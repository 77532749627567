export const getInitials = (name) => {
  const splitName = name.split(/\s+/g).map(i => i[0])

  if (name.includes('@')) {
    return name.split('@').shift().slice(0, 2).toUpperCase()
  } else {
    return splitName.length > 1 ?
      splitName.shift()[0].concat(splitName.pop()[0]).toUpperCase() : name.slice(0, 1).toUpperCase()
  }
}
