import './ChangeLanguage.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { i18n } from '../../services/i18n'

export const ChangeLanguage = props => {
  const { t, currentLanguage } = props

  const handleChange = lang => {
    return event => {
      event.preventDefault()
      i18n.changeLanguage(lang)
    }
  }

  const languages = [{
    name: t('english'),
    code: 'en'
  }/*, {
    name: t('spanish'),
    code: 'es'
  }*/]

  return (
    <div className='change-language'>
      <label>
        {t('language')}:
      </label>

      {languages.map(l => {
        if (l.code === currentLanguage) {
          return <span key={l.code} className='disabled-link' id={`footer-${(l.name).toLowerCase()}`}>{l.name}</span>
        }

        return (
          <a
            key={l.code}
            href='#/'
            onClick={handleChange(l.code)}
          >
            {l.name}
          </a>
        )
      })}


    </div>
  )
}

ChangeLanguage.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  t: PropTypes.func
}

export default withTranslation('global')(ChangeLanguage)
