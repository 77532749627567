import './FolderRow.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FilenameCell from '../FilenameCell/FilenameCell'

import { getFileType } from '../../../models/files'
import { FILE_ROW_PROPS } from '../../../constants/propTypes/coreWebPropTypes'
import { humanFileSize } from '../../../utils/file'
import { keyPressed } from '../../../utils/a11y'

export class FolderRow extends React.Component {

  render() {
    const {
      folder,
      level,
      parentId,
      isOpen,
      isSelected,
      handleExpand,
      handleSelect,
      renderIndent
    } = this.props

    const classNames = classnames(
      'folder-row',
      { 'selected' : isSelected }
    )

    const ext = getFileType(folder)

    return (
      <div
        className={classNames}
        onClick={() => handleSelect(folder.id, folder.filename, parentId)}
        onKeyPress={keyPressed('Enter', () => {
          handleSelect(folder.id, folder.filename, parentId)
          handleExpand(folder.id, parentId)
        })}
      >
        <div>
          {renderIndent(level)}
          <FilenameCell
            childCount={folder.totalCount || folder.childCount}
            ext={ext}
            filename={folder.filename}
            handleClick={() => handleExpand(folder.id, parentId)}
            isFocus={isOpen}
            link={folder.link}
            type={folder.type}
          />
        </div>
        <div>{humanFileSize(folder.size)}</div>
      </div>
    )
  }
}

FolderRow.propTypes = {
  folder: PropTypes.shape(FILE_ROW_PROPS),
  level: PropTypes.number,
  isOpen: PropTypes.bool,
  isSelected: PropTypes.bool,
  handleExpand: PropTypes.func,
  handleSelect: PropTypes.func,
  parentId: PropTypes.string,
  renderIndent: PropTypes.func,
}

export default FolderRow
