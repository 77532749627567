import flatten from 'lodash.flatten'
import intersectionWith from 'lodash.intersectionwith'
import isEmpty from 'lodash.isempty'
import { isNumber } from '../utils/number'
import { isString } from '../utils/string'
import numeral from 'numeral'
import moment from 'moment'
import union from 'lodash.union'
import { getLabel } from '../utils/translations'
import { getSetting } from '../services/settings/settings'
import { adjustTimezoneForDST, apiDateTime, apiFormat, getDateTimeForTimeZone } from '../utils/datetime'
import { humanFileSize } from '../utils/file'
import { getCurrencySymbol } from '../utils/currency'


export const PAYMENT_STATUS_LIST = [ 'Paid', 'Unpaid', 'Pending', 'Cancelled', 'Declined', 'Refunded' ]
export const SIGNATURE_STATUS_LIST = [ 'Signed', 'Unsigned', 'Revoked' ]
export const COMPLETE_SUBMISSION_LIST = [ 'Complete', 'Incomplete' ]
export const ASSERTION_LIST = [ 'Not_Empty', 'Empty' ]

export const MAXIMUM_ENTRIES_TO_EMAIL = 20
export const EMAIL_SEPARATOR = ','

const EXTRACT_ID_FROM_EXPRESSION_REGEX = /fields\[id\:(\d+)\]/ // eslint-disable-line no-useless-escape
const NUMBER_TYPE_LIST = [ 'number', 'price', 'rating', 'id', 'payment.purchase_total', 'last_page' ]
const DATE_TYPE_LIST = [ 'date' ]
const DATETIME_TYPE_LIST = [ 'dateCreated', 'dateUpdated' ]
const TIME_TYPE_LIST = [ 'time' ]
const BOOLEAN_TYPE_LIST = [ 'complete_submission' ]
const MAX_LENGTH_FIELD_TITLE = 100
const ASSERT_CONNECTOR_LIST = [ 'is' ]
const EQUAL_CONNECTOR_LIST = [ '==' ]
const EQUAL_DATETIME_CONNECTOR_LIST = [ 'onday' ]
const MORE_CONNECTOR_LIST = [ '>', '>=' ]
const STARTOF_DAY_CONNECTOR_LIST = [ '==', 'onday', '>=', '<' ]
export const LESS_CONNECTOR_LIST = [ '<', '<=' ]
export const DEFAULT_FIELD_LIST = [ 'id', 'dateCreated', 'createdBy', 'dateUpdated', 'updatedBy', 'complete_submission', 'last_page' ]
export const PAYMENT_FIELD_LIST = [ 'payment.status', 'payment.purchase_total', 'payment.transaction_id', 'payment.currency' ]
export const MAX_CONDITION_NUMBER = 10

export const DEFAULT_COLUMNS = [ 'id', 'date_created', 'date_updated', 'created_by', 'updated_by', 'complete_submission', 'last_page', 'ip' ]
export const PAYMENT_COLUMNS = [ 'payment_status', 'payment_amount', 'payment_currency', 'payment_transaction_id' ]
export const SIGNATURE_COLUMNS = [ 'signature_status', 'signature_document', 'signature_provider' ]
const DEFAULT_HIDDEN_COLUMNS = [
  'last_page',
  'date_updated',
  'created_by',
  'updated_by',
  'ip',
  'payment_amount',
  'payment_currency',
  'payment_transaction_id',
  ...SIGNATURE_COLUMNS
]
const DEFAULT_FIELDS_COUNT = 3

export const ENTRY_SORT_KEY_MAP = {
  payment_status: 'payment.status',
  payment_amount: 'payment.purchase_total',
  payment_transaction_id: 'payment.transaction_id',
  payment_currency: 'payment.currency'
}

export const getEntryColumn = (id, entry, fieldSets) => {
  switch (id) {
    case 'date_created':
      return entry.dateCreated
    case 'date_updated':
      return entry.dateUpdated
    case 'created_by':
      return entry.createdBy
    case 'updated_by':
      return entry.updatedBy
    case 'last_page':
      return entry.lastPage
    case 'ip':
      return entry.ip
    case 'signature_status':
      return entry?.signature?.state
    case 'signature_document':
      return entry?.signature?.documentId
    case 'signature_provider':
      return entry?.signature?.provider
    default:
      break
  }

  if (entry.payment) {
    const { currency, currencySymbol, purchaseTotal, status, transactionId = '' } = entry.payment

    if (id === 'payment_status') {
      return status
    }

    if (id === 'payment_amount') {
      return `${currencySymbol || '$'}${purchaseTotal}`
    }

    if (id === 'payment_transaction_id') {
      return `${transactionId}`
    }

    if (id === 'payment_currency') {
      return currency ? `${currency}` : ''
    }
  }

  let value = entry[id]

  if (!value && isString(id)) { //find checkbox values from entry fields
    const ids = id.split(',').map(id => parseInt(id, 10))
    const checkboxFields = entry.fields.filter(f => f.value && ids.includes(f.id))
    return checkboxFields.length > 0 ? checkboxFields.map(f => f.value).join(', ') : ''
  }

  if (!value) { //find value from entry fields
    const field = entry.fields.find(f => f.id === id)
    const fieldSet = getFieldSetByIds(fieldSets, [ id ])
    value = getFormattedFieldValue(field, fieldSet)

    if (fieldSet && fieldSet.type === 'price') {
      return `${getCurrencySymbol(fieldSet.currency)} ${value}`
    }
  }

  return value
}

export const getField = (fields) => {
  return fields?.[0] || {}
}

export const getFieldProperty = (field, propertyName) => {
  return field?.[propertyName] || ''
}

export const getFieldIds = (fieldSet) => {
  if (fieldSet.fields[0].choices) {
    if (fieldSet.fields[0].id && fieldSet.fields.length === 1) {
      return [ fieldSet.fields[0].id ]
    }
    if (fieldSet.fields[0].choices[0].id) {
      return fieldSet.fields[0].choices.map(({ id }) => id)
    }
  }
  return fieldSet.fields.map(({ id }) => id)
}

export const getFieldIdsByType = (fieldSets, fieldType) => {
  let fieldIds = []
  const filteredFieldSets = fieldSets?.filter(fieldSet => fieldSet.fields[0].type === fieldType)
  filteredFieldSets?.forEach(fieldSet => {
    fieldIds = [
      ...fieldIds,
      ...getFieldIds(fieldSet)
    ]
  })
  return fieldIds
}

export const getAllFields = (fieldSets) => {
  let fields = []
  fieldSets.forEach(fieldSet => {
    if (fieldSet.fields[0].choices && fieldSet.fields[0].choices[0].id) {
      return fieldSet.fields[0].choices.forEach(({ id, title }) => {
        fields.push({ id, title })
      })
    }
    if (fieldSet.fields[0].id && fieldSet.fields[0].title) {
      return fieldSet.fields.forEach(({ id, title }) => {
        fields.push({ id, title })
      })
    }
    return fieldSet.fields.forEach(({ id }) => {
      fields.push({ id, title: fieldSet.title })
    })
  })
  return fields
}

export const getFields = (fields, fieldSet) => {
  const ids = getFieldIds(fieldSet)
  return fields.filter(field => ids.includes(field.id))
}

export const getInitialFields = (fieldSet) => {
  const ids = getFieldIds(fieldSet)
  return ids.map(id => ({ id }))
}

export const getFormattedFieldValue = (entryField, fieldSet, isEdited = true) => {
  if (entryField?.value === undefined) {
    return ''
  }

  switch (fieldSet.type) {
    case 'number': {
      const parsedValue = Number(isEdited ? entryField.value : trimNumber(entryField.value))
      return Number.isNaN(parsedValue) ? '' : parsedValue
    }
    case 'price': {
      const price = numeral(entryField.value)
      if (fieldSet.currency === 'yen') {
        return price.format('0,0')
      }
      return price.format('0,0.00')
    }
    default:
      break
  }
  return entryField.value
}

export const getAccumulateFieldValues = (fields, separator) => {
  const filteredFields = fields.filter(field => !!field.value)
  let accValue = ''
  if (filteredFields.length === 1) {
    accValue = filteredFields[0].value
  } else if (filteredFields.length > 1) {
    accValue = filteredFields.reduce((acc, val) => {
      return { value: `${acc.value}${separator}${val.value}` }
    }).value
  }
  return accValue
}

export const getDefaultQueryParams = (formId) => {
  const getEntrySetting = getSetting('entry', formId)
  return {
    filterKey: getEntrySetting('filterKey') || 'all',
    sortKey: getEntrySetting('sortKey') || 'id',
    sortDirection: getEntrySetting('sortDirection') || 'desc',
    pageSize: getEntrySetting('pageSize') || 25,
    page: 1,
    query: getEntrySetting('search') || '',
    expression: {
      lastId: (getEntrySetting('expression') && getEntrySetting('expression').lastId)
        || getDefaultExpression().lastId,
      operator: (getEntrySetting('expression') && getEntrySetting('expression').operator)
        || getDefaultExpression().operator,
      conditions: (getEntrySetting('expression') && getEntrySetting('expression').conditions)
        || getDefaultExpression().conditions,
    }
  }
}

export const getDefaultExpression = () => {
  return {
    lastId: -1,
    operator: 'and',
    conditions: []
  }
}

export const getErrorMessage = (idArray, errors, t) => {
  let message = ''
  if (isString(errors)) {
    return isEmpty(idArray) ? t(errors) : null
  }
  let foundError = errors && errors.find(error => !isEmpty(intersectionWith(idArray, error.field_ids)))
  if (foundError) {
    if (foundError.errorText) {
      message = foundError.errorText
    } else {
      message = t(`edit_entry_error_${foundError.issue_code}`)
      if (foundError.expected_type) {
        message += t('edit_entry_error_expected_type', { expectedType: foundError.expected_type })
      }
      if (foundError.expected_format) {
        message += t('edit_entry_error_expected_format', { expectedFormat: foundError.expected_format })
      }
      if (foundError.size_max) {
        message += t('edit_entry_error_size_max', { maxSize: humanFileSize(foundError.size_max) })
      }
      if (foundError.range_type) {
        message += t('edit_entry_error_expected_range', {
          rangeType: foundError.range_type,
          rangeMin: foundError.range_min,
          rangeMax: foundError.range_max
        })
      }
    }
  }
  return message
}

export const getOperatorChain = (conditions) => {
  if (conditions.length === 1) {
    return []
  }
  let operators = conditions.map(con => {
    if (con.error) {
      return { extendHeight: true }
    }
    return { extendHeight: false }
  })
  operators.pop()
  return operators
}

export const getConnectorList = (fieldType) => {
  if (union(TIME_TYPE_LIST, DATE_TYPE_LIST).includes(fieldType)) {
    return union(EQUAL_CONNECTOR_LIST, LESS_CONNECTOR_LIST, MORE_CONNECTOR_LIST, ASSERT_CONNECTOR_LIST)
  }
  if (NUMBER_TYPE_LIST.includes(fieldType)) {
    return union(EQUAL_CONNECTOR_LIST, LESS_CONNECTOR_LIST, MORE_CONNECTOR_LIST, [ '!=' ], ASSERT_CONNECTOR_LIST)
  }
  if (fieldType === 'datetime') {
    return union(EQUAL_DATETIME_CONNECTOR_LIST, LESS_CONNECTOR_LIST, MORE_CONNECTOR_LIST)
  }
  if (fieldType === 'boolean') {
    return ASSERT_CONNECTOR_LIST
  }
  return [ 'like', 'nlike', 'start', 'end', '==', '!=', ASSERT_CONNECTOR_LIST ]
}

export const getFieldType = (fieldSet, field) => {
  let fieldType = fieldSet && fieldSet.type
  if (!fieldType && field) {
    fieldType = field.id
  }
  if (NUMBER_TYPE_LIST.includes(fieldType)) {
    return 'number'
  }
  if (DATE_TYPE_LIST.includes(fieldType)) {
    return 'date'
  }
  if (DATETIME_TYPE_LIST.includes(fieldType)) {
    return 'datetime'
  }
  if (TIME_TYPE_LIST.includes(fieldType)) {
    return 'time'
  }
  if (BOOLEAN_TYPE_LIST.includes(fieldType)) {
    return 'boolean'
  }
  return 'default'
}

export const getTruncatedTitle = (title) => {
  if (title.length > MAX_LENGTH_FIELD_TITLE) {
    return `${title.substr(0, MAX_LENGTH_FIELD_TITLE)}...`
  }
  return title
}

export const getDefaultColumns = options => {
  const { hasPayment = false, hasSignature = false } = options || {}
  let defaultColumns = DEFAULT_COLUMNS
  if (hasPayment) {
    defaultColumns = defaultColumns.concat(PAYMENT_COLUMNS)
  }
  if (hasSignature) {
    defaultColumns = defaultColumns.concat(SIGNATURE_COLUMNS)
  }
  defaultColumns = defaultColumns.map(c => ({
    id: c,
    label: getLabel('entries', `column_${c}`)
  }))
  return defaultColumns
}

export const getUserFieldColumns = (defaultColumns, fieldSets) => {
  const fieldColumns = flatten(fieldSets.map(fieldset => getFieldColumnInfo(fieldset)))
  return fieldColumns
}

export const getDefaultSelectedColumns = (defaultColumns, userColumns) => {
  return [
    ...defaultColumns.filter(col => !DEFAULT_HIDDEN_COLUMNS.includes(col.id)),
    ...userColumns.slice(0, DEFAULT_FIELDS_COUNT)
  ]
}

// make sure column still exist in the form
export const isValidColumn = column => {
  return DEFAULT_COLUMNS.includes(column.id)
    || PAYMENT_COLUMNS.includes(column.id)
    || SIGNATURE_COLUMNS.includes(column.id)
    || isUserField(column.id)
}

export const updateSelectedColumns = (selectedColumns, fieldSets) => {
  if (!selectedColumns || !fieldSets) {
    return null
  }

  let existingFieldIds = []
  fieldSets.forEach(fieldSet => {
    existingFieldIds = existingFieldIds.concat(getFieldIds(fieldSet))
  })

  let updatedSelectedColumns = []
  selectedColumns.forEach(column => {
    if (!isUserField(column.id)) {
      updatedSelectedColumns.push(column)
    } else {
      const fieldIdArray = splitUserFieldId(column.id)
      if (intersectionWith(fieldIdArray, existingFieldIds).length > 0) {
        const fieldSet = getFieldSetByIds(fieldSets, fieldIdArray)
        if (fieldSet) {
          const flattened = flatten(getFieldColumnInfo(fieldSet))
          const matchFlattened = flattened.find(flattenedColumn => flattenedColumn.id === column.id) || flattened[0]
          column = { ...column, ...matchFlattened }
        }
        updatedSelectedColumns.push(column)
      }
    }
  })

  return updatedSelectedColumns
}

export const getOrderedSelectedColumns = (unorderedColumns, defaultColumns, fieldColumns) => {
  return [
    unorderedColumns.find(c => c.id === 'id'),
    ...defaultColumns,
    ...fieldColumns ]
    .map(dc => unorderedColumns.find(c => c.id === dc.id))
    .filter(c => (typeof c !== 'undefined'))
}

const getFieldSetByIds = (fieldSets, ids) => {
  return fieldSets.find(fieldSet => intersectionWith(ids, getFieldIds(fieldSet)).length > 0)
}

const getFieldColumnInfo = fieldSet => {
  return fieldSet.fields.map(f => {
    let fieldColumn = {
      id: isNumber(f.id) ? f.id : f.choices.map(({ id }) => id).join(','),
      label: f.title ? `${fieldSet.title}: ${f.title}` : fieldSet.title
    }

    return fieldColumn
  })
}

const splitUserFieldId = (id) => {
  if (isNumber(id)){
    return [ id ]
  }
  return id.split(',').map(id => Number(id))
}

export const isUserField = (id) => {
  if (isNumber(id)) {
    return true
  }
  const ids = id.split(',')
  return ids.map(id => Number(id)).every(id => !Number.isNaN(id))
}

export const updateConditionsIfErrorExists = (conditions, errors) => {
  if (Array.isArray(errors) && !isEmpty(errors) && errors[0]['expected_format'] === 'condition') {
    const id = getIdFromExpression(errors[0].expression)
    return conditions.map(condition => {
      if (condition.field.id === id) {
        condition.error = errors[0].message.toLowerCase().replace(/ /g, '_')
      }
      return condition
    })
  }
  return conditions
}

const getIdFromExpression = (expression) => {
  const result = EXTRACT_ID_FROM_EXPRESSION_REGEX.exec(expression)
  return Array.isArray(result) && result.length > 1 && Number(result[1])
}

export const shouldResetValueWithFieldChange = (currentType, newType) => {
  return [ 'time', 'date', 'datetime', 'boolean' ]
    .map(type => ((currentType === type || newType === type) && currentType !== newType))
    .some(t => t === true)
}

export const shouldResetValueWithConnectorChange = (currentConnector, newConnector) => {
  return [ 'is' ]
    .map(connector => ((currentConnector === connector || newConnector === connector) && currentConnector !== newConnector))
    .some(t => t === true)
}

export const getUpdatedValueIfDateCondition = (fieldType, { connector, value, userTimezone }) => {
  let newValue = value

  // if we require the datetime we need to calculate the UTC equivalent at/right before midnight in the user's timezone
  // otherwise we can just default to 00:00:00/11:59:59
  if (newValue && fieldType === 'datetime') {
    const adjustedTimezone = adjustTimezoneForDST(userTimezone, newValue)
    if (STARTOF_DAY_CONNECTOR_LIST.includes(connector)) {
      newValue = apiFormat(apiDateTime(moment(newValue).utcOffset(adjustedTimezone).startOf('day')))
    } else {
      newValue = apiFormat(apiDateTime(moment(newValue).utcOffset(adjustedTimezone).endOf('day')))
    }
  }
  else if (newValue && fieldType === 'date') {
    if (STARTOF_DAY_CONNECTOR_LIST.includes(connector)) {
      newValue = apiFormat(apiDateTime(newValue).startOf('day'))
    } else {
      newValue = apiFormat(apiDateTime(newValue).endOf('day'))
    }
  }
  return newValue
}

export const getOriginalDateWithoutUTCAdjustment = (fieldType, { value, userTimezone }) => {
  let newValue = value
  if (newValue && fieldType === 'datetime') {
    newValue = apiFormat(getDateTimeForTimeZone(value, userTimezone))
  }
  return newValue
}

export const buildEntryFetchParams = (filterKey, expression, query, userTimezone=0) => {

  const conditions = []
  const expConditions = []

  if (filterKey === 'today') {
    const adjustedTimezone = adjustTimezoneForDST(userTimezone)
    conditions.push({
      key: 'date_created',
      value: apiFormat(apiDateTime(moment().utcOffset(adjustedTimezone).startOf('day'))),
      op: '>='
    }, {
      key: 'date_created',
      value: apiFormat(apiDateTime(moment().utcOffset(adjustedTimezone).endOf('day'))),
      op: '<='
    })
  }

  if (filterKey === 'expression') {
    expression.conditions.forEach(con => {
      let key = con.field.id
      if (con.isUserField) {
        key = `fields[id:${con.field.id}].value`
      }

      let op = con.connector
      let value = con.value
      let compareToNone = false
      if (con.connector === 'is') {
        const fieldType = getFieldType(undefined, con.field)
        op = (value === 'true') ? '!=' : '=='
        value = fieldType === 'boolean' ? 'false' : ''
        compareToNone = fieldType === 'boolean' ? false : true
      }
      expConditions.push({ key, value, op, compareToNone })
    })

  }
  return {
    expression: {
      conditions: expConditions,
      operator: expression.operator
    },
    conditions,
    ...(query ? { search: query } : {})
  }
}

export const getWordCount = (value) => {
  value = value || ''
  const wordsArray = value.replace(/\s+/g, ' ').split(' ')
  const wordCount = isEmpty(wordsArray[wordsArray.length - 1]) ? wordsArray.length - 1 : wordsArray.length
  return wordCount
}

export const trimNumber = (number) => {
  if (number?.endsWith('.0')) {
    return number.replace('.0', '')
  }
  return number
}
