import './NewLookModal.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import WelcomeImage1 from './images/Welcome1.svg'
import WelcomeImage2 from './images/Welcome2.svg'
import WelcomeImage3 from './images/Welcome3.svg'
import { ModalBody, ModalFooter, Button } from '@sm/wds-react'
import { Modal } from '../../Modal'
import { setSetting } from '../../../../services/settings/settings'


export class NewLookModal extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    setSetting('formsV1')('welcomeDialogShown', true)
    dispatch({ type: 'CLOSE_MODAL' })
  }

  render() {
    const { t } = this.props

    const welcomeContentSections = [{
      img: WelcomeImage1,
      title: t('welcome_1_title'),
      description: t('welcome_1_description')
    }, {
      img: WelcomeImage2,
      title: t('welcome_2_title'),
      description: t('welcome_2_description')
    }, {
      img: WelcomeImage3,
      title: t('welcome_3_title'),
      description: t('welcome_3_description')
    }]

    return (
      <Modal
        className='welcome-modal'
        onClose={this.handleClose}
        size='md'
      >
        <ModalBody>
          <div className='welcome-modal-title'>
            {t('Welcome_to_the_new_experience')}
          </div>

          <div className='welcome-modal-description'>
            <Trans t={t} i18nKey='Wufoo_has_exciting_new_features'>
              <span>Wufoo has exciting new features and a new look! You can</span>&nbsp;
              <a
                id='welcome-modal-learn-more'
                href='https://main.wufoo.com/docs/new-experience/'
                target='_blank'
                rel='noopener noreferrer'
              >
                learn more
              </a>
              &nbsp;<span>about it here.</span>
            </Trans>
          </div>

          <div className='welcome-content-sections'>
            {welcomeContentSections.map(({ img, title, description }) => (
              <div
                className='welcome-content-section'
                key={title}
              >
                <img
                  alt=''
                  className='welcome-content-img'
                  src={img}
                />
                <h3 className='welcome-content-title'>
                  {title}
                </h3>
                <p className='welcome-content-description'>
                  {description}
                </p>
              </div>
            ))}
          </div>

        </ModalBody>

        <ModalFooter>
          <Button
            id='welcome-modal-get-started'
            stretched
            onClick={this.handleClose}
          >
            {t('GET_STARTED')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

NewLookModal.propTypes = {
  dispatch: PropTypes.func,
  t: PropTypes.func
}

export default NewLookModal
