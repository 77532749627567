
import React from 'react'
import PropTypes from 'prop-types'
import {
  Input
} from '@sm/wds-react'

export class InfoContent extends React.Component {

  render() {
    const { info } = this.props
    return (
      <div className='info-content'>
        {info.map(({ key, label, value }) => {
          return (
            <div className='content-item' key={key}>
              <span className='item-title'>{label}</span>
              <div className='item-input'>
                <Input
                  value={value}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

InfoContent.propTypes = {
  info: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default InfoContent
