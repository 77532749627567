import loadjs from 'loadjs'
import { isTruthy } from '../utils/boolean'

const ONETRUST_SRC = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'


const cookieOverrides = () => {
  // store the original cookie setter and getter
  document.cookieSetter = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie').set
  document.cookieGetter = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie').get

  // each item in this array is in the format [cookie, group]
  window.notConsentedButTriedToLoad = []
  window.OptanonWrapper = () => {
    var onetrustData = window.OneTrust.GetDomainData()
    var consentedGroups = window.OptanonActiveGroups
    var cookies = {}

    // create one object full of all of the cookies in the config for quick lookups
    onetrustData.Groups.forEach(function (group) {
      group.Cookies.forEach(function (cookie) {
        cookies[cookie.Name] = { group: group.OptanonGroupId }
      })
    })

    // override the cookie setter with our new function
    document.__defineSetter__('cookie', setCookie)
    document.__defineGetter__('cookie', document.cookieGetter)

    function setCookie(cookie) {
      if (!cookie) return
      var cookieArr = cookie.split('=')
      if (!cookieArr.length) return
      var cookieName = cookieArr[0]

      // Don't set cookies that dont exist in the OneTrust config
      if (!cookies[cookieName]) {
        return
      }

      // get the group this cookie belongs to
      var cookieGroup = cookies[cookieName].group

      // Don't set cookies that don't have User consent
      // but let it set the cookie if we know it's required
      // but store the value so we can set them if Onetrust consent changes
      if (consentedGroups.indexOf(cookieGroup) === -1) {
        window.notConsentedButTriedToLoad.push([ cookie, cookieGroup ])
        return
      }

      // set the cookie using the original javascript cookie setter
      document.cookieSetter(cookie)
    }

    // When the user changes their consent, we should set the cookies that were previously blocked
    window.OneTrust.OnConsentChanged(function(e) {
      var newConsentedGroups = e.detail
      window.notConsentedButTriedToLoad.forEach(function(cookieDetails) {
        var cookie = cookieDetails[0]
        var cookieGroup = cookieDetails[1]

        // cookie is in the new list of consented groups, so we can set it now
        if (newConsentedGroups.indexOf(cookieGroup) > -1) {
          document.cookieSetter(cookie)
        }
      })
    })
  }
}

const oneTrustWebInit = () => {
  const {
    REACT_APP_ONE_TRUST_SCRIPT,
    REACT_APP_USE_LEGACY_GDPR_BANNER
  } = process.env

  if (!loadjs.isDefined('onetrust') && !isTruthy(REACT_APP_USE_LEGACY_GDPR_BANNER)) {
    cookieOverrides()
    loadjs(ONETRUST_SRC, 'onetrust', {
      error: () => {},
      before: (path, script) => {
        script.async = true
        script.setAttribute('data-document-language', 'true')
        script.setAttribute('charset', 'UTF-8')
        script.setAttribute('data-domain-script', REACT_APP_ONE_TRUST_SCRIPT)
      },
      // Note: If needed, we could run some JS after OneTrust has loaded, such as loading GoogleTagManager
      // E.g. https://code.corp.surveymonkey.com/cms/web/blob/dbbbc1bef7b8bebdc3e952a7f747ff94aedf2040/src/util/onetrust.js#L110
      success: () => {},
    })
  }
}

export const OneTrustLoader = () => {
  oneTrustWebInit()
  return null
}
