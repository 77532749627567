export const urls = {
  FILE_MANAGER: '/file-manager',
  FORM_MANAGER_OLD: '/form-manager', // Redirect to new form manager URL for backwards compatibility
  FORM_MANAGER: '/forms',
  FORM_MANAGER_SHARE: '/forms/:id/share',
  ENTRY_MANAGER: '/entry-manager/:formId',
  ENTRY_DETAIL: '/entry-manager/:formId/entries/:entryId',
  NEW_ENTRY: '/entry-manager/:formId/new-entry',
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  REDIRECT: '/redirect'
}

const LOGGED_OUT_URLS = [
  urls.LOGIN
]

export const getUrl = (url, data={}) => {
  let newUrl = url

  Object.entries(data).forEach(([ k, v ]) => {
    newUrl = newUrl.replace(`:${k}`, v)
  })

  return newUrl
}

export const isCurrentUrl = (location, url) => {
  const path = location.pathname
  if (url === path) {
    return true
  } else {
    // replace url params (:id) with wildcard
    // based on which a regex is created to test the current location path
    const regex = new RegExp(url.replace(/:((?!\/).)*/g, '.*').replace(/\//g, '\\/'))
    return regex.test(path)
  }
}

export const isLoggedOutExperience = location => {
  return LOGGED_OUT_URLS.some(url => isCurrentUrl(location, url))
}

export const shouldShowCookieBanner = location => {
  return !isLoggedOutExperience(location) || isCurrentUrl(location, urls.LOGIN)
}

export const isUrlSafe = url => {
  return !url.toLowerCase().trim().startsWith('javascript')
}

export const sanitizeIframeOrigin = origin => {
  return (origin === 'null' || origin?.startsWith('file://')) ? '*' : origin
}

