import { getFlag } from '../../utils/selector'

/* eslint-disable no-unused-expressions */

class Mixpanel {
  constructor() {
    /*
    * If env === 'dev' || 'stage' || 'prod', always initiate mixpanel library
    * If env === 'development', initiate mixpanel library if mixpanel feature flag is on
    * if env === 'test', never initiate mixpanel library
    * */
    if ((process.env.NODE_ENV === 'development' && getFlag('mixpanel')) || process.env.NODE_ENV !== 'test') {
      this.mp = require('mixpanel-browser')
      this.mp.init(process.env.REACT_APP_MIXPANEL_KEY, {
        property_blacklist: [ '$user_id' ]
      })
    }
    this.metadataReady = false
  }

  identify(user = {}) {
    // form respondent or embed scenario
    if (user.profile && !user.id) {
      this.metadataReady = true
      return this.mp?.identify(`${user.profile}`)
    } else if (user.profile && user.id) {
      // A precaution to make sure distinct ID is set before tracking events
      // Tho it is not likely to return bad user data from api side
      this.metadataReady = true

      const distinctId = user.accountOwner ? `${user.profile}` : `${user.profile}-${user.id}`
      return this.mp?.identify(distinctId)
    }
    return
  }

  setPeopleProperty(props) {
    return this.metadataReady && this.mp?.people.set(props)
  }

  registerUserSupers(user) {
    const { profile, id } = user || {}
    this.supers = {
      ...this.supers,
      ...(profile ? { 'Profile ID': profile } : {}),
      ...(id ? { 'User ID': id } : {}),
    }
    return this.mp?.register(this.supers)
  }

  registerPlanSupers(plan) {
    const { name, id, billingInterval } = plan || {}
    this.supers = {
      ...this.supers,
      ...(name ? { 'Plan Type': name } : {}),
      ...(id ? { 'Plan ID': id } : {}),
      ...(billingInterval ? { 'Billing Frequency': billingInterval } : {}),
    }
    return this.mp?.register(this.supers)
  }

  track(name, props = {}) {
    return this.metadataReady && this.mp?.track(name, props)
  }

  reset() {
    return this.mp?.reset()
  }
}

export let mixpanel = new Mixpanel()

/* eslint-disable no-unused-expressions */
