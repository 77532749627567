export const getQueryParams = () => {
  const parts = window.location.href.split('?')

  if (parts.length > 1) {
    let query = new URLSearchParams(parts[1])
    let parsedQueryString = {}
    for (let key of query.keys()) {
      let vals = query.getAll(key)
      parsedQueryString[key] = vals.length > 1 ? vals : vals[0]
    }
    return parsedQueryString
  }

  return {}
}
