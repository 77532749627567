const defaultState = {
  type: null,
  title: null,
  body: null,
  autoClose: true,
  dismissible: true
}

export default (state=defaultState, action) => {

  switch (action.type) {
    case 'SHOW_TOAST': {
      const { type, title, body, autoClose, dismissible, icon } = action.data

      return {
        ...state,
        type,
        title,
        body,
        autoClose,
        dismissible,
        icon
      }
    }
    case 'HIDE_TOAST':
      return {
        ...state,
        type: null,
        title: '',
        body: '',
        autoClose: true,
        dismissible: true,
        icon: undefined
      }
    default:
      return state
  }

}
