import './Modals.sass'

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Button } from '@sm/wds-react'

import { AddForm } from './AddForm'
import { ConfirmModal } from './ConfirmModal'
import { DeleteEntry } from './DeleteEntry'
import { DeleteFile } from './DeleteFile'
import { DeleteForm } from './DeleteForm'
import { EmailEntry } from './EmailEntry'
import { ExportInProgress } from './ExportInProgress'
import { FilteringExpression } from './FilteringExpression'
import { FormUseTrackingModal } from './FormUseTrackingModal'
import { FolderSelector } from './FolderSelector'
import { FormLabelModal } from './FormLabelModal'
import { NewFileDetails } from './NewFileDetails'
import { PricingModal } from './PricingModal'
import { SetPassword } from './SetPassword'
import { UploadDetails } from './UploadDetails'
import { WelcomeModal } from './WelcomeModal'
import { removeHasModalClassname } from '../../models/modal'
import { FLAGS_STATE_PROPS, MODAL_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


const modalMap = {
  'add-form-modal': AddForm,
  'delete-entry-modal': DeleteEntry,
  'delete-file-modal': DeleteFile,
  'delete-form-modal': DeleteForm,
  'email-entry-modal': EmailEntry,
  'export-in-progress-modal': ExportInProgress,
  'filter-expression-modal': FilteringExpression,
  'folder-select-modal': FolderSelector,
  'form-label-modal': FormLabelModal,
  'new-file-details-modal': NewFileDetails,
  'pricing-modal': PricingModal,
  'set-password-modal': SetPassword,
  'tell-us-about-modal': FormUseTrackingModal,
  'upload-details-modal': UploadDetails,
  'welcome-modal': WelcomeModal,
}

const promotionalModals = [
  'tell-us-about-modal',
  'welcome-modal',
]

let Modals = props => {
  const { flags, modal, t } = props
  const renderTo = useRef(null)
  let ModalComponent = modalMap[modal.activeModal]

  // make sure "has-modal" classname is removed when the modal unmounts
  useEffect(() => {
    return () => {
      removeHasModalClassname()
    }
  }, [])

  if (!flags.configs.dialogs && promotionalModals.includes(modal.activeModal)) {
    removeHasModalClassname()
    return null
  }

  if (modal.activeModal === 'upgrade-modal') {
    return (
      <ConfirmModal
        isUpgrade={true}
        size='sm'
        name='upgrade-modal'
        header={t('upgrade_modal_header')}
        okayButton={(
          <Button
            color='warning'
          >
            {t('CLOSE')}
          </Button>
        )}
      >
        <React.Fragment />
      </ConfirmModal>
    )
  }

  return (
    <div className='wufoo-modals' ref={renderTo}>
      {!!ModalComponent &&
        <ModalComponent
          renderTo={renderTo?.current}
          {...modal.props}
        />
      }
    </div>
  )
}

Modals.propTypes = {
  flags: PropTypes.shape(FLAGS_STATE_PROPS).isRequired,
  modal: PropTypes.shape(MODAL_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  flags: state.flags,
  modal: state.modal,
})

Modals = connect(select)(Modals)
Modals = withTranslation('modals')(Modals)

export default Modals
