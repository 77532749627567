import React from 'react'
import PropTypes from 'prop-types'

import {
  Tabs,
  Tab
} from '@sm/wds-react'

export const WuToolTabs = props => {
  const { selected, tabs, onChange } = props
  return (
    <Tabs
      onChange={(i, id) => onChange(id)}
      color='primary'
      align='even'
    >
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.id}
            selected={selected === tab.id}
            title={tab.title}
            id={tab.id}
          />
        )
      })}
    </Tabs>
  )
}

WuToolTabs.propTypes = {
  selected: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
}

export default WuToolTabs
