import mime from 'mime'
import { api, getTableQueryParams } from '../services/api'
import { apiError } from './api'
import { FILE_UPLOAD_STATUS } from '../constants/files'
import { getPlan } from './plan'
import {
  getUpdatedUploadFileList,
  toAPIParentId,
} from '../models/files'
import { withToastDisplay } from '../utils/toast'


export const fetchFiles = (queryObj, parentId) => {
  const { sortKey, sortDirection, query, page, pageSize } = queryObj

  let conditions = [{
    key: 'parentId',
    value: parentId || '',
    op: '=='
  }]

  // You can only search from the root directory
  if (query) {
    conditions = [{
      key: 'filename',
      value: query,
      op: 'like'
    }]
  }

  const params = {
    conditions,
    sortBy: sortKey,
    sortDirection,
    page,
    pageSize
  }

  return dispatch => {
    dispatch({ type: 'SET_FILES_LOADING', data: true })
    dispatch({ type: 'CHANGE_FILES_QUERY_PARAMS', data: queryObj })
    dispatch(apiError('file-list', false))

    dispatch(getPlan({ loading: true }))

    api.get(`/files?${getTableQueryParams(params)}`)
      .then(({ data }) => {
        dispatch({ type: 'SET_FILES', data })
        dispatch({ type: 'UPDATE_FOLDER_CONTENT', data })
        dispatch({ type: 'RESET_SELECTED', data: 'files' })
      })
      .catch(() => {
        dispatch(apiError('file-list', true))
        dispatch({ type: 'SET_FILES_LOADING', data: false })
      })
  }
}


export const fetchFavouriteFiles = queryObj => {
  const { sortKey, sortDirection, page, pageSize } = queryObj
  const conditions = [{
    key: 'is_favourite',
    value: true,
    op: '=='
  }]

  const params = {
    conditions,
    sortBy: sortKey,
    sortDirection,
    page,
    pageSize
  }

  return dispatch => {
    dispatch({ type: 'SET_FAVOURITE_FILES_LOADING', data: true })
    dispatch(apiError('favourite-file-list', false))

    api.get(`/files?${getTableQueryParams(params)}`)
      .then(({ data }) => {
        dispatch({ type: 'SET_FAVOURITE_FILES', data })
        dispatch({ type: 'RESET_SELECTED', data: 'files' })
      })
      .catch(() => {
        dispatch(apiError('favourite-file-list', true))
      })
      .then(() => {
        dispatch({ type: 'SET_FAVOURITE_FILES_LOADING', data: false })
      })
  }
}

export const fetchFolders = ({ id, parentId, query }) => {

  let conditions = [{
    key: 'type',
    value: 'folder',
    op: '=='
  }]

  if (query) {
    conditions.push({
      key: 'filename',
      value: query,
      op: 'like'
    })
  }
  else {
    conditions.push({
      key: 'parent_id',
      value: id || '',
      op: '=='
    })
  }

  const params = { conditions }

  return dispatch => {
    if (!id) {
      dispatch({ type: 'RESET_FOLDERS', data: {}})
      id = 'root'
    }

    dispatch({ type: 'SET_FOLDERS_LOADING', data: { id, isLoading: true }})
    dispatch(apiError('folder-list'), false)

    api.get(`/files?${getTableQueryParams(params)}`)
      .then(({ data }) => {
        const newData = { ...data, id, parentId }
        dispatch({ type: 'SET_FOLDERS', data: newData })
      })
      .catch(() => {
        dispatch({ type: 'RESET_FOLDERS', data: {}})
        dispatch(apiError('folder-list', true))
      })
      .then(() => {
        dispatch({ type: 'SET_FOLDERS_LOADING', data: { id, isLoading: false }})
      })
  }
}

export const fetchFolderContent = (queryObj, parentId, options = {}) => {
  const { sortKey, sortDirection, page, pageSize } = queryObj

  const conditions = [{
    key: 'parent_id',
    value: parentId,
    op: '=='
  }]

  const params = {
    conditions,
    sortBy: sortKey,
    sortDirection,
    page,
    pageSize
  }

  return async dispatch => {
    dispatch({ type: 'SET_SELECTED_FILE_LOADING', data: true })
    dispatch({ type: 'CHANGE_SELECTED_FILE_QUERY_PARAMS', data: queryObj })
    dispatch(apiError('file-list', false))

    try {
      const { data: folderInfo } = options.includeParent ? await api.get(`/files/${parentId}`) : {}
      const { data : folderContent } = await api.get(`/files?${getTableQueryParams(params)}`)
      const data = {
        ...folderInfo,
        ...folderContent
      }
      dispatch({ type: 'SET_SELECTED_FILE_CONTENT', data })
      dispatch({ type: 'UPDATE_FOLDER_CONTENT', data })
    } catch (e) {
      dispatch(apiError('file-list', true))
    } finally {
      dispatch({ type: 'SET_SELECTED_FILE_LOADING', data: false })
    }
  }
}

export const refreshFileList = (queryObj, parentId) => {
  return async dispatch => {
    if (parentId) {
      dispatch(fetchFolderContent(queryObj, parentId, { includeParent: true }))
    } else {
      dispatch(fetchFiles(queryObj, ''))
    }
    dispatch(fetchFavouriteFiles(queryObj))
  }
}

export const deleteFile = (fileIds, toastMessages, options) => {
  return async (dispatch, getState) => {

    const { files } = getState()
    withToastDisplay(dispatch, toastMessages, async () => {
      await api.post('/files/requests/deletes', { fileIds })

      // there are three possible values for 'navigateTo':
      //   undefined: no navigation
      //   '': navigation to home
      //   object: navigation to previous folder
      if (options?.navigateTo !== undefined) {
        dispatch({ type: 'NAVIGATE_FILE_TO', data: options.navigateTo })
      }
      if (options?.navigateTo?.id) {
        dispatch(fetchFolderContent({ ...files.selectedFileQueryParams }, options.navigateTo.id, { includeParent: true })) //eslint-disable-line
      } else {
        dispatch({ type: 'SET_REFRESH', data: true })
      }
    })

    dispatch({ type: 'RESET_FILE_REQUEST' })
  }
}

export const updateFileWithRefresh = (file, toastMessages) => {
  return async dispatch => {

    withToastDisplay(dispatch, toastMessages, async () => {
      await api.patch(`/files/${file.id}`, file)
      dispatch({ type: 'UPDATE_FILE', data: file })
      dispatch({ type: 'SET_REFRESH', data: true })
    })
  }
}

export const updateFile = (fileId, fileData) => {
  return dispatch => {
    api.patch(`/files/${fileId}`, fileData)
      .then(({ data }) => {
        dispatch({ type: 'UPDATE_FILE', data })
        dispatch({ type: 'UPDATE_FOLDER_CONTENT', data })
      })
  }
}

export const upload = async (file, { filename, parentId, description }) => {
  const { data } = await api.post('/files/requests/upload', {
    filename,
    parentId,
    description,
  })
  const { fields, url } = data

  const formData = new FormData()
  formData.append('aWSAccessKeyId', fields.aWSAccessKeyId)
  formData.append('acl', fields.acl)
  formData.append('key', fields.key)
  formData.append('policy', fields.policy)
  formData.append('signature', fields.signature)
  formData.append('Content-Disposition', fields['Content-Disposition'])
  formData.append('Content-Type', mime.getType(file.name) || 'application/octet-stream')
  formData.append('file', file)
  await api.upload(url, formData)
  return data
}

export const singleUpload = ({ file, filename, description, parentFolder }, toastMessages) => {
  return async dispatch => {
    await withToastDisplay(dispatch, toastMessages, async () => {
      const data = await upload(file, {
        filename,
        parentId: parentFolder?.id,
        description
      })
      await api.patch(`/files/${data.fileId}`, {})
      dispatch({ type: 'SET_REFRESH', data: true })
    })

    dispatch({ type: 'RESET_FILE_REQUEST' })
  }
}

export const bulkUpload = ({ files, parentFolder }) => {
  return async dispatch => {
    await Promise.all(files.map(async (file) => {
      try {
        const data = await upload(file, {
          filename: file.name,
          parentId: parentFolder?.id
        })
        await api.patch(`/files/${data.fileId}`, {})
        const newFiles = getUpdatedUploadFileList(files, [ file ], FILE_UPLOAD_STATUS.COMPLETE, data.fileId)
        dispatch({ type: 'SET_FILE_REQUEST', data: { files: newFiles }})
      } catch (e) {
        const newFiles = getUpdatedUploadFileList(files, [ file ], FILE_UPLOAD_STATUS.ERROR)
        dispatch({ type: 'SET_FILE_REQUEST', data: { files: newFiles }})
      }
    }))
  }
}

export const createFolder = ({ filename, description, parentFolder, location }, toastMessages) => {
  return async dispatch => {

    withToastDisplay(dispatch, toastMessages, async () => {
      const { id } = parentFolder

      await api.post('/files', { filename, description, parentId: toAPIParentId(id) })

      if (location === 'modal') {
        dispatch({ type: 'SET_REFRESH_FOLDER', data: { id }})
      } else {
        dispatch({ type: 'SET_REFRESH', data: true })
        dispatch({ type: 'RESET_FILE_REQUEST' })
      }
    })
  }
}

export const downloadFile = ({ url, filename }) => {
  return () => {
    api.download(url, filename, { bypass: true })
  }
}

export const copyFile = ({ fileIds, parentFolder }, toastMessages) => {
  return async dispatch => {

    withToastDisplay(dispatch, toastMessages, async () => {
      await api.post('/files/requests/duplicate', { fileIds, parentId: parentFolder?.id })
      dispatch({ type: 'SET_PENDING_REFRESH_FOLDERS', data: { id: !parentFolder?.parentId || parentFolder.parentId === 'root' ? '' : parentFolder.parentId }})
      dispatch({ type: 'SET_REFRESH', data: true })
    })

    dispatch({ type: 'RESET_FILE_REQUEST' })
  }
}

export const moveFile = ({ fileIds, parentFolder }, toastMessages) => {
  return async (dispatch) => {

    withToastDisplay(dispatch, toastMessages, async () => {
      await api.post('/files/requests/move', { fileIds, parentId: parentFolder?.id })
      dispatch({ type: 'SET_PENDING_REFRESH_FOLDERS', data: { id: !parentFolder?.parentId || parentFolder.parentId === 'root' ? '' : parentFolder.parentId }})
      dispatch({ type: 'SET_REFRESH', data: true })
    })

    dispatch({ type: 'RESET_FILE_REQUEST' })
  }
}
