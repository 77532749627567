
import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@sm/wds-react'

export const FeatureContent = props => {
  const { configs, onChange } = props

  return (
    configs.map((feature) => {
      return (
        <div
          key={feature.key}
          className='feature-container'
        >
          <div className='feature-title'>
            <span>{feature.title}</span>
            <Switch
              onChange={() => onChange(feature.key)}
              checked={feature.enabled}
              disabled={feature.disabled === true}
            />
          </div>
          <span className='feature-description'>{feature.description}</span>
        </div>
      )
    })
  )
}

FeatureContent.propTypes = {
  configs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
}

export default FeatureContent