const defaultState = {
  form: {},
  modalForm: null,
  isLoading: false,
  selectedEmbedTab: 'javascript',
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_CURRENT_FORM':
      return { ...state, form: data }
    case 'SET_MODAL_FORM':
      return { ...state, modalForm: data }
    case 'SET_FORM_LOADING':
      return { ...state, isLoading: data }
    case 'SET_SELECTED_EMBED_TAB':
      return { ...state, selectedEmbedTab: data }
    default:
      return state
  }

}

