import React from 'react'
import PropTypes from 'prop-types'

import {
  IconLogoLinkedIn,
  IconLogoFacebook,
  IconLogoYouTube,
} from '@sm/wds-icons'
import { SocialLink } from './SocialLink'
export const SocialLinks = props => {
  const { size } = props

  return (
    <div className='social-links'>
      <SocialLink id='footer-twitter' size={size} iconCode='h' href='https://twitter.com/Wufoo' />
      <SocialLink id='footer-facebook' href='https://www.facebook.com/formbuilder'><IconLogoFacebook size={size} /></SocialLink>
      <SocialLink id='footer-youtube' href='https://www.youtube.com/user/wufooforms'><IconLogoYouTube size={size} /></SocialLink>
      <SocialLink id='footer-linkedin' href='https://www.linkedin.com/company-beta/3364692/'><IconLogoLinkedIn size={size} /></SocialLink>
    </div>
  )
}

SocialLinks.propTypes = {
  size: PropTypes.string
}

SocialLinks.defaultProps = {
  size: 'lg'
}

export default SocialLinks
