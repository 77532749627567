import './UpgradeButton.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button } from '@sm/wds-react'
import { LegacyLink } from '../../../components/LegacyLink'

import { getUpgradeCTAClickedEventDetails } from '../../../models/mixpanel'
import { mixpanel } from '../../../services/telemetry/mixpanel'

export let UpgradeButton = props => {
  const { classname, size, location, upgradeTextKey, tabIndex, id, t } = props

  const sendTrackingEvent = () => {
    const eventDetails = getUpgradeCTAClickedEventDetails(upgradeTextKey, location)
    mixpanel.track(eventDetails.event, eventDetails.properties)
  }

  return (
    <LegacyLink to='/account/pricing'>
      <Button
        className={classname}
        color='upgrade'
        size={size}
        onClick={sendTrackingEvent}
        tabIndex={tabIndex}
        id={id}
      >
        {t(upgradeTextKey)}
      </Button>
    </LegacyLink>
  )
}

UpgradeButton.propTypes = {
  id: PropTypes.string,
  location: PropTypes.string.isRequired,
  classname: PropTypes.string,
  size: PropTypes.string,
  t: PropTypes.func,
  tabIndex: PropTypes.number,
  upgradeTextKey: PropTypes.string.isRequired
}

UpgradeButton.defaultProps = {
  id: '',
  classname: '',
  size: 'sm',
  tabIndex: 0
}

UpgradeButton = withTranslation('global')(UpgradeButton)

export default UpgradeButton
