import './EmailVerificationBanner.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button } from '@sm/wds-react'
import { IconWarning } from '@sm/wds-icons'
import { sendVerificationEmail } from '../../../actions/user'
import { USER_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class EmailVerificationBanner extends React.Component {

  handleSendEmail = () => {
    const { t, dispatch } = this.props
    dispatch(sendVerificationEmail({
      successMessage: t('send_email_success_toast_message'),
      warningMessage: t('send_email_already_verified_toast_message'),
      errorMessage: t('send_email_error_toast_message')
    }))
  }

  render() {
    const { t, user } = this.props
    if (user && user.validatedEmail === false) {
      return (
        <div className='verification-banner'>
          <div className='wds-grid'>
            <div className='verification-banner-alert'>
              <div className='verification-banner-icon'>
                <IconWarning />
              </div>
              <div>
                <h3>{t('verification_email_banner_title')}</h3>
              </div>
            </div>
            <div className='verification-banner-action'>
              <Button
                size='md'
                color='upgrade'
                variant='secondary'
                onClick={this.handleSendEmail}
              >
                {t('Send_Verification_Email_Button')}
              </Button>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

EmailVerificationBanner.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
}

const select = state => ({
  user: state.user,
})

EmailVerificationBanner = connect(select)(EmailVerificationBanner)
EmailVerificationBanner = withTranslation('user')(EmailVerificationBanner)

export default EmailVerificationBanner
