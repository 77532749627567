import './Header.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Button } from '@sm/wds-react'
import { AccountDropdown } from '../AccountDropdown'
import { SkipLink } from '../../components/SkipLink'
import { Tab } from './Tab'
import { urls, isCurrentUrl } from '../../utils/urls'
import wufooLogo from './images/wufoo-logo.svg'
import { APP_STATE_PROPS, FLAGS_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


export let Header = props => {

  const {
    app,
    flags,
    handleSkip,
    hasError,
    location,
    t
  } = props

  return (
    <div>
      <header
        className={classnames(
          'wufoo-header',
          { error: hasError },
          { bluefoo: flags.configs.bluefoo }
        )}
      >
        <div className='left-section'>
          <SkipLink
            handleSkip={handleSkip}
          />

          <a className='wufoo-logo' href='/' id='header-wufoo-logo'>
            <img className='wufoo-logo-img' src={wufooLogo} alt='' />
          </a>

          <div className='tabs'>
            <Tab
              id='header-forms'
              title={t('Forms')}
              isActive={isCurrentUrl(location, urls.FORM_MANAGER)}
              href={urls.FORM_MANAGER}
            />

            <Tab
              id='header-files'
              title={t('Files')}
              isActive={isCurrentUrl(location, urls.FILE_MANAGER)}
              href={urls.FILE_MANAGER}
            />

            <Tab
              legacy
              id='header-reports'
              title={t('Reports')}
              isActive={false}
              href='/reports'
            />

            <Tab
              legacy
              id='header-themes'
              title={t('Themes')}
              isActive={false}
              href='/themes'
            />

            <Tab
              legacy
              id='header-users'
              title={t('Users')}
              isActive={false}
              href='/users'
            />

            <Tab
              legacy
              id='header-pricing'
              title={t('Pricing')}
              isActive={false}
              href='/account/pricing'
            />

            {/* Show "Test error" button when error mode is turned on for developing purpose */}
            {flags.configs.errorMode && (
              <div className='test-error-button'>
                <Button onClick={() => { throw new Error('Error mode') }}>
                  Test error
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className='right-section'>
          <AccountDropdown key={app.isAuthenticated} />
        </div>
      </header>

    </div>
  )
}

Header.propTypes = {
  app: PropTypes.shape(APP_STATE_PROPS).isRequired,
  flags: PropTypes.shape(FLAGS_STATE_PROPS).isRequired,
  location: PropTypes.object.isRequired,
  handleSkip: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  app: state.app,
  flags: state.flags,
})

Header = connect(select)(Header)
Header = withTranslation('global')(Header)
Header = withRouter(Header)

export default Header
