import './Toasts.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  ProgressCircle,
  Toast,
  ToastTitle,
  ToastBody,
} from '@sm/wds-react'
import { IconCheck, IconWarning, IconX } from '@sm/wds-icons'

export const Toasts = props => {
  const { dispatch, toasts } = props

  const {
    type,
    title,
    body,
    autoClose,
    dismissible,
    icon
  } = toasts

  if (type) {
    const typeOverride = icon || type
    return (
      <span className='global-toast'>
        <Toast
          key={`${type}-${title}`}
          dismissible={dismissible !== false}
          autoClose={autoClose !== false}
          onClose={() => {
            dispatch({ type: 'SHOW_TOAST', data: {}})
          }}
        >
          {title &&
            <ToastTitle>
              {typeOverride === 'loading' &&
                <ProgressCircle color='primary' continuous size='sm' />
              }

              {typeOverride === 'warning' &&
                <IconWarning className={`wds-icon-svg ${type}`} size='lg' />
              }

              {typeOverride === 'error' &&
                <IconX className={`wds-icon-svg ${type}`} size='lg' />
              }

              {typeOverride === 'success' &&
                <IconCheck className={`wds-icon-svg ${type}`} size='lg' />
              }
              {title}
            </ToastTitle>
          }
          {body &&
            <ToastBody>
              <span className='toast-body'>
                {body}
              </span>
            </ToastBody>
          }
        </Toast>
      </span>
    )
  }

  return null
}

Toasts.propTypes = {
  dispatch: PropTypes.func,
  toasts: PropTypes.object
}

const select = state => ({
  toasts: state.toasts
})

export default connect(select)(Toasts)
