import './FilteringExpression.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'
import omit from 'lodash.omit'
import {
  Align,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@sm/wds-react'

import { Modal } from '../Modal'
import { FilterExpressions } from '../../../components/FilterExpressions'

import {
  getAllFields,
  getConnectorList,
  getFieldType,
  updateConditionsIfErrorExists
} from '../../../models/entries'
import { getEntryManagerFilterCreated } from '../../../models/mixpanel'
import { mixpanel } from '../../../services/telemetry/mixpanel'
import { setSetting } from '../../../services/settings/settings'
import { API_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class FilteringExpression extends React.Component {

  constructor(props) {
    super(props)
    const { api, entries } = props
    const { conditions, lastId } = entries.queryParams.expression
    this.fieldList = getAllFields(entries.fieldSets)

    let newConditions = conditions.length === 0 ? [{
      id: lastId + 1,
      field: this.getDefaultField(),
      connector: this.getDefaultConnector(),
      value: '',
      isUserField: true
    }] : conditions
    newConditions = updateConditionsIfErrorExists(newConditions, api.errors['entries-list'])

    this.state = {
      ...entries.queryParams.expression,
      conditions: newConditions,
      lastId: lastId + 1,
      pendingAdd: false
    }
  }

  //auto-scroll to bottom when adding a condition
  componentDidUpdate(prevProps, prevState) {
    const { pendingAdd } = this.state
    if (!prevState.pendingAdd && pendingAdd) {
      this.setState({ pendingAdd: false })
    }
    if (prevState.pendingAdd && !pendingAdd) {
      const bodyRef = document.getElementsByClassName('wds-modal__body')[0]
      bodyRef.scrollTop = bodyRef.scrollHeight
    }
  }

  getDefaultField = () => {
    return {
      id: this.fieldList?.[0]?.id,
      title: this.fieldList?.[0]?.title
    }
  }

  getDefaultConnector = () => {
    const { entries } = this.props
    return getConnectorList(getFieldType(entries.fieldSets[0]))[0]
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  handleApply = () => {
    const { conditions } = this.state
    const { entries, form, dispatch } = this.props
    const formId = form.form.id
    dispatch({ type: 'CHANGE_ENTRIES_QUERY_PARAMS', data: {
      filterKey: 'expression',
      page: 1,
      expression: this.state
    }})
    setSetting('entry', formId)('filterKey', 'expression')
    setSetting('entry', formId)('expression', this.state)

    const eventDetails = getEntryManagerFilterCreated(formId, {
      isFilteredByFormFields: !!conditions.find(c => !!c.isUserField),
      metadataFields: conditions.filter(c => !c.isUserField).map(c => c.field.id).join(', '),
      numOfEntries: entries.stats.totalEntries,
      numOfFilterArguments: conditions?.length || 0,
    })
    mixpanel.track(eventDetails.event, eventDetails.properties)
    this.handleClose()
  }

  handleConditionChange = (condition) => {
    const { conditions } = this.state
    const index = conditions.findIndex(con => con.id === condition.id)
    const newConditions = [ ...conditions ]
    newConditions[index] = condition
    this.setState({ ...this.state, conditions: newConditions })
  }

  handleConditionRemove = (condition) => {
    const { conditions } = this.state
    let newConditions = [ ...conditions ]
    if (newConditions.length === 1) {
      newConditions[0] = {
        field: this.getDefaultField(),
        connector: this.getDefaultConnector(),
        value: ''
      }
    } else {
      newConditions = newConditions.filter(con => con.id !== condition.id)
    }
    this.setState({ ...this.state, conditions: newConditions })
  }

  handleConditionAdd = () => {
    const { conditions, lastId } = this.state
    const newConditions = conditions.concat([{
      id: lastId + 1,
      isUserField: true,
      field: this.getDefaultField(),
      connector: this.getDefaultConnector(),
      value: ''
    }])
    this.setState({ ...this.state, conditions: newConditions, lastId: lastId + 1, pendingAdd: true })
  }

  handleOperatorChange = () => {
    const { operator } = this.state
    this.setState({ operator: operator === 'and' ? 'or' : 'and' })
  }

  getInputHeader = () => {
    const { t } = this.props
    const { conditions, operator } = this.state

    if (conditions.length === 1) {
      return t('expression_input_first_header')
    }
    return t('expression_input_second_header', { operator: t(`operator_${operator}`) })
  }

  areConditionsComplete = () => {
    const { conditions } = this.state
    return conditions.every(condition => {
      const values = Object.values(omit(condition, [ 'id', 'isUserField' ]))
      return !condition.error && values.every(value => !isEmpty(value))
    })
  }

  canRemoveCondition = () => {
    const { conditions } = this.state
    if (conditions.length > 1) {
      return true
    }
    const { field, connector, value } = conditions[0]
    if (field.id !== this.getDefaultField().id
      || connector !== this.getDefaultConnector()
      || !isEmpty(value)) {
      return true
    }
    return false
  }

  render(){
    const { t, entries, user } = this.props
    this.fieldList = getAllFields(entries.fieldSets)

    return (
      <Modal
        className='filter-expression-modal'
        name='new-file-details'
        size='md'
      >
        <ModalHeader
          header={(
            <div className='filter-expression-header'>
              <h1 className='wds-modal__title'>
                {t('filter_expression_header')}
              </h1>
            </div>
          )}
        />

        <ModalBody>
          <div className='filter-expression-container'>
            <span>{this.getInputHeader()}</span>
            <div className='filter-expression-list'>
              <FilterExpressions
                expressions={this.state}
                hasPayment={!!entries.stats.currentCurrency}
                handleApply={this.handleApply}
                handleOperatorChange={this.handleOperatorChange}
                handleConditionChange={this.handleConditionChange}
                handleRemove={this.handleConditionRemove}
                handleAdd={this.handleConditionAdd}
                fieldSets={entries.fieldSets}
                fieldList={this.fieldList}
                complete={this.areConditionsComplete()}
                canRemove={this.canRemoveCondition()}
                userTimezone={user.timezone}
              />
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Align placement='right'>
            <Button
              variant='ghost'
              color='secondary'
              size='sm'
              onClick={this.handleClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              size='sm'
              disabled={!this.areConditionsComplete()}
              onClick={this.handleApply}
            >
              { t('APPLY')}
            </Button>
          </Align>
        </ModalFooter>
      </Modal>
    )
  }
}

const select = (state) => ({
  api: state.api,
  form: state.form,
  entries: state.entries,
  user: state.user
})

FilteringExpression.propTypes = {
  api: PropTypes.shape(API_STATE_PROPS).isRequired,
  form: PropTypes.object,
  entries: PropTypes.object,
  dispatch: PropTypes.func,
  t: PropTypes.func,
  user: PropTypes.object
}

FilteringExpression = withTranslation('modals')(FilteringExpression)
FilteringExpression = connect(select)(FilteringExpression)

export default FilteringExpression
