import { createFlagsReducer, isFeatureEnabled, isToolEnabled, getConfig } from '../services/settings/featureFlags'


export default createFlagsReducer({
  isEnabled: isToolEnabled(),
  isExpanded: false,
  selectedTab: 'features',
  configs: {
    bluefoo: isFeatureEnabled('bluefoo'),
    formBuilder: isFeatureEnabled('formBuilder'),
    formBuilderFeatureCompliant: isFeatureEnabled('formBuilderFeatureCompliant'),
    errorMode: isFeatureEnabled('errorMode'),
    debugMode: isFeatureEnabled('debugMode'),
    dialogs: isFeatureEnabled('dialogs'),
    mixpanel: isFeatureEnabled('mixpanel'),
    prodMode: isFeatureEnabled('prodMode'),
    protocol: getConfig('protocol') || process.env.REACT_APP_PROTOCOL,
    domain: getConfig('domain') || process.env.REACT_APP_DOMAIN,
    apiUrl: getConfig('apiUrl') || process.env.REACT_APP_API_URL,
    submissionApiUrl: getConfig('submissionApiUrl') || process.env.REACT_APP_SUBMISSION_API_URL,
    legacyUrl: getConfig('legacyUrl') || process.env.REACT_APP_LEGACY_URL,
    autoSaveInterval: getConfig('autoSaveInterval') || 10,
    appVersion: process.env.REACT_APP_VERSION,
    gitCommitHash: process.env.REACT_APP_GIT_COMMIT_HASH
  }
})
