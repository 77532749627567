const defaultState = {
  downgradeDate: 0,
  code: 0,
  message: ''
}

export default (state = defaultState, { type, data }) => {
  switch (type) {
    case 'SET_BILLING_STATUS':
      return {
        ...state,
        ...data
      }
    default:
      return state
  }
}