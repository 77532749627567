import './Footer.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Copyright } from './Copyright'
import { SocialLinks } from '../Social/SocialLinks'
import { SecurityBadges } from '../Badges'
import { LinkList } from './LinkList'
import { ChangeLanguage } from '../ChangeLanguage'

import { isTruthy } from '../../utils/boolean'

import {
  Grid,
  Col
} from '@sm/wds-react'

export const Footer= props => {
  const { t, currentLanguage } = props
  const {
    REACT_APP_USE_LEGACY_GDPR_BANNER
  } = process.env

  return (
    <footer>
      <Grid className='footer-desktop'>
        <div className='row'>
          <Col lg={8}>
            <div className='row'>
              <Col lg={3}>
                <LinkList
                  title={t('about')}
                  links={[{
                    text: t('blog'),
                    href: 'https://www.wufoo.com/blog/'
                  }, {
                    text: t('careers'),
                    href: 'https://www.surveymonkey.com/mp/jobs/'
                  }, {
                    text: t('sitemap'),
                    href: 'https://www.wufoo.com/sitemap/'
                  }]}
                />
              </Col>
              <Col lg={3}>
                <LinkList
                  title={t('support')}
                  links={[{
                    text: t('help_center'),
                    href: 'https://main.wufoo.com/docs/'
                  }, {
                    text: t('guides'),
                    href: 'https://www.wufoo.com/guides/'
                  }, {
                    text: t('faqs'),
                    href: 'https://www.wufoo.com/why-wufoo/#faqs-span'
                  }]}
                />
              </Col>
              <Col lg={3}>
                <LinkList
                  title={t('helpful_links')}
                  links={[{
                    text: t('accept_payments'),
                    href: 'https://www.wufoo.com/payments/'
                  }, {
                    text: t('template_gallery'),
                    href: 'https://www.wufoo.com/gallery/templates/'
                  }, {
                    text: t('integrations'),
                    href: 'https://www.wufoo.com/partners/'
                  }, {
                    text: t('mobile_forms'),
                    href: 'https://www.wufoo.com/mobile-forms/'
                  }]}
                />
              </Col>
              <Col lg={3}>
                <LinkList
                  title={t('legal')}
                  links={[{
                    text: t('terms_of_service'),
                    href: 'https://www.wufoo.com/terms/'
                  }, {
                    text: t('privacy'),
                    href: 'https://www.wufoo.com/privacy/'
                  }, {
                    text: t('california_privacy_notice'),
                    href: 'https://www.surveymonkey.com/mp/legal/region-specific-privacy-statement/',
                    rel: 'nofollow'
                  }, !isTruthy(REACT_APP_USE_LEGACY_GDPR_BANNER) ? {
                    text: t('cookie_settings'),
                    id: 'ot-sdk-btn',
                    className: 'ot-sdk-show-settings'
                  } : null, {
                    text: t('report_abuse'),
                    href: 'https://master.wufoo.com/forms/m7p0x3/'
                  }].filter(a => a !== null)}
                />
              </Col>
            </div>
          </Col>

          <Col lg={4}>
            <div className='text-center'>
              <p>{t('on_it')}</p>
            </div>
            <div className='row pt-2'>
              <Col lg={2} xs={0}><span /></Col>
              <Col lg={8} xs={12}>
                <SocialLinks />
                <SecurityBadges />
              </Col>
            </div>
          </Col>
        </div>

        <div className='bottom-row pt-4 row'>
          <Col lg={8}>
            <Copyright />
          </Col>
          <Col lg={4} className='language-select text-center'>
            <ChangeLanguage currentLanguage={currentLanguage} />
          </Col>
        </div>
      </Grid>
    </footer>
  )
}

Footer.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  t: PropTypes.func
}

export default withTranslation('footer')(Footer)
