import { recursiveActOnSelection } from '../models/selection'
import { uniq } from '../utils/array'

export const defaultState = {
  entries: [],
  files: [],
  forms: [],
  labels: [],
  shiftActivated: false,
  lastSelected: null
}

export default (state=defaultState, { type, data }) => {
  let newState

  switch (type) {
    case 'REMOVE_DUPLICATED_SELECTION': {
      const { depthStack, isSelected, items, sourceItems, type } = data
      newState = { ...state }

      if (isSelected && items.length > 0) {
        const sourceItem = sourceItems.find(i => i.id === items[0])

        // Remove the direct parent from selection list if found
        if (sourceItem?.parentId) {
          newState[type] = newState[type].filter(s => s !== sourceItem.parentId)
        }
        // Remove ancestors from selection list if found
        if (depthStack?.length > 1) {
          newState[type] = newState[type].filter(s => !depthStack.find(ds => ds.id === s))
        }
        // Recursively remove children from selection if found
        recursiveActOnSelection(type, sourceItem[type], (selection) => {
          newState[type] = newState[type].filter(s => s !== selection.id)
        })
      }

      return newState
    }
    case 'SHIFT_ACTIVATED':
      return { ...state, shiftActivated: data }
    case 'RESET_SELECTED':
      return {
        ...state,
        entries: [],
        files: [],
        forms: [],
        labels: [],
        lastSelected: null
      }
    case 'SET_SELECTED': {
      const {
        isSelected,
        items,
        sourceItems,
        type,
      } = data
      const selected = [ ...state[type] ]
      newState = { ...state }

      if (items.length === 1) {
        newState.lastSelected = items[0]
      }

      let newItems = items

      // Bulk select
      if (state.shiftActivated && !!sourceItems && items.length === 1) {
        const lastSelectedIndex = sourceItems.findIndex(f => f.id === state.lastSelected)
        const selectedIndex = sourceItems.findIndex(f => f.id === items[0])

        if (lastSelectedIndex >= 0 && selectedIndex >= 0) {
          const first = Math.min(lastSelectedIndex, selectedIndex)
          const last = Math.max(lastSelectedIndex, selectedIndex)

          newItems = sourceItems.slice(first, last + 1).map(item => item.id)
        }
      }

      if (isSelected) {
        newState[type] = uniq([ ...selected, ...newItems ])
      }
      else {
        newState[type] = selected.filter(s => !newItems.includes(s))
      }

      return newState
    }
    default:
      return state
  }

}

