export const defaultState = {
  optedOutOfBeta: false,
  systemNotifications: []
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        ...data
      }
    case 'GET_BILLING_STATUS':
      return {
        ...state,
        ...data
      }
    case 'SET_SYSTEM_NOTIFICATIONS':
      return {
        ...state,
        systemNotifications: data.systemNotifications
      }
    case 'OPTED_OUT_OF_BETA':
      return {
        ...state,
        optedOutOfBeta: true
      }
    default:
      return state
  }

}
