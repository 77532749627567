import { getSetting, setSetting } from '../services/settings/settings'

const defaultState = {
  isCreating: false,
  isDeleting: false,
  isEditing: false,
  isLoading: true,
  showMultiselect: false,
  labels: [],
  managerLabels: [],
  dialogueLabels: [],
  remainingLabels: [],
  selectedLabels: getSetting('label')('selectedLabels') || [],
  filterQueryParams: {
    sortKey: 'name',
    sortDirection: 'asc',
    pageSize: 100,
    page: 1,
    labelIds: [],
  },
  managerQueryParams: {
    query: '',
    sortKey: 'name',
    sortDirection: 'asc',
    pageSize: 100,
    page: 1,
  },
  dialogueQueryParams: {
    query: '',
    sortKey: 'name',
    sortDirection: 'asc',
    pageSize: 100,
    page: 1,
  },
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_FETCH_LABELS_LOADING': {
      return { ...state, isLoading: data }
    }
    case 'SET_SHOW_MULTISELECT': {
      return { ...state, showMultiselect: data }
    }
    case 'SET_CREATE_LABEL_LOADING': {
      return { ...state, isCreating: data }
    }
    case 'SET_DELETE_LABEL_LOADING': {
      return { ...state, isDeleting: data }
    }
    case 'SET_EDIT_LABEL_LOADING': {
      return { ...state, isEditing: data }
    }
    case 'CHANGE_LABELS_MANAGER_QUERY_PARAMS': {
      return {
        ...state,
        managerQueryParams: {
          ...state.managerQueryParams,
          ...data
        }
      }
    }
    case 'CHANGE_LABELS_DIALOGUE_QUERY_PARAMS': {
      return {
        ...state,
        dialogueQueryParams: {
          ...state.dialogueQueryParams,
          ...data
        }
      }
    }
    case 'CHANGE_LABELS_FILTER_QUERY_PARAMS': {
      return {
        ...state,
        filterQueryParams: {
          ...state.filterQueryParams,
          ...data
        }
      }
    }
    case 'UPDATE_LABEL': {
      const selectedLabels = state.selectedLabels.map(label => label.id === data.id ? data : label)
      const dialogueLabels = state.dialogueLabels.map(label => label.id === data.id ? data : label)
      const managerLabels = state.managerLabels.map(label => label.id === data.id ? data : label)
      setSetting('label')('selectedLabels', selectedLabels)

      return {
        ...state,
        labels: state.labels.map(label => label.id === data.id ? data : label),
        selectedLabels: selectedLabels,
        remainingLabels: state.remainingLabels.map(label => label.id === data.id ? data : label),
        dialogueLabels: dialogueLabels,
        managerLabels: managerLabels,
      }
    }
    case 'SET_LABELS': {
      return {
        ...state,
        labels: data,
        isLoading: false
      }
    }
    case 'SET_DIALOGUE_LABELS': {
      return {
        ...state,
        dialogueLabels: data,
        isLoading: false
      }
    }
    case 'SET_MANAGER_LABELS': {
      return {
        ...state,
        managerLabels: data,
        isLoading: false
      }
    }
    case 'SET_REMAINING_LABELS': {
      return {
        ...state,
        remainingLabels: data,
        isLoading: false
      }
    }
    case 'SET_SELECTED_LABELS': {
      const selectedLabels = data || []
      setSetting('label')('selectedLabels', selectedLabels)

      return {
        ...state,
        selectedLabels
      }
    }
    default:
      return state
  }

}
