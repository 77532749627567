import './FilenameCell.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Thumbnail } from '../Thumbnail'

import { keyPressed } from '../../../utils/a11y'
import { toID } from '../../../utils/string'

export class FilenameCell extends React.Component {
  render() {
    const {
      childCount,
      ext,
      filename,
      handleClick,
      link,
      type,
      ...rest
    } = this.props

    const classNames = classnames(
      'filename-cell',
    )

    return (
      <div
        className={classNames}
        id={toID(`files-view-${filename}`)}
        onClick={handleClick}
        onKeyPress={keyPressed('Enter', handleClick)}
        tabIndex='0'
      >
        <Thumbnail
          link={link}
          name={filename}
          type={type}
          ext={ext}
          {...rest}
        />

        <span className='filename'>
          {filename}
        </span>

        {type === 'folder' && childCount >= 0 &&
          <span className='folder-child-count'>
            {childCount}
          </span>
        }
      </div>
    )
  }
}

FilenameCell.propTypes = {
  childCount: PropTypes.number,
  ext: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  link: PropTypes.string,
  type: PropTypes.oneOf([ 'folder', 'file' ]).isRequired
}

export default FilenameCell
