import './WuTools.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { setFlagsAction } from '../../services/settings/featureFlags'
import { saveConfig, restoreConfig } from '../../actions/flags'

import { Drawer } from '../../components/Drawer'
import { FeatureContent } from '../../components/WuTools/FeatureContent'
import { ConfigContent } from '../../components/WuTools/ConfigContent'
import { InfoContent } from '../../components/WuTools/InfoContent'
import { WuToolTabs } from '../../components/WuTools/WuToolTabs'

import { Button } from '@sm/wds-react'
import { IconMore } from '@sm/wds-icons'
import { FLAGS_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


export class WuTools extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isExposed: false
    }
  }

  handleDrawerToggle = () => {
    const { dispatch, flags } = this.props
    const isExpanded = flags.isExpanded ? false : true
    dispatch(setFlagsAction({ isExpanded }))
  }

  handleTabSwitch = (id) => {
    const { dispatch } = this.props
    const selectedTab = id
    dispatch(setFlagsAction({ selectedTab }))
  }

  handleFeatureToggle = (key) => {
    const { flags } = this.props
    const value = flags.configs[key] ? false : true
    this.setConfig(key, value)
    saveConfig(key, value)
  }

  setConfig = (key, value) => {
    const { dispatch } = this.props
    dispatch(setFlagsAction({
      configs: {
        [key]: value
      }
    }))
  }

  setExposed = isExposed => {
    this.setState({ isExposed })
  }

  restoreConfig = () => {
    const { dispatch } = this.props
    dispatch(restoreConfig())
  }

  renderTitle = () => {
    return (
      <div className='wutools-title'>
        <Button
          onClick={this.handleDrawerToggle}
          variant='text'
        >
          <IconMore />
        </Button>
      </div>
    )
  }

  renderHeader = () => {
    const { t, flags } = this.props

    return (
      <div className='wutools-header'>
        <WuToolTabs
          tabs={[{
            id: 'features',
            title: t('FEATURE_FLAGS')
          }, {
            id: 'devtools',
            title: t('DEVTOOLS')
          }, {
            id: 'configs',
            title: t('CONFIGS')
          }, {
            id: 'info',
            title: t('INFO')
          }, {
            id: 'fun',
            title: t('FUN')
          }]}
          selected={flags.selectedTab}
          onChange={this.handleTabSwitch}
        />
        <Button
          onClick={this.restoreConfig}
        >
          {t('RESET')}
        </Button>
      </div>
    )
  }

  renderContent = () => {
    const { t, flags } = this.props

    return (
      <div className='wutools-content'>
        {flags.selectedTab === 'features' &&
          <React.Fragment>
            <div className='wutools-content-row'>
              <FeatureContent
                configs={[{
                  key: 'mixpanel',
                  title: t('Mixpanel'),
                  description: t('mixpanel_description'),
                  enabled: flags.configs.mixpanel,
                }]}
                onChange={this.handleFeatureToggle}
              />
              <FeatureContent
                configs={[{
                  key: 'dialogs',
                  title: t('Welcome_Dialogs'),
                  description: t('welcome_dialogs_description'),
                  enabled: flags.configs.dialogs,
                }]}
                onChange={this.handleFeatureToggle}
              />
              <FeatureContent
                configs={[{
                  key: 'errorMode',
                  title: t('Error_Mode'),
                  description: t('error_mode_description'),
                  enabled: flags.configs.errorMode,
                }]}
                onChange={this.handleFeatureToggle}
              />
            </div>
          </React.Fragment>
        }

        {flags.selectedTab === 'devtools' && (
          <React.Fragment>
            <FeatureContent
              configs={[{
                key: 'debugMode',
                title: 'Debug Mode',
                description: 'Enables redux sidebar (ctrl + h)',
                enabled: flags.configs.debugMode,
              }]}
              onChange={this.handleFeatureToggle}
            />
            <FeatureContent
              configs={[{
                key: 'prodMode',
                title: t('Production_Mode'),
                description: t('prod_mode_description'),
                enabled: flags.configs.prodMode,
              }]}
              onChange={this.handleFeatureToggle}
            />
          </React.Fragment>
        )}

        {flags.selectedTab === 'configs' &&
          <ConfigContent
            configs={[{
              key: 'protocol',
              value: flags.configs.protocol,
              label: t('Protocol')
            }, {
              key: 'domain',
              value: flags.configs.domain,
              label: t('Domain')
            }, {
              key: 'apiUrl',
              value: flags.configs.apiUrl,
              label: t('API_Url')
            }, {
              key: 'submissionApiUrl',
              value: flags.configs.submissionApiUrl,
              label: t('Submission_API_Url')
            }, {
              key: 'legacyUrl',
              value: flags.configs.legacyUrl,
              label: t('Legacy_Url')
            }, {
              key: 'autoSaveInterval',
              value: Number(flags.configs.autoSaveInterval) || 10,
              label: t('Autosave_Interval'),
              isNumber: true,
              defaultValue: 10
            }]}
            onChange={this.setConfig}
            onSave={saveConfig}
          />
        }

        {flags.selectedTab === 'info' &&
          <React.Fragment>
            <InfoContent
              info={[{
                key: 'appVersion',
                value: flags.configs.appVersion,
                label: t('app_version')
              }, {
                key: 'commitHash',
                value: flags.configs.gitCommitHash,
                label: t('commit_hash')
              }]}
            />
          </React.Fragment>
        }

        {flags.selectedTab === 'fun' &&
          <React.Fragment>
            <FeatureContent
              configs={[{
                key: 'bluefoo',
                title: 'Bluefoo',
                description: 'Turns Wufoo into Bluefoo',
                enabled: flags.configs.bluefoo,
              }]}
              onChange={this.handleFeatureToggle}
            />
            <div>
              <Button
                onClick={() => {
                  this.handleDrawerToggle()
                  setTimeout(() => {
                    window.startDestruction()
                  }, 1000)
                }}
                color='warning'
              >
                Spawn Self-Destruct Ship
              </Button>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }

  renderExpandedDrawer = () => {
    return (
      <Drawer
        isOpen={true}
        className={'wutools-drawer-expanded'}
      >
        <div className='wutools-container'>
          {this.renderTitle()}
          {this.renderHeader()}
          {this.renderContent()}
        </div>
      </Drawer>
    )
  }

  renderCollapsedDrawer = () => {
    const { isExposed } = this.state

    return (
      <Drawer
        isOpen={true}
        className={classnames(
          'wutools-drawer-collapsed',
          { 'exposed': isExposed }
        )}
      >
        <div
          className='drawer-toggle'
          onClick={this.handleDrawerToggle}
          onMouseOver={() => { this.setExposed(true) }}
          onMouseLeave={() => this.setExposed(false)}
        />
      </Drawer>
    )
  }

  render() {
    const { flags } = this.props
    return flags.isExpanded? this.renderExpandedDrawer() : this.renderCollapsedDrawer()
  }
}

WuTools.propTypes = {
  dispatch: PropTypes.func.isRequired,
  flags: PropTypes.shape(FLAGS_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  flags: state.flags,
})

WuTools = connect(select)(WuTools)
WuTools = withTranslation('wutools')(WuTools)

export default WuTools
