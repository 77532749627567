import './FormUseTrackingModal.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ProgressBar
} from '@sm/wds-react'
import FormUseCard from './FormUseCard'
import { Modal } from '../Modal'
import { sendUserUseCase } from '../../../actions/user'
import { formUseCards, industryCards } from '../../../constants/formUseTracking'


export class FormUseTrackingModal extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      cardsSelected: [],
      isFormUsageSelected: false,
      isIndustrySelected: false,
      isOtherOptionSelected: false,
      modalPage: 1,
      industryOther: '',
      useCasesOther: '',
      userUseCase: {}
    }
  }

  handleModalClose = () => {
    const { dispatch } = this.props
    const { userUseCase } = this.state

    dispatch(sendUserUseCase({ ...userUseCase }))
  }

  handleNext = () => {
    const {
      cardsSelected,
      industryOther,
      modalPage,
      useCasesOther,
      userUseCase
    } = this.state
    const { dispatch } = this.props

    if (modalPage === 1) {
      this.setState({
        cardsSelected: [],
        userUseCase: {
          ...userUseCase,
          useCases: cardsSelected,
          useCasesOther
        },
        isOtherOptionSelected: false,
        modalPage: modalPage + 1
      })
    } else {
      dispatch(sendUserUseCase({
        ...userUseCase,
        industries: cardsSelected,
        industryOther
      }))
      this.handleToast()
    }
  }

  handleSkip = () => {
    const {
      modalPage,
      userUseCase
    } = this.state
    const { dispatch } = this.props

    if (modalPage === 1) {
      this.setState({
        modalPage: modalPage + 1,
        cardsSelected: [],
        isOtherOptionSelected: false,
        userUseCase: {
          ...userUseCase,
          useCases: [],
          useCasesOther: ''
        }
      })
    } else {
      dispatch(sendUserUseCase({
        ...userUseCase,
        industries: [],
        industryOther: ''
      }))
      this.handleToast()
    }
  }

  handleSelect = (selected, cardName) => {
    const { cardsSelected, modalPage } = this.state
    let cardsSelectedUpdated

    // if card is being selected
    if (selected) {
      const cardSelected = cardsSelected.find(cardSelected => cardSelected === cardName)
      if (cardSelected) {
        // selecting card that had already been selected
        const index = cardsSelected.indexOf(cardSelected)
        cardsSelectedUpdated = cardsSelected.splice(index, 1)
      } else {
        // selecting card that had NOT been selected yet
        cardsSelected.push(cardName)
        cardsSelectedUpdated = cardsSelected
      }
    } else { // if card is being de-selected
      const cardSelected = cardsSelected.find(cardSelected => cardSelected === cardName)
      const index = cardsSelected.indexOf(cardSelected)
      cardsSelected.splice(index, 1)
      cardsSelectedUpdated = cardsSelected
    }
    const isOtherOptionSelected = !!cardsSelectedUpdated.find(card => card === 'other')

    this.setState({ cardsSelected: cardsSelectedUpdated })
    this.setState({ isOtherOptionSelected: isOtherOptionSelected })
    const setSelected = modalPage === 1 ? {
      isFormUsageSelected: cardsSelectedUpdated.length > 0
    } : {
      isIndustrySelected: cardsSelectedUpdated.length > 0
    }
    this.setState({ ...setSelected })
  }

  handleToast = () => {
    const { dispatch, t } = this.props
    dispatch({ type: 'SHOW_TOAST', data: {
      type: 'success',
      title: t('thank_you'),
      body: t('this_information_helps_us_improve_wufoo')
    }})
  }

  handleBlur = e => {
    const { modalPage } = this.state
    const updatedState = modalPage === 1 ? { useCasesOther: e.target.value } : { industryOther: e.target.value }
    this.setState({ ...updatedState })
  }

  render() {
    const { t } = this.props
    const {
      cardsSelected,
      isFormUsageSelected,
      isIndustrySelected,
      isOtherOptionSelected,
      modalPage
    } = this.state

    const isFirstPage = modalPage === 1
    const cards = isFirstPage ? formUseCards : industryCards
    const isCardSelected = isFirstPage ? isFormUsageSelected : isIndustrySelected
    const answeredLabel = `${isFormUsageSelected && isIndustrySelected ? '2' : (isFormUsageSelected || isIndustrySelected) ? '1' : '0'} of 2 ${t('answered')}`
    const answeredProgressBar = isFormUsageSelected && isIndustrySelected ? '100' : (isFormUsageSelected || isIndustrySelected) ? '50' : '0'


    return (
      <Modal
        className={classnames(
          'tell-us-about-modal',
          isFirstPage ? 'form-use-page' : 'industry-page'
        )}
        onClose={this.handleModalClose}
      >
        <ModalHeader
          header={isFirstPage ?
            t('how_do_you_use_wufoo_forms')
            :
            t('what_type_of_work_do_you_do')
          }
          subHeader={isFirstPage ? t('select_all_the_ways_you_use_forms') : null}
        />
        <ModalBody>
          {cards.map((card, index) => (
            <FormUseCard
              key={index}
              card={card}
              handleSelect={(selected, cardName) => this.handleSelect(selected, cardName)}
              selected={!!cardsSelected.find(cardSelected => cardSelected === card.name)}
            />
          ))}
          {isOtherOptionSelected && (
            <div className='other-field-container'>
              <div className='other-field-label'>{t('something_else')}</div>
              <Input
                className='other-field-input'
                stretched
                placeholder={isFirstPage ? t('enter_your_use_case_placeholder') : t('enter_your_work_placeholder')}
                onBlur={this.handleBlur}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className='progress-bar-container'>
            <div className='progress-bar-label'>{answeredLabel}</div>
            <ProgressBar
              color='info'
              percent={answeredProgressBar}
              size='sm'
            />
          </div>
          <div className='modal-action-buttons'>
            <Button
              className='skip-button'
              variant='text'
              onClick={this.handleSkip}
            >
              {t('skip_button')}
            </Button>
            <Button
              className='next-button'
              disabled={!isCardSelected}
              onClick={this.handleNext}
            >
              {isFirstPage? t('next_button') : t('done_button')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

FormUseTrackingModal.propTypes = {
  dispatch: PropTypes.func,
  t: PropTypes.func
}

const select = () => ({})

FormUseTrackingModal = withTranslation('modals')(FormUseTrackingModal)
FormUseTrackingModal = connect(select)(FormUseTrackingModal)

export default FormUseTrackingModal
