import './Loader.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ProgressCircle } from '@sm/wds-react'

export const Loader = props => {
  const {
    className,
    dummy,
    isTableRow,
    fixed,
    loading,
    children,
    size,
    transparent,
    ...rest
  } = props

  let rendered
  let spinner

  if (typeof children === 'function') {
    rendered = children()
  }
  else {
    rendered = children
  }

  if (!loading) {
    if (!className) {
      return rendered
    }

    return (
      <div className={className}>
        {rendered}
      </div>
    )
  }

  const classNames = classnames(
    'loader',
    className,
    { 'loader-fixed': fixed }
  )

  if (transparent) {
    spinner = (
      <div className='transparent-overlay'>
        <ProgressCircle
          color='primary'
          continuous
          size={size}
        />
      </div>
    )
  }
  else if (dummy) {
    rendered = dummy
  } else {
    spinner = (
      <ProgressCircle
        color='primary'
        continuous
        size={size}
      />
    )
  }

  if (isTableRow) {
    return (
      <td colSpan={100} className={className}>
        {spinner}
      </td>
    )
  }

  return (
    <div className={classNames} {...rest}>
      <div className='loading-overlay'>
        {spinner}
      </div>
      <div className={classnames('loading-content', { transparent })}>
        {rendered}
      </div>
    </div>
  )
}

Loader.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.node, PropTypes.func ]),
  className: PropTypes.string,
  dummy: PropTypes.node,
  fixed: PropTypes.bool,
  isTableRow: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  size: PropTypes.string,
  transparent: PropTypes.bool
}

Loader.defaultProps = {
  isTableRow: false,
  className: '',
  dummy: null,
  fixed: false,
  size: 'md'
}

export default Loader
