import './Icon.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Tooltip } from '@sm/wds-react'
import { a11yClick, blurClick } from '../../utils/a11y'

export const Icon = props => {
  const {
    children,
    className,
    clickable,
    color,
    innerRef,
    label,
    placement,
    round,
    size,
    handleClick,
    handleFocus,
    isDisabled,
    ...rest
  } = props

  const tabIndex = clickable ? '0' : undefined

  const classNames = classnames(
    'wufoo-icon',
    `wufoo-icon-${size}`,
    `wufoo-icon-${color}`,
    className,
    { round },
    { clickable },
    { 'disabled': isDisabled }
  )

  const handleAction = !isDisabled && handleClick ? blurClick(a11yClick(handleClick)) : undefined

  const icon = (
    <span
      className={classNames}
      disabled={isDisabled}
      onClick={handleAction}
      onKeyPress={handleAction}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      ref={innerRef}
      {...rest}
    >
      {children}
    </span>
  )

  if (label) {
    return (
      <Tooltip placement={placement} text={label}>
        {icon}
      </Tooltip>
    )
  }

  return icon
}

Icon.defaultProps = {
  className: '',
  children: null,
  clickable: false,
  color: 'default',
  handleClick: null,
  handleFocus: null,
  placement: 'bottom',
  round: false,
  size: 'md',
  isDisabled: false
}

Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  clickable: PropTypes.bool,
  color: PropTypes.string,
  innerRef: PropTypes.func,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  handleFocus: PropTypes.func,
  placement: PropTypes.string,
  round: PropTypes.bool,
  isDisabled: PropTypes.bool,
  size: PropTypes.string
}

export default Icon
