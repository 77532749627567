import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import api from './api'
import app from './app'
import billing from './billing'
import entry from './entry'
import entries from './entries'
import experiments from './experiments'
import exports from './exports'
import files from './files'
import flags from './flags'
import form from './form'
import forms from './forms'
import labels from './labels'
import legacy from './legacy'
import modal from './modal'
import plan from './plan'
import selections from '../reducers/selections'
import sheet from './sheet'
import toasts from './toasts'
import user from './user'

const createRootReducer = history => combineReducers({
  api,
  app,
  billing,
  entry,
  entries,
  experiments,
  exports,
  files,
  flags,
  form,
  forms,
  labels,
  legacy,
  modal,
  plan,
  selections,
  sheet,
  toasts,
  user,
  router: connectRouter(history)
})

export default createRootReducer
