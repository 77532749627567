import React from 'react'
import PropTypes from 'prop-types'
import { isFolderEmpty, isSelected, shouldBeExcluded, shouldDisplayEditingField } from '../../../models/files'
import { Dummy } from '../../../components/Dummy'
import { FolderRow } from '../FolderRow'
import { Loader } from '../../../components/Loader'
import { FolderEditingRow } from '../FolderEditingRow'

export class FolderTable extends React.Component {

  renderLevelIndent = (level) => {
    let indent = []

    for (let i = 0; i < level; i++) {
      indent.push(<div key={i} className='folder-indent' />)
    }

    return indent
  }

  renderFolderRows(currentFolder, parentId) {
    const { folders, newFolder, handleExpand, handleSelect, handleCancel, handleCreate, exclude }  = this.props
    let folderArray = []

    if (!isFolderEmpty(currentFolder)) {

      currentFolder.files.forEach((folder) => {

        if (!shouldBeExcluded(folder, exclude)) {

          const isOpening = folders.loadingMap[folder.id]
          const opened = folders[folder.id] && folders[folder.id].status === 'open'

          folderArray.push(
            <FolderRow
              folder={folder}
              level={currentFolder.level}
              isOpen={opened}
              isSelected={isSelected(folders.selectedFolder, folder.id, folder.filename, parentId)}
              parentId={parentId}
              handleExpand={handleExpand}
              handleSelect={handleSelect}
              renderIndent={this.renderLevelIndent}
              key={folder.id}
            />
          )

          if (isOpening) {
            folderArray.push(
              <Loader
                dummy={<Dummy rows={4} />}
                loading={isOpening}
                key={`${folder.id}-loader`}
              />
            )
          }

          if (opened) {
            folderArray = folderArray.concat(this.renderFolderRows(folders[folder.id], folder.id))
          }
        }
      })
    }

    if (shouldDisplayEditingField(newFolder, parentId)) {
      folderArray.push(
        <FolderEditingRow
          level={currentFolder.level}
          type={'folder'}
          handleCancel={handleCancel}
          handleCreate={handleCreate}
          renderIndent={this.renderLevelIndent}
          key={'folder-row-edit'}
        />
      )
    }

    return folderArray
  }

  render() {
    const { folders, handleExpand, handleSelect, totalSizeUsed }  = this.props
    let folderArray = [ (
      <FolderRow
        folder={{
          id: 'root',
          filename: 'Home',
          type: 'folder',
          dateCreated: '',
          dateUpdated: '',
          size: totalSizeUsed
        }}
        level={0}
        isOpen={true}
        isSelected={folders.selectedFolder.id === 'root'}
        parentId={null}
        handleExpand={handleExpand}
        handleSelect={handleSelect}
        renderIndent={this.renderLevelIndent}
        key={'root'}
      />) ]

    if (folders.loadingMap['root']) {
      folderArray.push(
        <Loader
          dummy={<Dummy rows={4} />}
          loading={true}
          key={'0-loader'}
        />
      )
    }
    return folderArray.concat(this.renderFolderRows(folders['root'], 'root'))
  }
}

FolderTable.propTypes = {
  exclude: PropTypes.arrayOf(PropTypes.string),
  folders: PropTypes.object,
  newFolder: PropTypes.object,
  handleExpand: PropTypes.func,
  handleSelect: PropTypes.func,
  handleCancel: PropTypes.func,
  handleCreate: PropTypes.func,
  totalSizeUsed: PropTypes.number
}

export default FolderTable
