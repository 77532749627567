import './BillingBanner.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { Button } from '@sm/wds-react'
import { IconWarning } from '@sm/wds-icons'
import { getBillingStatus } from '../../../actions/billing'
import { LegacyLink } from '../../../components/LegacyLink/'
import { USER_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class BillingBanner extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(getBillingStatus())
  }

  render() {
    const { t, billing, user } = this.props

    if (billing.code === 0 || billing.downgradeDate === 0) {
      return null
    }

    const downgradeDate = moment(billing.downgradeDate)
    const todaysDate = moment().startOf('day')
    const daysRemaining = downgradeDate.diff(todaysDate, 'days')

    return (
      <div className='billing-banner'>
        <div className='wds-grid'>
          <div className='billing-banner-alert'>
            <div className='billing-banner-icon'>
              <IconWarning />
            </div>
            <div>
              <h3>{t('cant_process_credit_card')}</h3>
              <p className='billing-days-warning'>
                {t('update_billing_information', { count: daysRemaining })}
                {user.accountOwner === true ?
                  <Button size='sm' variant='secondary' className='update-payment-btn'>
                    <LegacyLink to={'/account/billing/?type=updateCC'}>UPDATE PAYMENT INFORMATION</LegacyLink>
                  </Button> : t('talk_to_account_owner')
                }

              </p>
            </div>
          </div>
          <div className='billing-countdown'>
            <p className='billing-countdown-number'>{ daysRemaining }</p>
            <p className='billing-countdown-text'>{t('days_remaining')}</p>
          </div>
        </div>
      </div>
    )
  }
}

BillingBanner.propTypes = {
  dispatch: PropTypes.func.isRequired,
  billing: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
}
const select = state => ({
  billing: state.billing,
  user: state.user,
})


BillingBanner = connect(select)(BillingBanner)
BillingBanner = withTranslation('plan')(BillingBanner)

export default BillingBanner
