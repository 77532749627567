import './FolderEditingRow.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Thumbnail } from '../Thumbnail'
import { EditableField } from '../../EditableField'
import { getFileType } from '../../../models/files'

export class FolderEditingRow extends React.Component {

  render() {

    const { type, level, handleCancel, handleCreate, renderIndent } = this.props
    const ext = getFileType(type)

    return (
      <div
        className='folder-row-edit'
      >
        {renderIndent(level)}
        <Thumbnail
          type={type}
          ext={ext}
          isFocus={false}
        />
        <EditableField
          value=''
          editing={true}
          handleCancel={handleCancel}
          handleEdit={handleCreate}
        />
      </div>
    )
  }
}

FolderEditingRow.propTypes = {
  level: PropTypes.number,
  type: PropTypes.string,
  handleCancel: PropTypes.func,
  handleCreate: PropTypes.func,
  renderIndent: PropTypes.func,
}

export default FolderEditingRow
