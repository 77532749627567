import { api, combineConditionsWithExpression, downloadFromUrl } from '../services/api'
import { buildEntriesExportsConditionsSpec } from '../models/exports'
import {
  EXPORT_FILES_ERROR_MAPS,
  POLL_INTERVAL,
  POLL_MAX_ATTEMPTS
} from '../constants/exports'
import { getToastConfig } from '../utils/exports'
import { withToastDisplay } from '../utils/toast'


const getEntryExportConfig = (formId, entrySpec, type) => {
  const { conditions, expression } = buildEntriesExportsConditionsSpec(entrySpec)
  return {
    urlPrefix: `/forms/${formId}/entries/requests/exports`,
    requestBody: {
      conditions: combineConditionsWithExpression(conditions, expression) || '',
      formId,
      type
    }
  }
}

const getFileExportConfig = (fileIds) => {
  return {
    urlPrefix: '/files/requests/exports',
    requestBody: {
      fileIds
    }
  }
}

const getFileExportErrorMessage = (e, translate) => {
  let errorMessage
  const error = e?.response?.data?.errors?.[0]
  if (error?.range_type) {
    errorMessage = translate(EXPORT_FILES_ERROR_MAPS[error.issue_code][error.range_type], {
      rangeMax: error.range_max,
      rangeMin: error.range_min
    })
  } else {
    errorMessage = translate(EXPORT_FILES_ERROR_MAPS[error.issue_code])
  }
  return errorMessage
}

const pollExportStatus = async (requestConfig, exportId, assignRejectPropsFunc) => {
  return new Promise((resolve, reject) => {
    let count = 0
    let timer

    const poll = async () => {
      const { data } = await api.get(`/exports/${exportId}`, {
        signal: requestConfig.controller
      })
      if (data.artifactMetadata.url) {
        resolve(data.artifactMetadata.url)
      } else if (data.state === 'cancelled') {
        // If the bulk request was cancelled it's possible it's between polling attempts
        // this prevents it from continuing to poll in that case
        resolve()
      } else if (data.state === 'errored'){
        reject('Bulk request failed to run')
      } else if (count < POLL_MAX_ATTEMPTS) {
        count++
        timer = setTimeout(() => {
          poll()
        }, POLL_INTERVAL)
        assignRejectPropsFunc(reject, exportId, timer)
      } else {
        reject('Bulk request timed out')
      }
    }
    poll()
  })
}

export const startExport = (requestConfig, toastConfig, assignRejectPropsFunc = () => {}) => {
  return async dispatch => {
    dispatch({ type: 'SET_EXPORTS_IN_PROGRESS', data: true })

    await withToastDisplay(dispatch, toastConfig, async () => {
      try {
        const { data: { id: exportId }} = await api.post(
          requestConfig.urlPrefix,
          requestConfig.requestBody,
          {
            signal: requestConfig.controllerSignal
          }
        )
        const exportUrl = await pollExportStatus(requestConfig, exportId, assignRejectPropsFunc)
        dispatch({ type: 'SET_EXPORT_REQUEST_URL', data: exportUrl })
        if (exportUrl) {
          downloadFromUrl(exportUrl)
        }
      } finally {
        dispatch({ type: 'CLOSE_MODAL_TYPE', data: 'export-in-progress-modal' })
        dispatch({ type:'SET_EXPORTS_IN_PROGRESS', data: false })
      }
    })
  }
}

export const cancelExport = async (exportId) => {
  if (exportId) {
    try {
      await api.patch(`/exports/${exportId}`, { state: 'cancelled' })
    } catch (e) {
      // Only if the promise is already resolved or the bulk request has already completed
      // No-op
    }
  }
}

export const exportEntries = (form, entrySpec, type) => {
  return async (dispatch, getState) => {
    const { exports: { inProgress }} = getState()
    if (inProgress) {
      dispatch({ type: 'OPEN_MODAL', data: 'export-in-progress-modal' })
    } else {
      const { id } = form
      const requestConfig = getEntryExportConfig(id, entrySpec, type)
      const requestController = new AbortController()
      let rejectPromise
      let exportId
      let _timer

      const assignRejectPropsFunc = (reject, id, timer = undefined) => {
        rejectPromise = reject
        exportId = id
        _timer = timer
      }

      const handleCancelExport = () => {
        requestController.abort()
        clearTimeout(_timer)
        rejectPromise('canceled')
        cancelExport(exportId)
      }

      const toastConfig = getToastConfig(handleCancelExport, {
        hideOnErrorKey: 'canceled'
      })
      await dispatch(startExport({
        ...requestConfig,
        controllerSignal: requestController.signal
      }, toastConfig, assignRejectPropsFunc))
    }
  }
}

export const exportFiles = (fileIds) => {
  return async (dispatch, getState) => {
    const { exports: { inProgress }} = getState()
    if (inProgress) {
      dispatch({ type: 'OPEN_MODAL', data: 'export-in-progress-modal' })
    } else {
      const requestConfig = getFileExportConfig(fileIds)
      const requestController = new AbortController()
      let rejectPromise
      let exportId
      let _timer


      const assignRejectPropsFunc = (reject, id, timer = undefined) => {
        rejectPromise = reject
        exportId = id
        _timer = timer
      }

      const handleCancelExport = () => {
        requestController.abort()
        clearTimeout(_timer)
        rejectPromise?.('canceled')
        cancelExport(exportId)
      }

      const toastConfig = getToastConfig(handleCancelExport, {
        getErrorMessage: getFileExportErrorMessage,
        hideOnErrorKey: 'canceled'
      })
      await dispatch(startExport({
        ...requestConfig,
        controllerSignal: requestController.signal
      }, toastConfig, assignRejectPropsFunc))
    }
  }
}
