import isEmpty from 'lodash.isempty'
import omit from 'lodash.omit'
import humps from 'humps'

// Restricted characters must be santized before being sent to the server
export const sanitize = field => {
  return `${field}`.replace(/\//g, '/s').replace(/-/g, '/h').replace(/\./g, '/d').replace(/~/g, '/t')
}

const getOperatorJoiner = (operator) => {
  return (!operator || operator === 'and') ? '-' : '~'
}

export const buildConditions = (conditions=[], operator) => {
  return conditions.map(c => {
    const key = humps.decamelize(sanitize(c.key))
    const op = c.op || '=='
    const value = sanitize(c.value)

    return `${key}.${op}${c.compareToNone === true ? '' : '.'}${value}`
  })
    .filter(c => !!c)
    .join(getOperatorJoiner(operator))
}

export const formatQueryParams = (queryParams) => {
  const params = humps.decamelizeKeys(queryParams)
  return new URLSearchParams(params).toString()
}

const getJoinedConditions = (conditions, expConditions) => {
  const isConditionsEmpty = isEmpty(conditions)
  const isExpressionConditionsEmpty = isEmpty(expConditions)
  if (isConditionsEmpty && isExpressionConditionsEmpty) {
    return
  }
  if (isConditionsEmpty) {
    return expConditions
  }
  if (isExpressionConditionsEmpty) {
    return conditions
  }
  return `${conditions}-${expConditions}`
}

export const combineConditionsWithExpression = (conditionsArray, expression) => {

  const conditions = buildConditions(conditionsArray)
  const expressionConditions = expression ? buildConditions(expression.conditions, expression.operator) : undefined

  return getJoinedConditions(conditions, expressionConditions)
}

export const getTableQueryParams = queryParams => {
  const { expression, ...restQueryParams } = queryParams
  const jointedConditions = combineConditionsWithExpression(restQueryParams.conditions, expression)

  return formatQueryParams( jointedConditions ? {
    ...restQueryParams,
    conditions: jointedConditions
  } : omit(restQueryParams, [ 'conditions' ]))
}
