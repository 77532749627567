import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { ManageLabels } from './ManageLabels'

const sheetMaps = {
  'manage-labels-sheet': ManageLabels
}

let Sheets = props => {
  const { sheet } = props
  let SheetComponent = sheetMaps[sheet.activeSheet]

  return (
    <div className='wufoo-sheets'>
      {!!SheetComponent &&
        <SheetComponent {...sheet.props} />
      }
    </div>
  )
}

Sheets.propTypes = {
  sheet: PropTypes.object
}

const select = state => ({
  sheet: state.sheet
})

Sheets = connect(select)(Sheets)
Sheets = withTranslation('sheets')(Sheets)

export default Sheets
