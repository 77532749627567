import './FormUseCard.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Card } from '@sm/wds-react'


export class FormUseCard extends React.Component {

  handleClick = () => {
    const { selected, card, handleSelect } = this.props
    handleSelect(!selected, card.name)
  }

  render() {
    const { card, selected, t } = this.props

    return (
      <Card
        className='tell-us-about-card'
        interactive
        onClick={this.handleClick}
        selected={selected}
      >
        <div className='card-icon'>{card.icon}</div>
        <div className='card-title'>{t(card.title)}</div>
      </Card>
    )
  }
}

FormUseCard.propTypes = {
  card: PropTypes.object,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  t: PropTypes.func
}

FormUseCard = withTranslation('modals')(FormUseCard)

export default FormUseCard
