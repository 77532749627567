
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import NewLookModal from './NewLook/NewLookModal'
import { shouldShowWelcomeDialog } from '../../../models/modal'
import { getQueryParams } from '../../../utils/browser'
import { urls } from '../../../utils/urls'


export class WelcomeModal extends React.Component {

  componentDidMount() {
    const location = getQueryParams()
    if (location.welcome === 'convert') {
      window.history.pushState({}, '', urls.FORM_MANAGER)
    }
  }

  render() {
    const { dispatch, t } = this.props
    const ModalComponent = shouldShowWelcomeDialog(getQueryParams()) ? NewLookModal : null

    return (ModalComponent &&
      <ModalComponent
        dispatch={dispatch}
        t={t}
      />
    )
  }
}

WelcomeModal.propTypes = {
  dispatch: PropTypes.func,
  t: PropTypes.func
}

const select = () => ({})

WelcomeModal = withTranslation('modals')(WelcomeModal)
WelcomeModal = connect(select)(WelcomeModal)

export default WelcomeModal
