const defaultState = {
  isLoading: true,
  plan: null,
  planFeatures: {
    account: {
      forms: 0,
      entries: 0,
      storage: 0,
      users: 0
    }
  },
  planWarningDismissed: false,
  stats: {
    entries: 0,
    forms: 0,
    reports: 0,
    respondents: 0,
    uploads: 0,
    users: 0
  },
  storageWarningDismissed: false,
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_PLAN_LOADING':
      return { ...state, isLoading: data }
    case 'DISMISS_PLAN_WARNING':
      return { ...state, planWarningDismissed: true }
    case 'DISMISS_STORAGE_WARNING':
      return { ...state, storageWarningDismissed: true }
    case 'SET_CURRENT_PLAN': {
      const { plan, planFeatures, stats } = data || {}
      return {
        ...state,
        plan,
        planFeatures,
        stats,
      }
    }
    default:
      return state
  }
}

