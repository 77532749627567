import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'

export class SafeHTML extends React.Component {
  render() {
    const { children } = this.props
    const sanitized = DOMPurify.sanitize(children, {
      ADD_ATTR: [ 'target' ]
    })

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: sanitized
        }}
      />
    )
  }
}

SafeHTML.propTypes = {
  children: PropTypes.string,
}

export default SafeHTML
