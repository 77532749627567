import 'react-day-picker/lib/style.css'
import './FilterExpressionItem.sass'

import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import { withTranslation } from 'react-i18next'
import { formatDate, parseDate } from 'react-day-picker/moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import {
  DEFAULT_FIELD_LIST,
  COMPLETE_SUBMISSION_LIST,
  ASSERTION_LIST,
  PAYMENT_FIELD_LIST,
  getConnectorList,
  getFieldType,
  getTruncatedTitle,
  getOriginalDateWithoutUTCAdjustment,
  getUpdatedValueIfDateCondition,
  shouldResetValueWithFieldChange,
  shouldResetValueWithConnectorChange
} from '../../../models/entries'
import { getFieldSetByField } from '../../../models/fieldset'
import { isValidTime } from '../../../models/validations'
import { keyPressed } from '../../../utils/a11y'

import { Button, Input, Select, Option } from '@sm/wds-react'
import { IconMinusCircle } from '@sm/wds-icons'


export class FilterExpressionItem extends React.PureComponent {

  constructor(props) {
    super(props)
    const { condition, userTimezone, fieldSets } = this.props
    const { field, value } = condition

    const fieldSet = getFieldSetByField(fieldSets, field)
    const fieldType = getFieldType(fieldSet, field)

    this.state = {
      isValid: true,
      tempValue: '',
      autoSelected: false,
      displayValue: getOriginalDateWithoutUTCAdjustment(fieldType, { value, userTimezone })
    }
  }

  handleConditionChange = (eleObject) => {
    const { condition, handleChange } = this.props
    handleChange(omit({ ...condition, ...eleObject }, [ 'error' ]))
  }

  handleConditionRemove = () => {
    const { condition, handleRemove } = this.props
    handleRemove(condition)
  }

  getValueOptions = (fieldType) => {
    return fieldType === 'boolean' ? COMPLETE_SUBMISSION_LIST : ASSERTION_LIST
  }

  componentDidUpdate(prevProps) {
    const { condition, userTimezone, fieldSets } = this.props
    const { field, value } = condition
    const { autoSelected } = this.state
    const { condition: prevCondition } = prevProps
    const { value: prevValue } = prevCondition
    if (this.booleanRef && !autoSelected) {
      const { condition } = this.props
      if (!condition.value) {
        this.booleanRef.props.onChange({ target: { value: 'true' }})
      }
      this.setState({ autoSelected: true })
    }

    if (prevValue !== value) {
      const fieldSet = getFieldSetByField(fieldSets, field)
      const fieldType = getFieldType(fieldSet, field)

      this.setState({
        displayValue: getOriginalDateWithoutUTCAdjustment(fieldType, { value, userTimezone })
      })
    }
  }

  render() {
    const { isValid, tempValue, displayValue } = this.state
    const { t, hasPayment, canRemove, condition, fieldSets, fieldList, handleApply, userTimezone } = this.props
    const { field, connector, value, error } = condition

    const fieldSet = getFieldSetByField(fieldSets, field)
    const fieldType = getFieldType(fieldSet, field)

    const VALUE_ASSERTION_LIST = this.getValueOptions(fieldType)
    const NONE_USER_FIELDS = hasPayment ? DEFAULT_FIELD_LIST.concat(PAYMENT_FIELD_LIST) : DEFAULT_FIELD_LIST

    return (
      <div className='filter-expression-item-container'>
        <div className='filter-expression-item'>
          <Select
            native
            className='field'
            color={error && 'warning'}
            key='field'
            value={(error && '') || (field && field.id) || fieldList[0].id}
            onChange={e => {
              let newValue = value
              let newField = fieldList.find(field => field.id.toString() === e.target.value)
              const isUserField = !!newField
              newField =  { id: (newField && newField.id) || e.target.value }
              const newFieldSet = getFieldSetByField(fieldSets, newField)
              const newFieldType = getFieldType(newFieldSet, newField)
              const newConnector = getConnectorList(newFieldType)[0]
              if (shouldResetValueWithFieldChange(fieldType, newFieldType)
                || shouldResetValueWithConnectorChange(connector,newConnector)) {
                newValue = ''
                this.setState({ isValid: true, tempValue: '' })
              }
              newValue = getUpdatedValueIfDateCondition(newFieldType, { connector, value: newValue, userTimezone })
              this.handleConditionChange({
                field: newField,
                connector: newConnector,
                value: newValue,
                isUserField
              })
              this.setState({ autoSelected: false })
            }}
          >
            <optgroup label={t('Fields')}>
              {error && (
                <Option
                  value=''
                  key='empty-option'
                >
                  {''}
                </Option>
              )}
              {fieldList.map(entryField => (
                <Option
                  value={entryField && entryField.id}
                  key={entryField && entryField.id}
                >
                  {getTruncatedTitle(entryField && entryField.title)}
                </Option>
              ))}
            </optgroup>
            <optgroup label={t('Entry_Info')}>
              {NONE_USER_FIELDS.map(entryKey => (
                <Option
                  value={entryKey}
                  key={entryKey}
                >
                  {t(entryKey.replace(/\./g, '_'))}
                </Option>
              ))}
            </optgroup>

          </Select>

          <Select
            native
            className='connector'
            key='connector'
            value={connector}
            onChange={e => {
              let newValue
              if (shouldResetValueWithConnectorChange(connector, e.target.value)) {
                newValue = ''
                this.setState({ isValid: true, tempValue: '' })
              } else {
                newValue = getUpdatedValueIfDateCondition(fieldType, { connector: e.target.value, value, userTimezone })
              }
              this.handleConditionChange({ field, connector: e.target.value, value: newValue })
              this.setState({ autoSelected: false })
            }}
          >
            {getConnectorList(fieldType).map(connector => (
              <Option
                value={connector}
                key={connector}
              >
                {t(`${fieldType}_${connector}`)}
              </Option>
            ))}
          </Select>
          {connector === 'is' && (
            <Select
              native
              ref={ref => this.booleanRef = ref}
              className='value'
              key='value'
              value={value || 'true'}
              onChange={e => {
                if (e.target.value === 'true') {
                  return this.handleConditionChange({ field, value: 'true' })
                }
                this.handleConditionChange({ field, value: 'false' })
              }}
            >
              {VALUE_ASSERTION_LIST.map(status => (
                <Option
                  value={status === VALUE_ASSERTION_LIST[0] ? 'true' : 'false'}
                  key={status}
                >
                  {t(`${status}`)}
                </Option>
              ))}
            </Select>
          )}
          {connector !== 'is' && (fieldType === 'date' || fieldType === 'datetime') && (
            <DayPickerInput
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder={`${formatDate(new Date())}`}
              value={`${displayValue ? formatDate(displayValue) : ''}`}
              onDayChange={date => {
                const newValue = getUpdatedValueIfDateCondition(fieldType, { connector, value: date, userTimezone })
                this.handleConditionChange({ field, value: newValue })
              }}
            />
          )}
          {connector !== 'is' && fieldType === 'time' && (
            <Input
              className={`value ${isValid ? '': 'error'}`}
              key='value'
              value={tempValue || value}
              placeholder={fieldType ? 'HH:MM:SS' : ''}
              onKeyPress={keyPressed('Enter', handleApply)}
              onChange={e => {
                const isValid = isValidTime(e.target.value)
                const value = isValid ? e.target.value : ''
                this.setState({ isValid, tempValue: e.target.value })
                this.handleConditionChange({ field, value })
              }}
            />
          )}
          {connector !== 'is' && fieldType !== 'date' && fieldType !== 'datetime' && fieldType !== 'time' && (
            <Input
              className='value'
              key='value'
              value={value}
              onKeyPress={keyPressed('Enter', handleApply)}
              onChange={e => this.handleConditionChange({ field, value: e.target.value })}
            />
          )}

          <Button
            className='remove-condition-btn'
            variant='text'
            disabled={!canRemove}
            onClick={this.handleConditionRemove}
          >
            <IconMinusCircle />
          </Button>
        </div>
        <div className='error-message'>
          { error && t(`filter_expression_item_error_${error}`)}
        </div>
      </div>
    )
  }
}

FilterExpressionItem.defaultProps = {
  canRemove: true,
  hasPayment: false
}

FilterExpressionItem.propTypes = {
  t: PropTypes.func.isRequired,
  hasPayment: PropTypes.bool,
  handleApply: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  fieldSets: PropTypes.array.isRequired,
  fieldList: PropTypes.array.isRequired,
  condition: PropTypes.object.isRequired,
  userTimezone: PropTypes.number
}

export default withTranslation('entries')(FilterExpressionItem)
