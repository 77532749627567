import {
  LAUNCH_LEGACY_LOCATION_CREATE,
  LAUNCH_LEGACY_LOCATION_EDIT,
  LAUNCH_LEGACY_LOCATION_FORMNAME
} from '../constants/mixpanel'


const bulkActionsProps = (action, formNum, selectedFormNum) => {
  const props = {
    'Form Type': 'Legacy Forms',
    '# of Forms Selected': selectedFormNum,
    'All Forms Selected?': selectedFormNum === formNum,
    'Action Performed': action // Delete or assign labels
  }
  return props
}

export const getActionPerformedEventDetails = (action, { formNum, selectedFormNum }) => {
  return {
    event: 'Form Manager Action Performed',
    properties: bulkActionsProps(action, formNum, selectedFormNum)
  }
}

export const getBulkActionDrawerEventDetails = (action, { formNum, selectedFormNum }) => {
  return {
    event: 'Form Manager Bulk Action Clicked in Drawer',
    properties: bulkActionsProps(action, formNum, selectedFormNum)
  }
}

export const getDuplicateFormEventDetails = (formId, { formNum }) => {
  return {
    event: 'Form Manager Duplicate Form',
    properties: {
      'Form Type View': 'Legacy Forms',
      'Source Form ID': formId,
      'Total # of Forms': formNum
    }
  }
}

export const getEntriesDeletedEventDetails = (formId, { isSelectedAll, numOfEntries, numOfEntriesDeleted }) => {
  return {
    event: 'Entries Deleted',
    properties: {
      'Form ID': formId,
      'Entry Manager Version': 'Classic Forms',
      '# of Entries': numOfEntries,
      '# of Entries Deleted': numOfEntriesDeleted,
      'All Entries Selected': isSelectedAll,
    }
  }
}

export const getEntryDeletedEventDetails = (formId, { entryId, numOfEntries }) => {
  return {
    event: 'Entry Deleted',
    properties: {
      'Form ID': formId,
      'Entry ID': entryId,
      'Entry Manager Version': 'Classic Forms',
      '# of Entries': numOfEntries
    }
  }
}

export const getEntryDetailsOpenedEventDetails = (formId, { dateCreated, entryId, numOfEntries }) => {
  return {
    event: 'Entry Details Opened',
    properties: {
      'Form ID': formId,
      'Entry Created Date': dateCreated,
      'Entry ID': entryId,
      'Entry Manager Version': 'Classic Forms',
      '# of Entries': numOfEntries
    }
  }
}

export const getEntryDetailsNextPrevClicked = (formId, { direction, numOfEntries }) => {
  return {
    event: 'Entry Details Next/Prev Clicked',
    properties: {
      'Form ID': formId,
      'Entry Manager Version': 'Classic Forms',
      'Direction': direction,
      '# of Entries': numOfEntries
    }
  }
}

export const getEntryManagerColumnsModified = (formId, { numOfSelectedColumns }) => {
  return {
    event: 'Entry Manager Columns Modified',
    properties: {
      'Form ID': formId,
      'Entry Manager Version': 'Classic Forms',
      '# of columns selected': numOfSelectedColumns
    }
  }
}

export const getEntryManagerFilterApplied = (formId, { isFilteredByFormFields, metadataFields,
  numOfEntries, numOfEntriesReturned, numOfFilterArguments, preBuiltFilter }) => {
  return {
    event: 'Entry Manager Filter Applied',
    properties: {
      'Form ID': formId,
      'Entry Manager Version': 'Classic Forms',
      'Filtered by Metadata': metadataFields,
      'Is Filtered by Form Fields': isFilteredByFormFields,
      'Pre-built Filter Used': preBuiltFilter, // all, today, expression
      '# of Arguments': numOfFilterArguments,
      '# of Entries': numOfEntries,
      '# of Entries Returned': numOfEntriesReturned
    }
  }
}

export const getEntryManagerFilterCreated = (formId, {
  isFilteredByFormFields, metadataFields, numOfEntries, numOfFilterArguments }) => {
  return {
    event: 'Entry Manager Filter Created',
    properties: {
      'Form ID': formId,
      'Entry Manager Version': 'Classic Forms',
      '# of Entries': numOfEntries,
      'Filtered by Metadata': metadataFields,
      'Is Filtered by Form Fields': isFilteredByFormFields,
      '# of Arguments': numOfFilterArguments
    }
  }
}

export const getEntryManagerOpenedEventDetails = (formId, {
  entriesPerPage, filterApplied, numOfEntries, numOfSelectedColumns, sortDirection, sortBy }) => {
  return {
    event: 'Entry Manager Opened',
    properties: {
      'Form ID': formId,
      'Entry Manager Version': 'Classic Forms',
      'Entries per page': entriesPerPage,
      'Filter Applied': filterApplied, // all, today, expression
      'Sort by': sortBy,
      'Sort order': sortDirection,
      '# of Entries': numOfEntries,
      '# of columns selected': numOfSelectedColumns
    }
  }
}

export const getEntryPrintedEventDetails = (formId, { entryId, numOfEntries, numOfQuestions }) => {
  return {
    event: 'Entry Printed',
    properties: {
      'Form ID': formId,
      'Entry ID': entryId,
      'Entry Manager Version': 'Classic Forms',
      '# of Entries': numOfEntries,
      '# of Questions': numOfQuestions,
    }
  }
}

export const getFormManagerLaunchedEventDetails = (forms, onLogin) => {
  const { queryParams, totalCount } = forms
  const { sortKey, sortDirection, filterKey, pageSize, query } = queryParams || {}

  const props = {
    'Sort By': sortKey,
    'Sort Order': sortDirection,
    'Filter Pre-applied?': filterKey !== 'all',
    'Form Type View': 'Legacy Forms',
    'Search Pre-applied?': query ? true : false,
    'Forms Per Page': pageSize,
    'Total # of Forms': totalCount,
    'On Login?': onLogin
  }
  return { event: 'Form Manager Launched', properties: props }
}

export const getFormRenameEventDetails = (formId) => {
  return {
    event: 'Form Manager Form Rename',
    properties: {
      'Form ID': formId,
      'Form Type': 'Legacy Forms'
    }
  }
}

export const getLaunchFBEventDetails = (location, { form, createFormType } = {}) => {
  let purpose

  switch (location) {
    case LAUNCH_LEGACY_LOCATION_CREATE:
      purpose = 'Create New'
      break
    case LAUNCH_LEGACY_LOCATION_EDIT:
    case LAUNCH_LEGACY_LOCATION_FORMNAME:
      purpose = 'Edit'
      break
    default:
      purpose = ''
      break
  }

  const props = {
    'Action Invoked Location': location,
    'Purpose': purpose,
    'Form ID': form?.id || '',
    'Form Type': 'Legacy Form Builder',
    'Password Required?': form?.password ? true : false,
    'Create Form': createFormType || ''
  }

  return { event: 'Form Builder Launched', properties: props }
}

export const getQueryParamsEventDetails = (queryDiff, queryObj, data) => {

  const { query, labelIds, filterKey, sortKey, sortDirection, page, pageSize } = queryObj
  const { countLimit, totalCount } = data

  // for the case where on page 2+ and changing search, filters, or sort, we shouldn't count the page change to 1 a page turned event
  const queryDiffKeys = Object.keys(queryDiff)
  if (queryDiffKeys.length > 1 && queryDiffKeys.includes('page')) delete queryDiff.page

  let event
  let props
  let mixpanelData = []
  const keys = Object.keys(queryDiff)
  keys.forEach(key => {
    const hasFilter = labelIds.length > 0 || filterKey !== 'all'
    switch (key) {
      case 'query':
        event = 'Form Manager Search'
        props = {
          'Search Type': query ? 'Terms' : 'Reset',
          'Form Type': 'Legacy Forms',
          'Forms Per Page': pageSize,
          '# of Forms Returned': totalCount,
          'Has Filter?': hasFilter
        }
        break
      case 'labelIds':
        event = 'Form Manager Filter by Labels'
        props = {
          '# of Labels Applied': labelIds.length,
          '# of Forms Returned': totalCount,
          'Form Type': 'Legacy Forms',
          'Search Applied?': query ? true : false,
          'Visibility': filterKey,
        }
        break
      case 'sortKey':
      case 'sortDirection':
        event = 'Form Manager Sort'
        props = {
          'Form Type': 'Legacy Forms',
          'Forms Per Page': pageSize,
          'Total # of Forms': totalCount,
          'Old Sort By': key === 'sortKey' ? queryDiff.sortKey : sortKey,
          'New Sort By': sortKey,
          'Old Sort Order': key === 'sortDirection' ? queryDiff.sortDirection : sortDirection,
          'New Sort Order': sortDirection
        }
        break
      case 'page':
        event = 'Form Manager Page Turned'
        props = {
          'Page Turned Type': queryDiff.page < page ? 'Next' : 'Previous',
          'Form Type': 'Legacy Forms',
          'Forms Per Page': pageSize,
          '# of Forms Returned': totalCount,
          'Has Filter?': hasFilter,
          '# of Pages': totalCount%pageSize === 0 ? totalCount/pageSize : Math.trunc((totalCount/pageSize) + 1)
        }
        break
      default:
        break
    }
    if (event) mixpanelData.push({ 'event': event, 'properties': { ...props, 'Total # of Forms in Account': countLimit }})
  })
  return mixpanelData
}

export const getUpgradeCTAClickedEventDetails = (upgradeTextKey, location) => {
  return {
    event: 'Upgrade CTA Clicked',
    properties: {
      'CTA Name': upgradeTextKey,
      'Action Invoked Location': location
    }
  }
}

export const getViewFormLaunchedEventDetails = (formId) => {
  return {
    event: 'Form Manager View Form',
    properties: {
      'Form ID': formId,
      'Form Type': 'Legacy Forms'
    }
  }
}
