import { getSetting } from '../services/settings/settings'

const getFormSetting = getSetting('form')

const filterKey = getFormSetting('filterKey') || 'all'
const labelIds = getSetting('label')('selectedLabels')
  ?.filter(label => label?.id !== filterKey)
  ?.map(label => label?.id) || []


export const defaultState = {
  isLoading: true,
  forms: [],
  queryParams: {
    query: getFormSetting('search') || '',
    filterKey,
    labelIds,
    sortKey: getFormSetting('sortKey') || 'date_created',
    sortDirection: getFormSetting('sortDirection') || 'desc',
    pageSize: getFormSetting('pageSize') || 10,
    page: 1
  }
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_FORMS_LOADING': {
      if (data && data.length > 0) {
        return {
          ...state,
          forms: state.forms.map(form => {
            return { ...form, isLoading: data.includes(form.id) }
          })
        }
      }

      return { ...state, isLoading: data }
    }
    case 'CHANGE_FORMS_QUERY_PARAMS':
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...data
        }
      }
    case 'UPDATE_FORM': {
      const newForms = state.forms.map(f => {
        return f.id === data.id ? { ...f, ...data } : f
      })

      return { ...state, forms: newForms }
    }
    case 'SET_FORMS':
      return {
        ...state,
        forms: data.forms || [], // OLD_API: remove default array
        totalCount: data.totalCount,
        isLoading: false
      }
    default:
      return state
  }

}

