import { store, USER_SETTING } from '../storage'


export const getSetting = (category, id) => (key) => {
  const settings = store.get(USER_SETTING) || {}
  const data = (id ? settings?.[category]?.[id] : settings[category]) || {}
  return (key in data) ? data[key] : null
}

export const setSetting = (category, id) => (key, value) => {
  const settings = store.get(USER_SETTING) || {}
  let items = settings[category] || {}
  let data

  if (id) {
    data = items?.[id] || {}
    data[key] = value
    items[id] = data
  } else {
    data = items
    data[key] = value
    items = data
  }

  settings[category] = items
  store.set(USER_SETTING, settings)
}

export const removeSetting = (category, id) => {
  const settings = store.get(USER_SETTING) || {}
  const items = settings[category] || {}

  if (id) {
    delete items[id]
  }

  settings[category] = items
  store.set(USER_SETTING, settings)
}
