export const EXPORT_FILES_SIZE_LIMIT = 500000000 // 500 MB
export const EXPORT_FILES_ERROR_MAPS = {
  '0002': {
    'size_mb': 'global:export_file_size_limit_error',
    'file_count': 'global:export_file_count_limit_error'
  },
}
export const EXPORT_TYPES = {
  ENTRIES_TO_XLS: 'entries_to_xls'
}
export const POLL_INTERVAL = 500 // 0.5 seconds
export const POLL_MAX_ATTEMPTS = 241 // 2 minutes of attempted polling

