import './ToastBodies.sass'

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Trans, withTranslation } from 'react-i18next'
import { Typography } from '@sm/wds-react'


export const ReadyForDownloadBody = ({ exportUrl, t }) => {
  return (
    <div className='wds-flex wds-flex--y'>
      <Typography color='light' variant='bodySm'>
        <Trans t={t} i18nKey='export_success_body_toast'>
          <span>Your download should start automatically. If it doesn't,</span>&nbsp;
          <a
            href={exportUrl}
            download
          >
            download now
          </a>
        </Trans>
      </Typography>
    </div>
  )
}

ReadyForDownloadBody.propTypes = {
  exportUrl: PropTypes.string,
  t: PropTypes.func.isRequired
}

ReadyForDownloadBody.defaultProps = {
  exportUrl: ''
}

const mapStateToProps = (state) => ({
  exportUrl: state.exports.exportUrl
})

const ConnectedReadyForDownloadBody = connect(mapStateToProps)(ReadyForDownloadBody)

export default withTranslation('global')(ConnectedReadyForDownloadBody)
