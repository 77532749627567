import './Status404.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CloudOne from './images/cloud2.svg'
import CloudTwo from './images/cloud5.svg'
import DinoOne from './images/dino6.svg'
import DinoTwo from './images/dino10.svg'

export const Status404 = props => {
  const { t } = props

  return (
    <div className='status-404'>
      <div className='status-404__container'>
        <div className='status-404__container__img-container'>
          <img
            src={CloudTwo}
            alt=''
          />
          <img
            src={DinoOne}
            alt=''
          />
        </div>
        <div className='status-404__container__text-container'>
          <p>{t('message_404')}</p>
          <p>{t('content_404')}</p>
          <Link className='wds-button wds-button--primary wds-button--solid wds-button--md' to={'/forms'}>{t('return_to_forms')}</Link>
        </div>
        <div className='status-404__container__img-container'>
          <img
            src={CloudOne}
            alt=''
          />
          <img
            src={DinoTwo}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

Status404.propTypes = {
  t: PropTypes.func
}

export default withTranslation('global')(Status404)
