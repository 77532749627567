export const defaultState = {
  isLoading: true,
  experiments: {}
}

export default (state = defaultState, { type, data }) => {
  switch (type) {
    case 'SET_EXPERIMENT': {
      const { name, ...restData } = data
      return {
        ...state,
        experiments: {
          ...state.experiments,
          [name]: restData
        },
      }
    }
    case 'SET_EXPERIMENT_LOADING':
      return { ...state, isLoading: data }
    default:
      return state
  }
}
