import './ApiError.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button } from '@sm/wds-react'
import { IconArrowRefresh } from '@sm/wds-icons'

export const ApiError = props => {
  const { t, reload } = props

  return (
    <div className='api-error'>
      <h4>{t('api_error')}</h4>
      <Button onClick={reload}>
        <IconArrowRefresh />
        {t('retry')}
      </Button>
    </div>
  )
}

ApiError.propTypes = {
  reload: PropTypes.func.isRequired,
  t: PropTypes.func
}

export default withTranslation('global')(ApiError)
