import './AccountDropdown.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Menu, MenuItem } from '@sm/wds-react'
import { IconChevronDown } from '@sm/wds-icons'
import { LegacyRedirect } from '../../containers/LegacyRedirect'
import { LegacyLink } from '../../components/LegacyLink'
import { WufooAvatar } from '../../components/WufooAvatar'

import { getUser, getSystemNotifications } from '../../actions/user'
import { USER_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


export class AccountDropdown extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(getUser())
    dispatch(getSystemNotifications())
  }

  render() {
    const {
      t,
      user
    } = this.props

    const Dummy = () => {
      return <span />
    }

    let accountSettings = <Dummy />
    let upgrade = <Dummy />
    let feedback = <Dummy />

    if (user.optedOutOfBeta) {
      return (
        <LegacyRedirect
          showLoader={false}
          url='/admin'
        />
      )
    }
    if (!user.id) {
      return null
    }

    const trigger = (
      <div
        id='header-account-dropdown'
        className='account-dropdown-trigger'
        tabIndex='0'
        ref={trigger => this.trigger = trigger}
      >
        <WufooAvatar user={user} />
        <span className='hidden-xs hidden-sm hidden-md'>
          {user.name}
        </span>
        <IconChevronDown size='sm' />
      </div>
    )

    if (user.accountOwner || user.adminAccess) {
      accountSettings = (
        <LegacyLink id='header-account-settings' to={'/account'}>
          {t('Account_Settings')}
        </LegacyLink>
      )
    }

    if (user.accountOwner) {
      upgrade = (
        <LegacyLink id='header-account-upgrade' to={'/account/pricing'}>
          {t('Upgrade')}
        </LegacyLink>
      )
    }

    return (
      <div className='account-dropdown'>
        <Menu
          autoClose
          placement='bottom-right'
          menuTrigger={trigger}
          onChange={({ show }) => {
            if (!show && this.trigger) {
              this.trigger.blur()
            }
          }}
        >
          {accountSettings}
          {upgrade}

          <LegacyLink id='header-account-help' to={'/docs'}>
            {t('Help')}
          </LegacyLink>

          {feedback}

          <MenuItem id='header-account-logout'>
            <Link to={'/logout'}>
              {t('Logout')}
            </Link>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

AccountDropdown.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
}

const select = state => ({
  app: state.app,
  user: state.user,
})

AccountDropdown = connect(select)(AccountDropdown)
AccountDropdown = withTranslation('global')(AccountDropdown)

export default AccountDropdown
