import './AppErrorBoundary.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@sm/wds-react'
import { CSSTransition } from 'react-transition-group'
import SplunkOtelWeb from '@splunk/otel-web'
import { withTranslation } from 'react-i18next'

import dinoImage from './images/dino11.svg'
import cloud05 from './images/cloud-05.svg'
import cloud08 from './images/cloud-08.svg'
import cloud09 from './images/cloud-09.svg'
import cloud10 from './images/cloud-10.svg'

class AppErrorBoundary extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error) {
    const { handleError } = this.props
    this.setState({ hasError: true })
    SplunkOtelWeb.error(error)
    if (handleError)
      handleError()
  }

  withTransition = (element) => {
    const { forceError } = this.props
    const { hasError } = this.state
    return (
      <CSSTransition
        in={hasError || forceError}
        appear={hasError || forceError}
        classNames={'cloud'}
        timeout={0}
      >
        {element}
      </CSSTransition>
    )
  }

  render() {
    const { t, children, forceError, forceClear } = this.props
    const { hasError } = this.state

    if ((hasError && !forceClear) || forceError) {
      return (
        <div className='error-container'>
          <div className='error-body'>
            <section>
              <div className='error-body-wrapper'>
                <h1>{t('generic_error_page_title')}</h1>
                <p>{t('generic_error_page_description')}</p>
                <p>{t('generic_error_page_remediation')}</p>
                <Button
                  className='reload-btn'
                  color='warning'
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  {t('generic_error_reload_button')}
                </Button>
              </div>
              <img className='dino dino__1' src={dinoImage} alt='' />
              {this.withTransition(<img className='cloud cloud__1' src={cloud05} alt='' />)}
              {this.withTransition(<img className='cloud cloud__2' src={cloud08} alt='' />)}
              {this.withTransition(<img className='cloud cloud__3' src={cloud09} alt='' />)}
              {this.withTransition(<img className='cloud cloud__4' src={cloud10} alt='' />)}
            </section>
          </div>
        </div>
      )
    }

    return children
  }
}

AppErrorBoundary.defaultProps = {
  forceError: false,
  forceClear: false,
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  forceError: PropTypes.bool,
  forceClear: PropTypes.bool,
  handleError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

AppErrorBoundary = withTranslation('global') (AppErrorBoundary)

export default AppErrorBoundary
