
const capitalize = (string, index) => {
  let word
  if (index === 0) {
    word = string
  } else {
    const array = string.split('')
    const firstLetter = array[0]?.toUpperCase()
    array.splice(0, 1, firstLetter)
    word = array.join('')
  }
  return word
}

export const capitalizeWithSpace = string => {
  return string.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function(key) { return key.toUpperCase()})
}

export const isString = value => {
  return typeof value === 'string'
}

export const parameterize = text => {
  return text.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/(^-|-$)/g,'')
}

export const like = (search, text) => {
  return text.toLowerCase().indexOf(search.toLowerCase()) > -1
}

export const removeWhitespace = string => {
  return string?.trim().replace(/\s+/g, '') || ''
}

export const sentenceCaseWithSpace = string => {
  return string.replace(/_/g, ' ').replace(/[a-z]/i, function (letter) {
    return letter.toUpperCase()
  }).trim()
}

export const toCamelCase = string => {
  return string?.toLowerCase().split('_').map((string, index) => capitalize(string, index)).join('')
}

export const toID = text => {
  return text.replace(/\s+/g, '-').toLowerCase()
}
