import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import store from '../../store/reduxStore'


const Backend = {
  type: 'backend',
  read: async (language, namespace, callback) => {
    try {
      callback(null, await import(`../../locales/${language}/${namespace}.json`))
    } catch (e) {
      callback('failed', true) // with retries
    }
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: { escapeValue: false },
    load: 'languageOnly'
  })

i18n.on('languageChanged', lng => {
  store.dispatch({ type: 'SET_LANGUAGE', data: lng })
  document.documentElement.setAttribute('lang', lng)
})

export default i18n
