import './Drawer.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Grid } from '@sm/wds-react'

export const Drawer = props => {
  const { children, className, isOpen } = props

  const classNames = classnames(
    'drawer',
    className,
    { 'drawer-open': isOpen }
  )

  return (
    <div className={classNames}>
      <Grid>
        <div className='drawer-container'>
          {children}
        </div>
      </Grid>
    </div>
  )
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
}

Drawer.defaultProps = {
  className: ''
}

export default Drawer
