const initialState = {
  inProgress: false,
  exportUrl: ''
}

export default (state=initialState, { type, data }) => {
  switch (type){
    case 'SET_EXPORTS_IN_PROGRESS':
      return {
        ...state,
        inProgress: data
      }
    case 'SET_EXPORT_REQUEST_URL':
      return {
        ...state,
        exportUrl: data
      }
    default:
      return state
  }
}
