import { api } from '../services/api'
import { parameterize } from '../utils/string'

import { fetchForms } from './forms'
import { getPlan } from './plan'
import { apiError } from './api'
import { CSV } from '../utils/mimetypes'
import { withToastDisplay } from '../utils/toast'

export const fetchForm = formId => {
  return dispatch => {
    dispatch({ type: 'SET_FORM_LOADING', data: true })

    api.get(`/forms/${formId}`)
      .then(({ data }) => {
        dispatch({ type: 'SET_CURRENT_FORM', data })
      })
      .catch(() => {})
      .then(() => {
        dispatch({ type: 'SET_FORM_LOADING', data: false })
      })
  }
}

export const updateFormVisibility = (formId, isPublic, loadingMessage, queryParams=null) => {
  return dispatch => {
    dispatch({ type: 'SHOW_TOAST', data: {
      type: 'loading',
      title: loadingMessage,
      dismissible: false,
      autoClose: false
    }})

    api.put(`/forms/${formId}/visibility`, { isPublic })
      .then(() => {
        if (queryParams && queryParams.filterKey !== 'all') {
          dispatch(fetchForms(queryParams))
        }
        else {
          dispatch({ type: 'UPDATE_FORM', data: { id: formId, isPublic }})
        }
      })
      .catch(() => {})
      .then(() => {
        dispatch({ type: 'HIDE_TOAST' })
      })
  }
}

export const setPassword = (newPassword, formId, loadingMessage) => {
  return dispatch => {
    dispatch({ type: 'SHOW_TOAST', data: {
      type: 'loading',
      title: loadingMessage,
      dismissible: false,
      autoClose: false
    }})

    api.put(`/forms/${formId}/password`, { password: newPassword })
      .then(() => {
        dispatch({ type: 'UPDATE_FORM', data: { id: formId, password: newPassword }})
      })
      .catch(() => {})
      .then(() => {
        dispatch({ type: 'HIDE_TOAST' })
      })
  }
}

export const setFormName = (formId, newFormName) => {
  return dispatch => {

    dispatch({ type: 'UPDATE_FORM', data: { id: formId, isLoading: true }})
    api.put(`/forms/${formId}/name`, { name: newFormName })
      .then(() => {
        api.get(`/forms/${formId}`)
          .then(({ data }) => {
            dispatch({ type: 'UPDATE_FORM', data: { ...data, isLoading: false }})
          })
      })
      .catch(() => {
        dispatch(apiError('form-list', true))
        dispatch({ type: 'UPDATE_FORM', data: { id: formId, isLoading: false }})
      })
  }
}

export const getFormShare = formId => {
  let formData

  return dispatch => {
    dispatch({ type: 'SET_FORM_LOADING', data: true })
    dispatch(apiError('form-share', false))

    api.get(`/forms/${formId}/share`)
      .then(({ data }) => {
        formData = data
        return api.get(`/forms/${formId}`)
      })
      .then(({ data }) => {
        dispatch({ type: 'SET_CURRENT_FORM', data: { ...data, ...formData }})
        dispatch({ type: 'SET_FORM_LOADING', data: false })
      })
      .catch(() => {
        dispatch({ type: 'SET_FORM_LOADING', data: false })
        dispatch(apiError('form-share', true))
      })
  }
}

export const downloadForm = form => {
  const { id, name='form' } = form

  return () => {
    api.download(
      `/forms/${id}/share/download`,
      `${parameterize(name)}_source.zip`
    )
  }
}

export const downloadAllEntries = (form, toastMessages={}) => {
  const { id, name = 'form' } = form

  return async dispatch => {
    dispatch({ type: 'SET_DOWNLOAD_ALL_DISABILITY', data: true })
    await withToastDisplay(dispatch, toastMessages, async () => {
      await new Promise((resolve, reject) => {
        api.download(
          `/forms/${id}/entries/download`,
          `${parameterize(name)}_entries.csv`, {
            headers: { Accept: CSV },
            callback: actionFunc => {
              actionFunc()
              resolve()
            }
          })
          .catch(reject)
      })
    })

    dispatch({ type: 'SET_DOWNLOAD_ALL_DISABILITY', data: false })
  }
}

export const duplicateForm = (formId, toastMessages={}) => {
  return async (dispatch, getState) => {
    const { forms } = getState()

    withToastDisplay(dispatch, toastMessages, async () => {
      try {
        await api.post(`/forms/${formId}`)
        dispatch(fetchForms(forms.queryParams))
        dispatch(getPlan())
      } catch (e) {
        dispatch(apiError('form-duplicate', true))
        throw e
      }
    })
  }
}

