import { getSetting } from '../services/settings/settings'
const getFileSetting = getSetting('file')

const defaultFolders = {
  root: { files: []},
  loadingMap: {},
  selectedFolder: {},
  refreshFolder: {}
}

const defaultState = {
  isFavouriteLoading: false,
  isLoading: false,
  isRefreshPending: false,
  depthStack: [],
  favouriteFiles: [],
  files: [],
  folders: defaultFolders,
  // This is used for refreshing destination folder in move/copy operations.
  // When navigating to a folder found in this list, a refresh is forced regardless of cache.
  // Values can be '' representing root folder, or folder id string.
  pendingRefreshFolders: [],
  queryParams: {
    query: getFileSetting('search') || '',
    filterKey: getFileSetting('filterKey') || 'all',
    page: 1,
    pageSize: getFileSetting('pageSize') || 25,
    sortDirection: getFileSetting('sortDirection') || 'asc',
    sortKey: getFileSetting('sortKey') || 'filename'
  },
  selectedFileQueryParams: {
    query: '',
    page: 1,
    pageSize: 25,
    sortDirection: 'asc',
    sortKey: 'filename'
  },
  selectedFile: null,
  isSelectedFileFavourite: false,
  fileRequest: null,
  totalCount: 0
}

export default (state=defaultState, { type, data }) => {
  switch (type) {
    case 'CHANGE_FILES_QUERY_PARAMS':
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...data
        }
      }
    case 'SET_FILES_LOADING':
      return {
        ...state,
        isLoading: data
      }
    case 'SET_FILES':
      return {
        ...state,
        files: data.files,
        totalCount: data.totalCount,
        isLoading: false
      }
    case 'SELECT_FILE': {
      let newDepth

      if (!data) {
        return {
          ...state,
          selectedFile: null,
          depthStack: []
        }
      }

      if (state.files.find(f => f.id === data.id)) {
        newDepth = []
      }
      else {
        newDepth = state.selectedFile ? [ ...state.depthStack, state.selectedFile ] : [ ...state.depthStack ]
      }

      const isNested = state.selectedFile || newDepth.length > 0
      return {
        ...state,
        depthStack: newDepth,
        selectedFile: data,
        isSelectedFileFavourite: isNested ? state.isSelectedFileFavourite : data.isFavourite
      }
    }
    case 'UPDATE_FILE': {
      const newFiles = [ ...state.files ].map(file => (file.id === data.id ? data : file))

      let newSelectedFile = state.selectedFile ? (state.selectedFile.id === data.id ?
        { ...state.selectedFile, ...data } : { ...state.selectedFile }) : null

      if (newSelectedFile && newSelectedFile.files) {
        newSelectedFile.files = newSelectedFile.files.map(file => (file.id === data.id ? data : file))
      }

      return {
        ...state,
        files: newFiles,
        selectedFile: newSelectedFile
      }
    }
    case 'UPDATE_FOLDER_CONTENT': {
      let newState = { ...state }

      if (state.depthStack.length === 0) { // first level folder
        const newFolderIndex = state.files.findIndex(file => file.id === state.selectedFile?.id)
        if (newFolderIndex !== -1) {
          const newFolder = {
            ...state.files[newFolderIndex],
            ...data,
            ...(data.totalCount === undefined ? {} : { childCount: data.totalCount }) //update child count to avoid data inconsistency
          }
          newState.files[newFolderIndex] = newFolder
        }
      } else {
        let newFolder = newState
        for (let i = 0; i < state.depthStack.length; i++) {
          const currentStack = state.depthStack[i]
          const newFolderIndex = newFolder.files.findIndex(file => file.id === currentStack.id)
          newFolder = newFolder.files[newFolderIndex]
          // note: need to sync depth stack with latest files, since depth stack was updated in "SELECT_FILE" action,
          // where folder content wasn't available
          newState.depthStack[i] = newFolder
        }
        const currentFolderIndex = newFolder.files.findIndex(file => file.id === state.selectedFile?.id)
        if (currentFolderIndex !== -1) {
          newFolder.files[currentFolderIndex] = {
            ...newFolder.files[currentFolderIndex],
            ...data,
            ...(data.totalCount === undefined ? {} : { childCount: data.totalCount }) //update child count to avoid data inconsistency
          }
        }
      }

      return newState
    }
    case 'NAVIGATE_FILE_TO': {
      const fileIndex = state.depthStack.findIndex(f => {
        return f && data ? f.id === data.id : false
      })

      const newDepth = fileIndex !== -1 ? state.depthStack.slice(0, fileIndex) : []
      return {
        ...state,
        depthStack: [ ...newDepth ],
        selectedFile: data
      }
    }
    case 'SET_FOLDERS_LOADING': {
      const newLoadingMap = { ...state.folders.loadingMap }

      if (data.isLoading) {
        newLoadingMap[data.id] = true
      } else {
        delete newLoadingMap[data.id]
      }

      return {
        ...state,
        folders: {
          ...state.folders,
          loadingMap: newLoadingMap
        }
      }
    }
    case 'SET_FOLDERS': {
      const { id, parentId } = data

      const level = id !== 'root' ? state.folders[parentId].level + 1 : 1
      const status = data.status || 'open'

      return {
        ...state,
        folders: {
          ...state.folders,
          [id]: {
            files: data.files,
            level,
            status,
            parentId
          }
        }
      }
    }
    case 'SET_REFRESH_FOLDER':
      return {
        ...state,
        folders: {
          ...state.folders,
          refreshFolder: {
            ...data
          }
        }
      }
    case 'RESET_FOLDERS':
      return {
        ...state,
        folders: defaultFolders
      }
    case 'SELECT_FOLDER':
      return {
        ...state,
        folders: {
          ...state.folders,
          selectedFolder: data,
        }
      }
    case 'SET_SELECTED_FILE_LOADING':
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          isLoading: data
        }
      }
    case 'CHANGE_SELECTED_FILE_QUERY_PARAMS':
      return {
        ...state,
        selectedFileQueryParams: {
          ...state.selectedFileQueryParams,
          ...data
        }
      }
    case 'SET_SELECTED_FILE_CONTENT':
      return {
        ...state,
        selectedFile: {
          ...state.selectedFile,
          ...data,
          ...(data.totalCount === undefined ? {} : { childCount: data.totalCount }), //update child count to avoid data inconsistency
          isLoading: false
        }
      }
    case 'SET_FAVOURITE_FILES_LOADING':
      return {
        ...state,
        isFavouriteLoading: data
      }
    case 'SET_FAVOURITE_FILES':
      return {
        ...state,
        favouriteFiles: data.files,
        isFavouriteLoading: false
      }
    case 'SET_FILE_REQUEST':
      return {
        ...state,
        fileRequest: {
          ...state.fileRequest,
          ...data
        }
      }
    case 'RESET_FILE_REQUEST':
      return {
        ...state,
        fileRequest: null
      }
    case 'SET_REFRESH':
      return {
        ...state,
        isRefreshPending: data
      }
    case 'SET_PENDING_REFRESH_FOLDERS': {
      if (data?.id !== undefined && !state.pendingRefreshFolders.includes(data?.id)) {
        return {
          ...state,
          pendingRefreshFolders: [
            ...state.pendingRefreshFolders,
            data.id
          ]
        }
      }
      return state
    }
    case 'REMOVE_PENDING_REFRESH_FOLDERS':
      return {
        ...state,
        pendingRefreshFolders: state.pendingRefreshFolders.filter(f => f !== data?.id)
      }
    default:
      return state
  }

}

