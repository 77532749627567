export const DEFAULT_FLAGS = {
  bluefoo: false,
  formBuilder: false,
  errorMode: false,
  debugMode: false,
  dialogs: true,
  mixpanel: false,
  prodMode: false,
  protocol: process.env.REACT_APP_PROTOCOL,
  domain: process.env.REACT_APP_DOMAIN,
  apiUrl: process.env.REACT_APP_API_URL,
  submissionApiUrl: process.env.REACT_APP_SUBMISSION_API_URL,
  legacyUrl: process.env.REACT_APP_LEGACY_URL,
  autoSaveInterval: 10,
}
