import './UploadDetails.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  Align,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@sm/wds-react'
import { Modal } from '../Modal'
import { ProgressBar } from '../../../components/Progress/ProgressBar'
import { UploadDetailsRow } from '../../../components/Files/UploadDetailsRow'
import { getCompletedUploadCount, isBulkUploadComplete } from '../../../models/files'
import { getUpdatedUploadFileList } from '../../../models/files'
import { percent } from '../../../utils/math'
import { urls } from '../../../utils/urls'
import { FILE_UPLOAD_STATUS } from '../../../constants/files'


export class UploadDetails extends React.Component {

  constructor(props) {
    super(props)
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
    dispatch({ type: 'SET_REFRESH', data: true })
    dispatch({ type: 'RESET_FILE_REQUEST' })
  }

  handleViewFolder = () => {
    const { dispatch, files } = this.props

    dispatch({ type: 'SELECT_FILE', data: { ...files.fileRequest.parentFolder, isFavourite: false }})

    this.handleClose()
  }

  handleCancel = (file) => {
    const { dispatch, files } = this.props
    const newFiles = getUpdatedUploadFileList(files.fileRequest.files, [ file ], FILE_UPLOAD_STATUS.CANCELLED)
    dispatch({ type: 'SET_FILE_REQUEST', data: { files: newFiles }})
  }

  handleCancelAll = () => {
    const { dispatch, files } = this.props

    if (!isBulkUploadComplete(files.fileRequest.files)) {
      const newFiles = getUpdatedUploadFileList(files.fileRequest.files, files.fileRequest.files, FILE_UPLOAD_STATUS.CANCELLED)
      dispatch({ type: 'SET_FILE_REQUEST', data: { files: newFiles }})
    }
  }

  render() {
    const { t, files } = this.props
    const fileList = [ ...files.fileRequest.files ]
    const completedCount = getCompletedUploadCount(fileList)
    const completedPercent = Math.floor(percent(completedCount, fileList.length))

    const progressClassNames = classnames(
      'upload-progress-overview',
      { 'upload-complete': completedPercent === 100 }
    )
    return (
      <Modal
        className='upload-details-modal'
        name='upload-details'
        dismissible={false}
        size='lg'
      >
        <ModalHeader
          header={(
            <div className='upload-details-header'>
              <span className='wds-modal__title'>
                {t('upload_details_header')}
              </span>
            </div>
          )}
          addOn={isBulkUploadComplete(fileList) ?
            <a href={`#${urls.FILE_MANAGER}`} onClick={this.handleViewFolder}>{t('View_folder')}</a>
            :
            <a href={`#${urls.FILE_MANAGER}`} onClick={this.handleCancelAll}>{t('cancel_all_uploads')}</a>
          }
        />

        <ModalBody>

          <div className='upload-details-content'>
            <table>
              <tbody>
                {fileList.map(file => (
                  <UploadDetailsRow
                    key={file.name}
                    file={file}
                    status={file.status}
                    handleCancel={this.handleCancel}
                  />
                ))}
              </tbody>
            </table>
          </div>

        </ModalBody>

        <ModalFooter>

          <div className={progressClassNames}>
            <ProgressBar
              className='pt-2 pb-1'
              percent={completedPercent}
            />
            <span className='upload-progress-percentage'>{`${completedPercent}%`}</span>
            <span>{`${completedCount} of ${fileList.length} files uploaded`}</span>
          </div>

          <Align placement='right'>
            <Button
              size='md'
              onClick={this.handleClose}
              disabled={!isBulkUploadComplete(fileList)}
            >
              {t('DONE')}
            </Button>
          </Align>
        </ModalFooter>
      </Modal>
    )
  }
}

const select = (state) => ({
  files: state.files
})

UploadDetails.propTypes = {
  dispatch: PropTypes.func,
  files: PropTypes.object,
  t: PropTypes.func
}

UploadDetails = withTranslation('modals')(UploadDetails)
UploadDetails = connect(select)(UploadDetails)

export default UploadDetails
