import intersectionWith from 'lodash.intersectionwith'
import isEmpty from 'lodash.isempty'
import { isNumber } from '../utils/number'
import isEqual from 'lodash.isequal'

export const MAX_LENGTH_LABEL_NAME = 50

export const isNameEmpty = name => {
  return name.length === 0
}

export const isNameTooLong = name => {
  return name.length > MAX_LENGTH_LABEL_NAME
}

export const isDefaultFilterSelected = (defaultFilters, selectedFilters) => {
  return !isEmpty(intersectionWith(defaultFilters, selectedFilters, isEqual))
}

export const getLabelQueryParams = (labelSelection) => {
  let labelIds = (labelSelection && labelSelection.map(selectedLabel => selectedLabel.id)) || []
  const filterKey = labelIds.find(labelId => !isNumber(labelId)) || 'all'
  return {
    filterKey,
    labelIds: labelIds.filter(isNumber)
  }
}

