import './EmailEntry.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Modal } from '../Modal'
import { keyPressed } from '../../../utils/a11y'
import { EMAIL_SEPARATOR, MAXIMUM_ENTRIES_TO_EMAIL } from '../../../models/entries'
import { sendEntryEmail } from '../../../actions/entries'
import {
  MAX_EMAIL_COUNT,
  areEmailsValid,
  exceedsEmailLimit,
  isValidEmail,
} from '../../../models/validations'
import {
  Align,
  Badge,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@sm/wds-react'
import { IconInfo, IconX } from '@sm/wds-icons'


export class EmailEntry extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      emailInput: '',
      isValid: false
    }
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'SET_ENTRY_EMAILS', data: []})
    dispatch({ type: 'CLOSE_MODAL' })
  }

  handleInputChange = (e, shouldClearInput) => {
    const { dispatch, entries } = this.props

    let newEmails = []
    let emailInput = e.target.value
    let isValid = false

    if (emailInput.indexOf(EMAIL_SEPARATOR) !== -1) { // text with comma
      newEmails = emailInput.split(EMAIL_SEPARATOR)
      emailInput = newEmails[newEmails.length - 1]

      newEmails = newEmails.filter((email) => email === '' ? false : true)
      isValid = areEmailsValid(entries.emails, newEmails)

    } else { // text without comma
      newEmails = shouldClearInput ? [ emailInput ] : []
      isValid = areEmailsValid(entries.emails, [ emailInput ])
    }

    emailInput = shouldClearInput? '' : emailInput
    newEmails = entries.emails.concat(newEmails)
    isValid = isValid && !exceedsEmailLimit(newEmails)

    dispatch({ type: 'SET_ENTRY_EMAILS', data: newEmails })
    this.setState({ emailInput, isValid })
  }

  handlePaste = (e) => {
    e.preventDefault()
    e.target.value = e.clipboardData.getData('Text')
    this.handleInputChange(e, true)
  }

  handleEmailRemove = (emailIndex) => () => {
    const { dispatch, entries } = this.props
    const { emailInput } = this.state

    const newEmails = entries.emails.filter((email, index) => index !== emailIndex)
    let isValid = areEmailsValid(newEmails, [ emailInput ])
    isValid = isValid && !exceedsEmailLimit(newEmails)

    dispatch({ type: 'SET_ENTRY_EMAILS', data: newEmails })
    this.setState({ isValid })
  }

  handleEmailRequest = () => {
    const { t, dispatch, form, entries, selections } = this.props
    const { emailInput } = this.state

    const formId = form.form.id
    const entryIds = entries.modalEntry ? [ entries.modalEntry.id ] : selections.entries
    const emails = entries.emails.length === 0 ? [ emailInput ] : entries.emails

    dispatch(sendEntryEmail(formId, emails, entryIds, {
      successMessage: t('email_entry_success_toast'),
      errorMessage: t('email_entry_failed_toast')
    }))

    this.handleClose()
  }

  renderBadges(emails) {
    return emails.map((email, index) => {
      return (
        <Badge
          pill
          color={isValidEmail(email) ? 'primary' : 'warning'}
          key={index}
        >
          <span>{email}</span>
          <a href='#/' onClick={this.handleEmailRemove(index)} id='entries-email-modal-delete'><IconX /></a>
        </Badge>
      )
    })
  }

  render(){
    const { t, count, entries } = this.props
    const { emailInput, isValid } = this.state

    return (
      <Modal
        className='email-entry-modal'
        name='email-entry'
        size='md'
      >
        <ModalHeader
          header={(
            <div className='email-entry-header'>
              <h1 className='wds-modal__title'>
                {t('email_entry_header', { count })}
              </h1>
            </div>
          )}
        />

        <ModalBody>
          <div className='email-description-top'>
            {t('email_entry_description')}
          </div>
          <div className='email-input-container'>
            <label
              className='wds-label'
              htmlFor='email-input'
            >
              {t('email_input_header', { maxEmailCount: MAX_EMAIL_COUNT })}
            </label>
            <InputGroup
              stretched
            >
              <InputGroupAddon>
                {this.renderBadges(entries.emails)}
              </InputGroupAddon>
              <Input
                id='entries-email-modal-input'
                stretched
                placeholder={entries.emails.length === 0 ? t('default_email_address') : ''}
                value={emailInput}
                onChange={this.handleInputChange}
                onKeyPress={keyPressed('Enter', (e) => this.handleInputChange(e, true))}
                onPaste={this.handlePaste}
              />

            </InputGroup>
          </div>
          <div className='email-description-bottom'>
            <IconInfo />
            {t('email_max_entries_description', { maxEntryCount: MAXIMUM_ENTRIES_TO_EMAIL })}
          </div>
        </ModalBody>

        <ModalFooter>
          <Align placement='right'>
            <Button
              id='entries-email-modal-cancel'
              variant='ghost'
              color='secondary'
              size='sm'
              onClick={this.handleClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              id='entries-email-modal-save'
              size='sm'
              disabled={!isValid}
              onClick={this.handleEmailRequest}
            >
              {t('EMAIL')}
            </Button>
          </Align>
        </ModalFooter>
      </Modal>
    )
  }
}

EmailEntry.propTypes = {
  count: PropTypes.number,
  entries: PropTypes.object,
  form: PropTypes.object,
  selections: PropTypes.object,
  dispatch: PropTypes.func,
  t: PropTypes.func,
}

const select = (state) => ({
  entries: state.entries,
  form: state.form,
  selections: state.selections
})

EmailEntry = withTranslation('modals')(EmailEntry)
EmailEntry = connect(select)(EmailEntry)

export default EmailEntry
