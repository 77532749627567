import React from 'react'
import loadable from '@loadable/component'
import { Loader } from '../components/Loader'

// Setup for code-splitting
export const split = importer => {
  return loadable(importer, {
    fallback: (<Loader loading={true} fixed={true} />)
  })
}
