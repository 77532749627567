import './ExportInProgress.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import {
  ModalBody,
  ModalHeader
} from '@sm/wds-react'
import { IconWarning } from '@sm/wds-icons'

import { Modal } from '../Modal'

export const ExportInProgress = ({ t }) => {
  return (
    <Modal
      className='export-in-progress-modal'
    >
      <ModalHeader
        header={(
          <span className='wds-modal__title'>
            <span className='title-body'>
              <IconWarning />
              {t('export_in_progress_header')}
            </span>
          </span>
        )}
      />
      <ModalBody>
        <div>
          <div>
            {t('export_in_progress_body_1')}
          </div>
          <div>
            {t('export_in_progress_body_2')}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

ExportInProgress.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation('modals')(ExportInProgress)
