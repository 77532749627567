import { buildEntryFetchParams } from './entries'


const ENTRY_QUERY_PARAM_KEYS = [ 'filterKey', 'query', 'expression' ]

export const buildExportConditionsFromEntryIdList = (entryIdList) => {
  if (!entryIdList.every((entryId) => (typeof entryId == 'number'))) {
    throw new Error('EntryIds expected to be an array of integers')
  }

  const entryConditions = entryIdList.map( (entryId) => ({
    key: 'id',
    op: '==',
    value: `${entryId}`, compareToNone: false
  }))

  return {
    conditionsArray: [],
    expression: {
      conditions: entryConditions,
      operator: 'or'
    }
  }
}

export const buildExportConditionsFromEntryQueryParams = (queryParams) => {
  if (!ENTRY_QUERY_PARAM_KEYS.every((k) => (k in queryParams))) {
    throw new Error('Invalid entry query spec')
  }
  const {
    filterKey,
    query,
    expression
  } = queryParams

  const entryFetchParams = buildEntryFetchParams(filterKey, expression, query)

  return {
    conditionsArray: entryFetchParams.conditions,
    expression: entryFetchParams.expression
  }
}

export const buildEntriesExportsConditionsSpec = (spec) => {
  return Array.isArray(spec) ?
    buildExportConditionsFromEntryIdList(spec) :
    buildExportConditionsFromEntryQueryParams(spec)
}