import HttpStatus from 'http-status-codes'
import { api, getTableQueryParams } from '../services/api'
import { clearCacheForUrl } from '../services/api/cache'
import { downloadFromUrl } from '../services/api'
import history from '../services/history'
import { fetchForm } from './form'
import { CSV } from '../utils/mimetypes'
import { apiError } from './api'
import { getFlag } from '../utils/selector'
import { parameterize } from '../utils/string'
import { urls, getUrl } from '../utils/urls'


export const fetchEntry = (formId, entryId) => {
  return (dispatch, getState) => {
    const { entry, form } = getState()

    // Don't fetch entry if already fetched
    if (form.form && form.form.id === formId && entry.entry && entry.entry.id === entryId) {
      return
    }

    // Only fetch the form if we haven't yet
    if (!form.form || `${form.form.id}` !== formId) {
      // Reset the entry cache in case entries have changed since last time they viewed
      dispatch({ type: 'SET_ENTRY_LOADING', data: true })
      dispatch(fetchForm(formId))
    }

    const requests = [
      api.get(`/forms/${formId}/entries/${entryId}`, { cache: true }),
      api.get(`/forms/${formId}/entries/${entryId}/comments`, { cache: true })
    ]

    Promise.all(requests)
      .then(data => {
        const [ entry, comments ] = data
        dispatch({ type: 'SET_CURRENT_ENTRY', data: entry.data })
        dispatch({ type: 'SET_ENTRY_COMMENTS', data: comments.data })
      })
      .catch(() => {})
      .then(() => {
        dispatch({ type: 'SET_ENTRY_LOADING', data: false })
      })

    dispatch(fetchPreviousEntry(formId, entryId))
    dispatch(fetchNextEntry(formId, entryId))
  }
}

export const fetchPreviousEntry = (formId, entryId) => {
  return dispatch => {
    return api.get(`/forms/${formId}/entries/${entryId}/order_previous`, { cache: true })
      .then(({ data }) => {
        dispatch({ type: 'SET_PREVIOUS_ENTRY', data })
      })
      .catch(() => {
        dispatch({ type: 'SET_PREVIOUS_ENTRY', data: null })
      })
  }
}

export const fetchNextEntry = (formId, entryId) => {
  return dispatch => {
    return api.get(`/forms/${formId}/entries/${entryId}/order_next`, { cache: true })
      .then(({ data }) => {
        dispatch({ type: 'SET_NEXT_ENTRY', data })
      })
      .catch(() => {
        dispatch({ type: 'SET_NEXT_ENTRY', data: null })
      })
  }
}

export const downloadEntry = (form, entryId) => {
  const { id, name } = form

  const conditions = [{
    key: 'id',
    value: entryId,
    op: '=='
  }]

  return () => {
    api.download(
      `/forms/${id}/entries/download?${getTableQueryParams({ conditions })}`,
      `${parameterize(name)}_entry_${entryId}.csv`,
      {
        headers: {
          Accept: CSV
        }
      }
    )
  }
}

export const downloadEntryFile = (formId, entryId, fieldId, fileName, errorMessage) => {
  return dispatch => {
    api.get( `/forms/${formId}/entries/${entryId}/fields/${fieldId}/file`).then(response => {
      const url = response.data.url
      try {
        downloadFromUrl(url, fileName)
      } catch (err) {
        const status = err.response && err.response.status

        if (status === HttpStatus.NOT_FOUND) {
          dispatch({ type: 'SHOW_TOAST', data: {
            type: 'error',
            title: errorMessage,
            autoClose: false
          }})
        }
      }
    })
  }
}

export const fetchComments = (formId, entryId) => {
  return dispatch => {
    dispatch({ type: 'SET_ENTRY_COMMENTS_LOADING', data: true })

    api.get(`/forms/${formId}/entries/${entryId}/comments`, { cache: true })
      .then(({ data }) => {
        dispatch({ type: 'SET_ENTRY_COMMENTS', data })
      })
      .catch(() => {
        dispatch({ type: 'SET_ENTRY_COMMENTS_LOADING', data: false })
      })
  }
}

export const addComment = (formId, entryId, message, errorMessage) => {
  return dispatch => {
    api.post(`/forms/${formId}/entries/${entryId}/comments`, { message }, { cache: true })
      .then(() => {
        clearCacheForUrl(`/forms/${formId}/entries/${entryId}/comments`)
        dispatch(fetchComments(formId, entryId))
      })
      .catch(() => {
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'error',
          title: errorMessage,
          autoClose: false
        }})
      })
  }
}

export const deleteComment = (formId, entryId, commentId, errorMessage) => {
  return dispatch => {
    api.delete(`/forms/${formId}/entries/${entryId}/comments/${commentId}`)
      .then(() => {
        clearCacheForUrl(`/forms/${formId}/entries/${entryId}/comments`)
        dispatch(fetchComments(formId, entryId))
      })
      .catch(() => {
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'error',
          title: errorMessage,
          autoClose: false
        }})
      })
  }
}

export const changePaymentStatus = (formId, entryId, status, errorMessage) => {
  return (dispatch) => {
    api.put(`/forms/${formId}/entries/${entryId}/payment/status`, { status })
      .then(() => {
        dispatch({ type: 'UPDATE_ENTRY_PAYMENT', data: { status }})
      })
      .catch(() => {
        dispatch({ type: 'UPDATE_ENTRY_PAYMENT', data: {}})
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'error',
          title: errorMessage,
          autoClose: false
        }})
      })
  }
}

export const changeSignatureState = (formId, entryId, state, errorMessage) => {
  return (dispatch) => {
    api.put(`/forms/${formId}/entries/${entryId}/signature/state`, { state })
      .then(() => {
        dispatch({ type: 'UPDATE_ENTRY_SIGNATURE', data: { state }})
      })
      .catch(() => {
        dispatch({ type: 'UPDATE_ENTRY_SIGNATURE', data: {}})
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'error',
          title: errorMessage,
          autoClose: false
        }})
      })
  }
}

export const editEntry = (formId, entryId, changedData) => {
  return async (dispatch, getState) => {
    const formData = new FormData()

    // Grab comments from entry rather than fetching it again, since there should be no change to comments
    const { entry } = getState()

    dispatch({ type: 'SET_ENTRY_SAVING', data: true })

    changedData.forEach(item => {
      formData.append(item.id, item.value || '')
    })

    try {
      const { data } = await api.patch(
        `/forms/${formId}/entries/${entryId}/fields`,
        formData,
        { noDecamelize: true }
      )

      // Clear the cached url so that we refetch correct values
      clearCacheForUrl(`/forms/${formId}/entries/${entryId}`)
      const updatedEntry = await api.get(`/forms/${formId}/entries/${entryId}`)


      dispatch({ type: 'SET_CURRENT_ENTRY', data: updatedEntry.data })
      dispatch({ type: 'UPDATE_ENTRY_FIELDS', data })
      dispatch({ type: 'SET_ENTRY_EDITING', data: false })
      dispatch({ type: 'SET_ENTRY_COMMENTS', data: entry.comments })
    } catch (err) {
      const status = err.response && err.response.status
      if (status === HttpStatus.BAD_REQUEST && err.response.data) {
        dispatch(apiError('edit-entry', err.response.data.errors.length > 0 ? err.response.data.errors : 'generic_entry_error'))
      }
      dispatch(apiError('edit-entry', 'generic_entry_error'))
    }

    dispatch({ type: 'SET_ENTRY_SAVING', data: false })
  }
}

export const createEntry = (formId, formHash, changedData, successMessage) => {
  return async (dispatch, getState) => {
    const formData = new FormData()
    const { user } = getState()

    dispatch({ type: 'SET_ENTRY_SAVING', data: true })

    const getFieldId = id => `Field${id}`  // TODO: get fields from API and map using formId?

    changedData.forEach(item => {
      let submitValue = item.value || ''
      if (submitValue && item.type === 'datetime') {
        submitValue = submitValue.replaceAll('-', '')   /* YYYYMMDD from YYYY-MM-DD, needed for API v3 */
      }
      formData.append(getFieldId(item.id), submitValue)
    })

    try {
      const { data } = await api.post(
        `${getFlag('protocol')}://${user.subdomain}.${getFlag('legacyUrl')}/api/v3/forms/${formHash}/entries.json?bypassFieldRules=true`,
        formData,
        {
          noDecamelize: true,
          bypass: true,
          headers: { Authorization: `Basic ${btoa(`${user.apiKey}:wufoo`)}` }
        }
      )
      if (data.success) {
        const entryId = data.entryId
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'success',
          title: successMessage,
          autoClose: true
        }})
        history.push(getUrl(urls.ENTRY_DETAIL, { formId, entryId }))
      } else {
        const newErrors = []
        if (data.fieldErrors?.length > 0) {
          data.fieldErrors.map(item => {
            newErrors.push({
              'field_ids': [ parseInt(item.ID.replace('Field', '')) ],
              'errorText': item.errorText
            })
          })
        }

        dispatch(apiError('create-entry', newErrors.length > 0 ? newErrors : 'generic_entry_error'))
      }
    } catch (err) {
      dispatch(apiError('create-entry', 'generic_entry_error'))
    }

    dispatch({ type: 'SET_ENTRY_SAVING', data: false })
  }
}
