const FILE_EXTENSIONS = [ 'jpeg', 'jpg', 'gif', 'png', 'apng', 'svg', 'bmp', 'ico', 'css' ]
export const FILE_SIZE_LIMIT = 25000000 // 25 MB
export const FILE_UPLOAD_STATUS = {
  CANCELLED: 'cancelled',
  COMPLETE: 'complete',
  ERROR: 'error',
}
export const IMAGE_EXTENSIONS = [ 'jpeg', 'jpg', 'gif', 'png', 'apng', 'svg', 'bmp', 'ico' ]
export const ALLOWED_MIME_TYPES = [ 'image/jpeg', 'image/gif', 'image/png', 'image/x-png', 'image/apng', 'image/svg+xml', 'image/bmp', 'image/x-icon', 'text/css' ]
export const ALLOWED_FILETYPES = [ ALLOWED_MIME_TYPES, FILE_EXTENSIONS ]
