const defaultState = {
  isAuthenticated: false,
  authenticationFailed: false,
  language: 'en',
  loadingOnLogin: false,
  firstTimeLoading: true
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_LANGUAGE':
      return { ...state, language: data }
    case 'SET_AUTHENTICATED':
      return { ...state, isAuthenticated: data }
    case 'AUTHENTICATION_FAILED':
      return { ...state, authenticationFailed: true }
    case 'LOADING_ON_LOGIN':
      return { ...state, loadingOnLogin: data }
    case 'FIRST_TIME_LOADING':
      return { ...state, firstTimeLoading: data }
    default:
      return state
  }

}
