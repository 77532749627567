import { getWordCount, trimNumber } from './entries'
import isEmpty from 'lodash.isempty'
import moment from 'moment/moment'


// source: http://emailregex.com/
const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// source: http://urlregex.com/
// eslint-disable-next-line no-useless-escape
const URL_VALIDATION_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
const HTTP_VALIDATION_REGEX = /^(http|https):\/\//
export const MAX_EMAIL_COUNT = 20


export const areEmailsValid = (processedEmails, unprocessedEmails, checkDupes=false) => {
  const allEmails = [ ...processedEmails, ...unprocessedEmails ]

  if (checkDupes) {
    const hasDupes = new Set(allEmails).size !== allEmails.length
    return !hasDupes && isValidEmailList(processedEmails) && isValidEmailList(unprocessedEmails)
  }
  else {
    return isValidEmailList(processedEmails) && isValidEmailList(unprocessedEmails)
  }
}

export const exceedsEmailLimit = (emails) => {
  return (emails.length > MAX_EMAIL_COUNT)
}

export const isValidEmail = (email) => {
  return isEmpty(email) || EMAIL_VALIDATION_REGEX.test(email)
}

const isValidEmailList = (emailList) => {
  return emailList.every(isValidEmail)
}

export const isValidUrl = (url) => {
  return URL_VALIDATION_REGEX.test(url)
}

export const isValidTime = (value) => {
  const hmsArray = value.split(':')
  if (hmsArray.length < 3) {
    return false
  }
  return hmsArray.every(isValidTimeElement)
}

export const isValidTimeElement = (value, index) => {
  if (isEmpty(value) || value.length > 2) {
    return false
  }
  const parsedValue = Number(value)
  if (index === 0) {
    return !Number.isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 23
  }
  return !Number.isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 59
}

export const isValidDate = (value) => {
  return value === '--' || moment(value, 'YYYY-MM-DD', true).isValid()
}

export const isValidPrice = (value) => {
  const split = value.split('.')

  // Decimal places should be absent OR only go up to 2 digits
  if (split.length <= 2) {
    return split.length === 1 || split[1].length <= 2
  }

  return false
}

export const isValidRange = (value, { min, max = 0 }) => {
  return value >= min && (value <= max || max < min || max === 0)
}

// Same validation logic in API and Legacy
export const validateField = (fieldSet, value, isCreating = false, isEdited = true) => {
  const { options = {}} = fieldSet?.fields?.[0] || {}
  let isValid = true
  let errorKey

  if (isValid && options.validation) {
    switch (options.validation) {
      case 'email': {
        isValid = isValidEmail(value)
        errorKey = !isValid && 'field_validation_error_email'
        break
      }
      case 'number': {
        const parsedValue = Number(value)
        isValid = isEmpty(value) || !Number.isNaN(parsedValue)
        errorKey = !isValid && 'field_validation_error_number'
        break
      }
      case 'phone_number': {
        if (fieldSet.type === 'international_phone') {
          const symbolRemovedValue = value.replace(new RegExp(/([-.+\s])/, 'g'), '')
          const parsedValue = Number(symbolRemovedValue)
          isValid = isEmpty(value) || (!Number.isNaN(parsedValue)
            && symbolRemovedValue.length >= 7 && symbolRemovedValue.length <= 19)
          errorKey = !isValid && 'field_validation_error_international_phone'
        } else {
          const parsedValue = Number(value)
          isValid = isEmpty(value) || (!Number.isNaN(parsedValue)
            && parsedValue.toString().length === value.length && value.length === 10)
          errorKey = !isValid && 'field_validation_error_phone'
        }
        break
      }
      case 'url': {
        isValid = false

        if (!isEmpty(value) && !HTTP_VALIDATION_REGEX.test(value)) {
          errorKey = 'field_validation_error_url_no_http'
        } else if (!isEmpty(value) && !isValidUrl(value)) {
          errorKey = 'field_validation_error_url'
        } else {
          isValid = true
        }
        break
      }
      default:
        break
    }
  }

  if (isCreating && !value && options.isRequired) {
    // Due to WUFOO-13411, we don't want front-end to validate required fields
    // errorKey = 'field_validation_error_required'
    // isValid = false
  }

  // Empty values are considered valid
  if (isValid && !isEmpty(value)) {
    // Examine range validations
    if (options.range) {
      switch (options.range.type) {
        case 'value': {
          const parsedValue = Number(value)
          isValid = !Number.isNaN(parsedValue) && isValidRange(parsedValue, options.range)
          errorKey = !isValid && 'field_validation_error_number_range'
          break
        }
        case 'digit': {
          value = isEdited ? value : trimNumber(value)
          const parsedValue = Number(value)
          isValid = !Number.isNaN(parsedValue) && isValidRange(value.length, options.range)
          errorKey = !isValid && 'field_validation_error_number_digit'
          break
        }
        case 'character': {
          isValid = isValidRange(value.length, options.range)
          errorKey = !isValid && 'field_validation_error_character_range'
          break
        }
        case 'word': {
          const wordCount = getWordCount(value)
          isValid = isValidRange(wordCount, options.range)
          errorKey = !isValid && 'field_validation_error_word_range'
          break
        }
        default:
          break
      }
    }

    // Examine other validations
    switch (fieldSet.type) {
      case 'file': {
        const parsedValue = Number(value)
        if (!Number.isNaN(parsedValue)) {
          isValid = parsedValue <= options.maxFileSize
          errorKey = !isValid && 'field_validation_error_max_file_size'
        }
        break
      }
      case 'time': {
        isValid = isValidTime(value)
        errorKey = !isValid && 'field_validation_error_time'
        break
      }
      case 'date': {
        isValid = isValidDate(value)
        errorKey = !isValid && 'field_validation_error_date'
        break
      }
      case 'price': {
        isValid = isValidPrice(value)
        errorKey = !isValid && 'field_validation_error_price'
        break
      }
      default:
        break
    }
  }

  errorKey = errorKey || null
  return { isValid, errorKey }
}
