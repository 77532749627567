import moment from 'moment'
import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import reduxStore from '../../store/reduxStore'
import { getFlag } from '../../utils/selector'

import { store, JWT_TOKEN, JWT_EXPIRY } from '../storage'

const MILLISECONDS = 1000
let authHeader

export const initializeAuthentication = (requiresAuth = true) => {
  if (requiresAuth) {
    processLegacyRedirect()
    const jwt = getJwt()
    if (!jwt || isJwtExpired()) {
      reauthenticate()
      return false
    }

    setAuthHeader(jwt.token)
  }
  return true
}

export const isJwtExpired = () => {
  const expiry = store.get(JWT_EXPIRY) || 0
  const now = moment()
  const exp = moment(expiry)

  return !(expiry && exp > now)
}

export const getJwt = () => {
  const token = store.get(JWT_TOKEN)
  const expiry = store.get(JWT_EXPIRY)
  return token ? { token, expiry } : null
}

export const decodeJwtExp = (token) => {
  try {
    return jwtDecode(token).exp
  } catch (e) {
    return 0
  }
}

export const authenticate = jwt => {
  store.set(JWT_TOKEN, jwt.token)
  store.set(JWT_EXPIRY, jwt.expiration * MILLISECONDS)
  setAuthHeader(jwt.token)
}

export const deauthenticate = () => {
  // remove legacy cookie for remember-me option
  Cookie.remove('wuLog', {
    path: '/',
    domain: `.${getFlag('legacyUrl')}`,
    secure: true,
    samesite: 'None'
  })

  store.remove(JWT_TOKEN)
  store.remove(JWT_EXPIRY)
  deleteAuthHeader()
}

export const reauthenticate = () => {
  deauthenticate()

  window.location = `${getFlag('protocol')}://secure.${getFlag('legacyUrl')}/login`
}

export const mobileRedirect = () => {
  const url = `${getFlag('protocol')}://m.${getFlag('legacyUrl')}`
  window.location = url
}

export const useDesktop = () => {
  Cookie.set('m_wufoo_preference', 0, { domain: `.${getFlag('legacyUrl')}` })
  window.location.reload()
}

export const isMobileBypassEnabled = () => {
  return Cookie.get('m_wufoo_preference') === '0'
}

export const setAuthHeader = token => {
  authHeader = `Bearer ${token}`
}

export const deleteAuthHeader = () => {
  authHeader = null
}

export const isAuthHeaderSet = () => {
  return !!authHeader
}

export const getAuthHeader = () => {
  return authHeader
}


// For legacy redirection only
export const processLegacyRedirect = () => {
  const token = Cookie.get('wuOokie')

  if (token) {
    authenticate({
      token,
      expiration: decodeJwtExp(token)
    })

    const isSecure = getFlag('protocol') === 'https'

    Cookie.remove('wuOokie', {
      path: '/',
      domain: `.${getFlag('legacyUrl')}`,
      secure: isSecure,
      samesite: isSecure ? 'None' : 'Lax'
    })

    setTimeout(() => {
      reduxStore.dispatch({ type: 'SET_AUTHENTICATED', data: true })
      reduxStore.dispatch( { type: 'LOADING_ON_LOGIN', data: true })
    }, 0)
  }
}

