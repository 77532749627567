import { api } from '../services/api'

export const getBillingStatus = () => {
  return dispatch => {
    api.get('/billing/status')
      .then(({ data }) => {
        dispatch({ type: 'SET_BILLING_STATUS', data })
      })
      .catch(() => {})
  }
}
