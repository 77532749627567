import React from 'react'
import PropTypes from 'prop-types'
import { toID } from '../../../utils/string'


/* eslint-disable react/jsx-no-target-blank */

export const LinkList = props => {
  const { title, links } = props

  return (
    <ul>
      <li><h3>{title}</h3></li>
      {links.map(link => (
        <li key={link.text}>
          <a
            href={link.href}
            target='_blank'
            rel={`noopener noreferrer ${link.rel}`}
            id={link.id ? link.id.toString() : toID(`footer-${link.text}`)}
            className={link.className}
          >
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  )
}

/* eslint-disable react/jsx-no-target-blank */

LinkList.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired
}

export default LinkList
