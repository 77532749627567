
export const withToastDisplay = async (
  dispatch,
  { loadingMessage, successMessage, errorMessage, loadingOptions = {}, successOptions = {}, errorOptions = {}},
  actionFunc
) => {
  try {
    loadingMessage && dispatch({ type: 'SHOW_TOAST', data: {
      type: 'loading',
      title: loadingMessage,
      dismissible: false,
      autoClose: false,
      ...loadingOptions
    }})

    await actionFunc()

    successMessage && dispatch({ type: 'SHOW_TOAST', data: {
      type: 'success',
      title: successMessage,
      ...successOptions
    }})
  } catch (e) {
    const { getErrorMessage, hideOnError } = errorOptions
    if (hideOnError?.(e)) {
      dispatch({ type: 'HIDE_TOAST' })
    } else {
      const updatedErrorMessage = getErrorMessage?.(e) || errorMessage
      updatedErrorMessage && dispatch({ type: 'SHOW_TOAST', data: {
        type: 'error',
        title: updatedErrorMessage,
        autoClose: false
      }})
    }
  }
}
