import './WufooAvatar.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@sm/wds-react'
import { getInitials } from '../../models/avatar'
import { USER_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


export class WufooAvatar extends React.PureComponent {

  render() {
    const { user } = this.props
    const { name, email } = user

    const displayName = name ? name : email
    const avatar = getInitials(displayName)

    if (displayName) {
      return (
        <Avatar>
          <div className='wufoo-avatar-circle'>
            <div className='wufoo-avatar-initials'>
              {avatar}
            </div>
          </div>
        </Avatar>
      )
    }
    return null
  }
}

WufooAvatar.propTypes = {
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
}

export default WufooAvatar
