import axios from 'axios'
import humps from 'humps'
import { downloadFromBlob as FileDownload } from './filedownload'
import HttpStatus from 'http-status-codes'

import reduxStore from '../../store/reduxStore'
import { i18n } from '../i18n'
import { cacheUrl, getCachedValueForUrl } from './cache'
import { getFlag } from '../../utils/selector'
import {
  isAuthHeaderSet,
  isJwtExpired,
  getAuthHeader,
  processLegacyRedirect,
  reauthenticate,
} from './auth'


const NO_AUTH_ENDPOINTS = [
  '/auth/login',
  getFlag('submissionApiUrl')
]

export const apiRoute = (path) => {
  return `${getFlag('protocol')}://${getFlag('apiUrl')}/api/v4${path}`
}

// AXIOS API WRAPPER
const wrapper = (method, url, options={}) => {
  const {
    autoRedirect = true,
    bypass,
    cache,
    data,
    headers,
    noCamelize,
    noDecamelize,
    withoutAuth,
    ...rest
  } = options

  // Bypass JWT verification for endpoints that do not require authentication
  if (!NO_AUTH_ENDPOINTS.find(endpoint => url.includes(endpoint))) {
    processLegacyRedirect()

    // Redirect user to login if auth token is expired
    if (!isAuthHeaderSet() || isJwtExpired()) {
      if (autoRedirect) {
        reduxStore.dispatch({ type: 'SET_AUTHENTICATED', data: false })
        reauthenticate()
      }
      return Promise.reject({ response: { status: HttpStatus.UNAUTHORIZED }})
    }
  }

  const authHeaders = (withoutAuth || bypass) ? {} : {
    Authorization: getAuthHeader()
  }

  return axios({
    method,
    url: bypass ? url : apiRoute(url),
    headers: { ...headers, ...authHeaders },
    ...rest,
    data: data ? (noDecamelize ? data : humps.decamelizeKeys(data,
      (key, convert) => (key.includes('-') || key === key.toUpperCase()) ? key : convert(key))): undefined
  })
    .then(response => {
      if (!noCamelize) {
        // do not camelize keys that have hyphen
        response['data'] = humps.camelizeKeys(response.data,
          (key, convert) => (key.includes('-') || key === key.toUpperCase()) ? key : convert(key))
      }

      if (cache) {
        cacheUrl(url, response, cache)
      }

      return response
    })
    .catch(err => {
      const status = err.response && err.response.status

      if (status === HttpStatus.INTERNAL_SERVER_ERROR) {
        reduxStore.dispatch({ type: 'SHOW_TOAST', data: {
          type: 'warning',
          title: i18n.t('global:unknown_error'),
        }})
      }
      else if (status === HttpStatus.FORBIDDEN) {
        reduxStore.dispatch({ type: 'SHOW_TOAST', data: {
          type: 'warning',
          title: i18n.t('global:unauthorized')
        }})
      }
      else if (status === HttpStatus.UNAUTHORIZED) {
        reauthenticate()
      }

      throw err
    })
}

const _delete = (url, config) => { return wrapper('delete', url, config)  }
const head = (url, config) => { return wrapper('head', url, config)  }
const options = (url, config) => { return wrapper('options', url, config)  }
const post = (url, data, config) => { return wrapper('post', url, { ...config, data })  }
const put = (url, data, config) => { return wrapper('put', url, { ...config, data })  }
const patch = (url, data, config) => { return wrapper('patch', url, { ...config, data })  }

const get = (url, config={}) => {
  if (config.cache) {
    const cachedValue = getCachedValueForUrl(url)

    if (cachedValue) {
      return new Promise(resolve => {
        resolve(cachedValue)
      })
    }
  }

  return wrapper('get', url, config)
}

const download = (url, filename, config={}, method) => {
  const { callback, ...restConfig } = config
  return wrapper(
    method || 'get',
    url, {
      responseType: 'blob',
      noCamelize: true,
      ...restConfig
    }
  ).then(response => {
    if (callback) {
      return callback(() => FileDownload(response.data, filename))
    }
    return FileDownload(response.data, filename)
  })
}

const upload = (url, formData) => {
  let request = new XMLHttpRequest()
  request.open('POST', url, false)
  request.send(formData)
  if (request.status !== HttpStatus.NO_CONTENT) {
    throw new Error('Could not upload file.')
  }
}

export const api = {
  delete: _delete,
  upload,
  download,
  get,
  head,
  options,
  patch,
  post,
  put
}

export default api

