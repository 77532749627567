import { api } from '../services/api'
import { apiError } from './api'
import { mixpanel } from '../services/telemetry/mixpanel'


export const getPlan = (options={}) => {
  const { loading } = options

  return dispatch => {
    loading && dispatch({ type: 'SET_PLAN_LOADING', data: true })
    dispatch(apiError('plan-summary', false))

    let planData
    let planFeaturesData
    let statsData
    const requests = [
      api.get('/plan'),
      api.get('/plan/features'),
      api.get('/stats'),
    ]

    Promise.all(requests)
      .then(results => {
        planData = results[0].data
        planFeaturesData = results[1].data
        statsData = results[2].data
        mixpanel.registerPlanSupers(planData)

        const stats = {
          entries: statsData?.totalEntriesCurrentPeriod || 0,
          forms: statsData?.totalForms || 0,
          reports: statsData?.totalReports || 0,
          respondents: statsData?.storage?.respondentsUsage || 0,
          uploads: statsData?.storage?.assetsUsage || 0,
          users: statsData?.totalUsers || 0,
        }

        dispatch({ type: 'SET_CURRENT_PLAN', data: {
          plan: planData,
          planFeatures: planFeaturesData,
          stats,
        }})
      })
      .catch(() => {
        dispatch(apiError('plan-summary', true))
      })
      .then(() => {
        dispatch({ type: 'SET_PLAN_LOADING', data: false })
      })
  }
}
