import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ProgressBar as WDSProgressBar } from '@sm/wds-react'

export const ProgressBar = props => {
  const { label, className, percent, ...rest } = props

  const classNames = classnames(
    'progress-container',
    className
  )

  const progressBar = (
    <WDSProgressBar percent={`${percent}`} {...rest} />
  )

  if (label) {
    return (
      <div className={classNames}>
        <label>{label}</label>
        <div className='mt-2'>
          {progressBar}
        </div>
      </div>
    )
  }

  return progressBar
}

ProgressBar.defaultProps = {
  className: '',
  percent: 0,
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  percent: PropTypes.number,
}

export default ProgressBar
