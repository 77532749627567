import 'core-js/actual/array/find'
import 'core-js/actual/array/find-index'
import 'core-js/actual/array/includes'
import 'core-js/actual/array/fill'
import 'core-js/actual/array/from'
import 'core-js/actual/number/is-nan'
import 'core-js/actual/object/assign'
import 'core-js/actual/object/entries'
import 'core-js/actual/object/values'
import 'core-js/actual/promise'
import 'core-js/actual/set'
import 'core-js/actual/string/includes'
import 'core-js/actual/string/starts-with'

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-focuswithin-setclasses !*/
//eslint-disable-next-line
!function(n,e,s){function o(n,e){return typeof n===e}function a(){var n,e,s,a,t,r,l;for(var c in f)if(f.hasOwnProperty(c)){if(n=[],e=f[c],e.name&&(n.push(e.name.toLowerCase()),e.options&&e.options.aliases&&e.options.aliases.length))for(s=0;s<e.options.aliases.length;s++)n.push(e.options.aliases[s].toLowerCase());for(a=o(e.features,"function")?e.features():e.features,t=0;t<n.length;t++)r=n[t],l=r.split("."),1===l.length?Modernizr[l[0]]=a:(!Modernizr[l[0]]||Modernizr[l[0]]instanceof Boolean||(Modernizr[l[0]]=new Boolean(Modernizr[l[0]])),Modernizr[l[0]][l[1]]=a),i.push((a?"":"no-")+l.join("-"))}}function t(n){var e=l.className,s=Modernizr._config.classPrefix||"";if(c&&(e=e.baseVal),Modernizr._config.enableJSClass){var o=new RegExp("(^|\\s)"+s+"no-js(\\s|$)");e=e.replace(o,"$1"+s+"js$2")}Modernizr._config.enableClasses&&(e+=" "+s+n.join(" "+s),c?l.className.baseVal=e:l.className=e)}var i=[],f=[],r={_version:"3.6.0",_config:{classPrefix:"",enableClasses:!0,enableJSClass:!0,usePrefixes:!0},_q:[],on:function(n,e){var s=this;setTimeout(function(){e(s[n])},0)},addTest:function(n,e,s){f.push({name:n,features:e,options:s})},addAsyncTest:function(n){f.push({name:null,features:n})}},Modernizr=function(){};Modernizr.prototype=r,Modernizr=new Modernizr,Modernizr.addTest("focuswithin",function(){try{e.querySelector(":focus-within")}catch(n){return!1}return!0});var l=e.documentElement,c="svg"===l.nodeName.toLowerCase();a(),t(i),delete r.addTest,delete r.addAsyncTest;for(var u=0;u<Modernizr._q.length;u++)Modernizr._q[u]();n.Modernizr=Modernizr}(window,document);
