import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { LegacyLink } from '../../../components/LegacyLink'

export const Tab = props => {
  const {
    title,
    isActive,
    href,
    legacy,
    id
  } = props

  const classNames = classnames(
    'tab',
    { active: isActive }
  )

  if (legacy) {
    return (
      <LegacyLink
        className={classNames}
        to={href}
        id={id}
      >
        {title}
      </LegacyLink>
    )
  }

  return (
    <Link
      className={classNames}
      to={href}
      id={id}
    >
      {title}
    </Link>
  )
}

Tab.propTypes = {
  isActive: PropTypes.bool,
  id: PropTypes.string,
  href: PropTypes.string,
  legacy: PropTypes.bool,
  title: PropTypes.string
}

export default Tab
