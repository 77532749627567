import numeral from 'numeral'

export const INFINITY = -1

export const k = num => (numeral(num).format('0a'))

export const comma = num => (numeral(num).format('0,0'))

export const isMaxed = (num, max) => (num >= max && max !== INFINITY)

export const isNumber = value => (typeof value === 'number')

export const isReachingLimit = (num, outOf, percentLimit) => {
  return (num/outOf * 100) >= percentLimit
}

export const percentage = (num, outOf) => {
  return outOf === 0 ? 100 : numeral(num / outOf * 100).format('0.0')
}

export const range = num => (Array(num).fill().map((_, i) => (i+1)))
