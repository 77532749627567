import React from 'react'
import {
  IconAirplane,
  IconArrowCombine,
  IconBalloon,
  IconCart,
  IconDocumentUser,
  IconEye,
  IconFootprint,
  IconGear,
  IconGlobe,
  IconHappy,
  IconHeartHand,
  IconPencilPaper,
  IconShapes,
  IconTag,
  IconUser,
  IconUserPlus
} from '@sm/wds-icons'


export const formUseCards = [{
  name: 'contact-forms', title: 'contact_forms', icon: <IconAirplane />
}, {
  name: 'event-registration', title: 'event_registration', icon: <IconPencilPaper />
}, {
  name: 'payment-collection', title: 'payment_collection', icon: <IconTag />
}, {
  name: 'applications', title: 'applications', icon: <IconDocumentUser />
}, {
  name: 'lead-generation', title: 'lead_generation', icon: <IconUserPlus />
}, {
  name: 'online-orders-ecommerce', title: 'online_orders_e_commerce', icon: <IconCart />
}, {
  name: 'surveys-feedback', title: 'surveys_feedback', icon: <IconHappy />
}, {
  name: 'data-collection-workflow-automation', title: 'data_collection_workflow_automation', icon: <IconArrowCombine />
}, {
  name: 'project-management', title: 'project_management', icon: <IconEye />
}, {
  name: 'other', title: 'other', icon: <IconBalloon />
}]

export const industryCards = [{
  name: 'marketing-sales', title: 'marketing_sales', icon: <IconGlobe />
}, {
  name: 'human-resources', title: 'human_resources', icon: <IconUser />
}, {
  name: 'administrative', title: 'administrative', icon: <IconShapes />
}, {
  name: 'support', title: 'support', icon: <IconHeartHand />
}, {
  name: 'project-management', title: 'project_management', icon: <IconEye />
}, {
  name: 'engineering-web-development', title: 'engineering_web_developer', icon: <IconGear />
}, {
  name: 'owner-self-employed', title: 'owner_self_employed', icon: <IconFootprint />
}, {
  name: 'other', title: 'other', icon: <IconBalloon />
}]
