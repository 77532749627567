import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import { Button } from '@sm/wds-react'
import { IconPencilBox, IconTrash, IconWarning } from '@sm/wds-icons'
import { EditableField } from '../../../../components/EditableField'
import { Icon } from '../../../../components/Icon'
import { toID } from '../../../../utils/string'

export let LabelRow = (props) => {
  const {
    inDeleteMode,
    isDeletePending,
    inEditMode,
    isEditPending,
    editError,
    handleDeleteInit,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleEditInit,
    handleEditCancel,
    handleEditConfirm,
    label,
    t
  } = props

  return (
    <React.Fragment>
      <td
        className={classnames(
          { 'editing': inEditMode },
          { 'deleting': inDeleteMode },
          { 'has-error': editError }
        )}
      >
        {!inEditMode && label.name}
        {inEditMode &&
          <div className='edit-field-container'>
            <EditableField
              editing={true}
              pending={isEditPending}
              handleCancel={handleEditCancel}
              handleEdit={handleEditConfirm}
              value={label.name}
            />
            {editError &&
              <span className='error'>
                <IconWarning />
                {editError}
              </span>}
          </div>
        }
      </td>
      <td className={classnames({ 'deleting': inDeleteMode })}>
        <span>
          {inDeleteMode && label.relativeTaggedFormCount > 0 && <IconWarning />}
          {label.relativeTaggedFormCount}
        </span>
      </td>
      {!inDeleteMode &&
        <td className='edit-column'>
          <Icon
            isDisabled={inEditMode}
            handleClick={handleEditInit}
            id={toID(`edit-column-${label.name}`)}
          >
            <IconPencilBox />
          </Icon>
        </td>
      }
      {inDeleteMode &&
        <td className='delete-confirm-column confirming'>
          <Button
            size='sm'
            color='warning'
            onClick={handleDeleteConfirm}
            disabled={isDeletePending}
          >
            {t('DELETE')}
          </Button>
        </td>
      }
      {!inDeleteMode &&
        <td className='delete-column'>
          <Icon
            handleClick={handleDeleteInit}
            id={toID(`delete-column-${label.name}`)}
          >
            <IconTrash />
          </Icon>
        </td>
      }
      {inDeleteMode &&
        <td className='delete-cancel-column confirming'>
          <Button
            size='sm'
            variant='ghost'
            color='secondary'
            onClick={handleDeleteCancel}
            disabled={isDeletePending}
          >
            {t('CANCEL')}
          </Button>
        </td>
      }
    </React.Fragment>
  )
}

LabelRow.propTypes = {
  editError: PropTypes.bool,
  inEditMode: PropTypes.bool,
  isEditPending: PropTypes.bool,
  inDeleteMode: PropTypes.bool,
  isDeletePending: PropTypes.bool,
  handleDeleteInit: PropTypes.func,
  handleDeleteCancel: PropTypes.func,
  handleDeleteConfirm: PropTypes.func,
  handleEditInit: PropTypes.func,
  handleEditCancel: PropTypes.func,
  handleEditConfirm: PropTypes.func,
  label: PropTypes.object,
  t: PropTypes.func
}

LabelRow.defaultTypes = {
  inEditMode: false,
  inDeleteMode: false
}

export default withTranslation('sheets')(LabelRow)
