import './SystemNotifications.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Alert } from '@sm/wds-react'
import { IconTag } from '@sm/wds-icons'
import { SafeHTML } from '../../components/SafeHTML'
import { markSystemNotificationAsRead } from '../../actions/user'
import { USER_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


const TYPE_OVERRIDES = {
  alert: {
    color: 'warning'
  },
  offer: {
    color: 'upgrade',
    Icon: IconTag,
    showIcon: false // hide default icon so that we can add our own
  }
}

export const SystemNotifications = props => {
  const { dispatch, user } = props

  return (
    <div className='system-notifications'>
      {user.systemNotifications.map(notification => {
        const { Icon, ...overrides } = (TYPE_OVERRIDES[notification.type] || {})

        return (
          <Alert
            dismissible
            key={notification.id}
            color={[ 'success', 'info', 'warning', 'upgrade' ].includes(notification.type) ? notification.type : 'info'}
            onClose={() => { dispatch(markSystemNotificationAsRead(notification.id)) }}
            showIcon
            {...overrides}
          >
            {Icon && <span className='custom-icon'><Icon /></span>}
            <SafeHTML>{ notification.message }</SafeHTML>
          </Alert>
        )
      })}
    </div>
  )
}

SystemNotifications.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
}

const select = state => ({
  user: state.user,
})

export default connect(select)(SystemNotifications)
