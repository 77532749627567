import PropTypes from 'prop-types'

export const PLAN_FEATURES = {
  account: PropTypes.shape({
    entries: PropTypes.number,
    forms: PropTypes.number,
    reports: PropTypes.number,
    storage: PropTypes.number,
    users: PropTypes.number,
  }),
  classicForms: PropTypes.object, //todo: specify property types
}

export const PLAN = {
  billingInterval: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
}

export const USER = {
  id: PropTypes.number,
  adminAccess: PropTypes.bool,
  accountOwner: PropTypes.bool,
  email: PropTypes.string,
  name: PropTypes.string,
  plan: PropTypes.shape(PLAN),
  planFeatures: PropTypes.shape(PLAN_FEATURES),
  profile: PropTypes.number,
  permissions: PropTypes.shape({
    createFileFolders: PropTypes.bool,
    createFormLabels: PropTypes.shape({
      private: PropTypes.bool,
      public: PropTypes.bool,
    }),
    createForms: PropTypes.bool,
    createReports: PropTypes.bool,
    createThemes: PropTypes.bool,
    uploadAssets: PropTypes.bool,
  }),
  subdomain: PropTypes.string,
  timezone: PropTypes.number,
}
