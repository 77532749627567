import './DeleteForm.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import { Button, Col } from '@sm/wds-react'
import { IconWarning } from '@sm/wds-icons'
import { ConfirmModal } from '../ConfirmModal'
import { deleteForms } from '../../../actions/forms'
import { downloadAllEntries } from '../../../actions/form'
import { exportEntries } from '../../../actions/exports'
import { getActionPerformedEventDetails } from '../../../models/mixpanel'
import { mixpanel } from '../../../services/telemetry/mixpanel'
import { EXPORT_TYPES } from '../../../constants/exports'
import {
  FORMS_STATE_PROPS,
  SELECTIONS_STATE_PROPS
} from '../../../constants/propTypes/coreWebPropTypes'


export class DeleteForm extends React.Component {

  handleDelete = () => {
    const { dispatch, form, forms, selections, t } = this.props
    const selected = form.modalForm ? [ form.modalForm.id ] : selections.forms
    const message = (
      selected.length > 1 ? t('forms_deleted_toast') : t('form_deleted_toast')
    )

    dispatch(deleteForms(selected, message))

    const eventDetails = getActionPerformedEventDetails('Delete', {
      formNum: forms.forms.length,
      selectedFormNum: selections.forms.length,
    })
    mixpanel.track(eventDetails.event, eventDetails.properties)
    this.finish()
  }

  downloadEntries = () => {
    const { dispatch, form, forms, selections } = this.props
    const formToDownload = form.modalForm ? form.modalForm : forms.forms.find(f => f.id === selections.forms[0])
    dispatch(downloadAllEntries(formToDownload))
  }

  closeDeleteFormModal = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL_TYPE', data: 'delete-form-modal' })
  }

  downloadXlsEntries = () => {
    const { dispatch, form, forms , selections } = this.props

    const formToDownload = form.modalForm ? form.modalForm : forms.forms.find(f => f.id === selections.forms[0])

    dispatch(exportEntries(formToDownload, [], EXPORT_TYPES.ENTRIES_TO_XLS))
    this.closeDeleteFormModal()
  }

  finish = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  constructor(props) {
    super(props)
  }

  render() {
    const {
      t,
      form,
      forms,
      selections
    } = this.props

    const selected = form.modalForm ? [ form.modalForm.id ] : selections.forms
    const numForms = selected.length
    const hasSingleForm = numForms === 1

    const formName = forms.forms.find(f => f.id === selected[0])?.name
    const totalEntries = selected.reduce((acc, id) => {
      const formV1 = forms.forms.find(f => f.id === id)
      return acc + (formV1?.totalEntries || 0)
    }, 0)

    return (
      <ConfirmModal
        size={hasSingleForm ? 'sm' : 'lg'}
        name='delete-form-modal'
        className={classnames(
          'delete-form-modal',
          { 'delete-multiple': !hasSingleForm }
        )}
        header={(
          <span className='title-body'>
            <IconWarning />
            {hasSingleForm && t('delete_form_header', { formName })}
            {!hasSingleForm && t('delete_forms_header', { count: numForms })}
          </span>
        )}
        cancelButton={(
          <Button id='forms-delete-modal-cancel' variant='ghost' color='secondary' onClick={this.finish}>
            {t('CANCEL')}
          </Button>
        )}
        okayButton={(
          <Button
            color='warning'
            id='forms-delete-modal-save'
            onClick={this.handleDelete}
          >
            <span>
              {hasSingleForm ? t('DELETE_FORM') : t('DELETE_FORMS')}
            </span>
          </Button>
        )}
      >
        <div className='row'>
          <Col md={hasSingleForm ? 12 : 9} className='confirm-column'>
            {hasSingleForm ? (
              <p>
                <span className='bold'>
                  <Trans t={t} i18nKey='delete_form_permanently_warning' count={totalEntries}>
                    <span>All information from this form - including the form's&nbsp;</span>
                    <span className='warning'>
                      {'{{count}} entry'}
                    </span>
                    <span>&nbsp;- will be permanently deleted if you delete it.</span>
                  </Trans>
                </span>
                &nbsp;
                {t('delete_form_organization_warning')}
              </p>
            ) : (
              <p>
                <span className='bold'>
                  <Trans t={t} i18nKey='delete_forms_permanently_warning' count={totalEntries}>
                    <span>All information from these forms - including their&nbsp;</span>
                    <span className='warning'>
                      {'{{count}} entry'}
                    </span>
                    <span>&nbsp;- will be permanently deleted if you delete them.</span>
                  </Trans>
                </span>
                &nbsp;
                {t('delete_forms_organization_warning')}
              </p>
            )}

            <ul>
              <li>{t('delete_form_share_links_warning')}</li>
              <li>{t('delete_form_history_warning')}</li>
              <li>{t('delete_form_entry_data_warning')}</li>
            </ul>

            {hasSingleForm && (
              <>
                <p>
                  {t('delete_form_download_suggestion')}
                </p>
                <div>
                  <Button
                    className='csv-button'
                    onClick={this.downloadEntries}
                    size='sm'
                    variant='ghost'
                  >
                    {t('Download_CSV')}
                  </Button>
                  <Button
                    className='excel-button'
                    onClick={this.downloadXlsEntries}
                    size='sm'
                    variant='ghost'
                  >
                    {t('Download_Excel_Doc')}
                  </Button>
                </div>
              </>
            )}

            <p className='warning'>
              {t('delete_form_undone_warning')}
            </p>

          </Col>
          {!hasSingleForm && (
            <Col md={3} className='side-column'>
              <p>
                {t('delete_following_forms')}
              </p>
              <ul>
                {selected.map(id => {
                  const form = forms.forms.find(f => f.id === id)
                  return (
                    <li key={form.id}>{form.name}</li>
                  )
                })}
              </ul>
            </Col>
          )}
        </div>
      </ConfirmModal>
    )
  }
}

DeleteForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  forms: PropTypes.shape(FORMS_STATE_PROPS).isRequired,
  selections: PropTypes.shape(SELECTIONS_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  form: state.form,
  forms: state.forms,
  selections: state.selections,
})

DeleteForm = withTranslation([ 'modals', 'entries' ])(DeleteForm)
DeleteForm = connect(select)(DeleteForm)

export default DeleteForm
