import './App.sass'

import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'

import { InnerApp } from './InnerApp'
import { Loader } from '../../components/Loader'
import { ScrollToTop } from '../../components/ScrollToTop'

import { ConnectedFlagsProvider } from '../../services/settings/featureFlags'
import { i18n } from '../../services/i18n'
import history from '../../services/history'

export class App extends Component {

  render() {
    const { store } = this.props

    return (
      <Provider store={store}>
        <Suspense fallback={<Loader loading={true} fixed={true} />}>
          <I18nextProvider i18n={i18n}>
            <ConnectedRouter history={history}>
              <ScrollToTop>
                <ConnectedFlagsProvider>
                  <InnerApp />
                </ConnectedFlagsProvider>
              </ScrollToTop>
            </ConnectedRouter>
          </I18nextProvider>
        </Suspense>
      </Provider>
    )
  }
}

App.propTypes = {
  store: PropTypes.object
}

export default App

