const defaultState = {
  activeSheet: null,
  props: null
}

const sheet = (state=defaultState, { type, data }) => {

  switch (type) {
    case 'OPEN_SHEET':
      return {
        ...state,
        activeSheet: data
      }
    case 'CLOSE_SHEET':
      return {
        ...state,
        activeSheet: null,
        props: null
      }
    case 'SET_SHEET_PROPS':
      return {
        ...state,
        props: { ...data }
      }
    default:
      return state
  }

}

export default sheet
