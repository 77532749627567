import './AddForm.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Card } from '@sm/wds-react'
import { ConfirmModal } from '../ConfirmModal'
import { LegacyLink } from '../../../components/LegacyLink'
import formBuildImage from './images/wf_scratch.png'
import formGalleryImage from './images/wf_template.png'
import { LAUNCH_LEGACY_LOCATION_CREATE } from '../../../constants/mixpanel'


export let AddForm = props => {

  const { t } = props

  return (
    <ConfirmModal
      className='add-form-modal'
      size='md'
      name='add-form-modal'
      hasFooter={false}
      header={t('modals:add_form_header')}
    >
      <div className='card-container'>
        <div className='form-cards'>
          <LegacyLink
            createFormType='Scratch'
            id='form-builder'
            location={LAUNCH_LEGACY_LOCATION_CREATE}
            to='/build'
          >
            <Card>
              <div className='card-content'>
                <img
                  alt=''
                  className='form-build-img'
                  src={formBuildImage}
                />
                <div className='text'>
                  {t('modals:add_form_from_scratch')}
                </div>
              </div>
            </Card>
          </LegacyLink>
          <LegacyLink
            createFormType='Template'
            id='form-template'
            location={LAUNCH_LEGACY_LOCATION_CREATE}
            to='/gallery/templates'
          >
            <Card>
              <div className='card-content'>
                <img
                  alt=''
                  className='template-img'
                  src={formGalleryImage}
                />
                <div className='text'>
                  {t('modals:add_form_from_template')}
                </div>
              </div>
            </Card>
          </LegacyLink>
        </div>
      </div>
    </ConfirmModal>
  )
}

AddForm.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation([ 'global','modals' ])(AddForm)
