import './FilterExpressions.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FilterExpressionItem } from './FilterExpressionItem'
import { Button } from '@sm/wds-react'
import { IconPlusCircle } from '@sm/wds-icons'

import { OperatorChain } from './OperatorChain'

import { MAX_CONDITION_NUMBER, getOperatorChain } from '../../models/entries'

export class FilterExpressions extends React.PureComponent {
  render() {
    const {
      t,
      complete,
      canRemove,
      expressions,
      fieldSets,
      fieldList,
      hasPayment,
      handleApply,
      handleOperatorChange,
      handleConditionChange,
      handleAdd,
      handleRemove,
      userTimezone
    } = this.props
    const { conditions, operator } = expressions

    return (
      <div className='filter-expressions-list'>
        <div className='filter-expression-operator'>
          <OperatorChain
            chain={getOperatorChain(conditions)}
            operator={operator}
            handleChange={handleOperatorChange}
          />
        </div>
        <div className='filter-expression-items'>
          {conditions.map((condition) => (
            <FilterExpressionItem
              key={condition.id}
              condition={condition}
              hasPayment={hasPayment}
              handleApply={handleApply}
              handleChange={handleConditionChange}
              handleRemove={handleRemove}
              fieldSets={fieldSets}
              fieldList={fieldList}
              canRemove={canRemove}
              userTimezone={userTimezone}
            />
          ))}
          <div className='filter-expression-footer'>
            <Button
              disabled={!complete || conditions.length >= MAX_CONDITION_NUMBER}
              onClick={handleAdd}
            >
              <IconPlusCircle />
            </Button>
            {conditions.length >= MAX_CONDITION_NUMBER &&
              <span className='warning-message'>{t('max_condition_warning_message', { maxLimit: MAX_CONDITION_NUMBER })}</span>
            }
          </div>

        </div>
      </div>
    )
  }
}

FilterExpressions.propTypes = {
  t: PropTypes.func,
  complete: PropTypes.bool,
  canRemove: PropTypes.bool,
  expressions: PropTypes.object,
  fieldSets: PropTypes.array,
  fieldList: PropTypes.array,
  hasPayment: PropTypes.bool,
  handleApply: PropTypes.func,
  handleOperatorChange: PropTypes.func,
  handleConditionChange: PropTypes.func,
  handleAdd: PropTypes.func,
  handleRemove: PropTypes.func,
  userTimezone: PropTypes.number
}

export default withTranslation('entries')(FilterExpressions)
