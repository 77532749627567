import './PricingModal.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  Button,
  ModalHeader,
  ModalBody,
} from '@sm/wds-react'

import { Modal } from '../Modal'
import { LegacyLink } from '../../../components/LegacyLink'
import { PLAN_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class PricingModal extends React.Component {

  render() {
    const { t, plan: planState } = this.props
    const { plan } = planState
    const annualPlan = plan?.billingInterval === 12
    const freePlan = plan?.name === 'Free'

    const STARTER_MONTHLY_UPGRADE_PATH = 'signup/18/upgrade'
    const PROFESSIONAL_MONTHLY_UPGRADE_PATH = 'signup/19/upgrade'
    const ADVANCED_MONTHLY_UPGRADE_PATH = 'signup/20/upgrade'
    const ULTIMATE_MONTHLY_UPGRADE_PATH = 'signup/21/upgrade'
    const STARTER_ANNUAL_UPGRADE_PATH = 'signup/22/upgrade'
    const PROFESSIONAL_ANNUAL_UPGRADE_PATH = 'signup/23/upgrade'
    const ADVANCED_ANNUAL_UPGRADE_PATH = 'signup/24/upgrade'
    const ULTIMATE_ANNUAL_UPGRADE_PATH = 'signup/25/upgrade'

    return (
      <Modal size='md' className='pricing-modal'>
        <ModalHeader
          header={t('pricing_modal_header')}
        />

        <ModalBody>
          <p>{t('pricing_modal_description')}</p>
          <p>{t('pricing_modal_instruction')}</p>

          <div className='pricing-modal-cards'>
            <div className='pricing-modal-card starter'>
              <p className='title'>{t('starter')}</p>
              <p className='price'>{(annualPlan || freePlan) ?
                t('starter_price_monthly') : t('starter_price_billed_monthly')}
              </p>
              <p className='billing'>{(annualPlan || freePlan) && t('starter_billing_annually')}</p>
              <ul>
                <li>{t('starter_forms')}</li>
                <li>{t('starter_entries')}</li>
                <li>{t('and_more')}</li>
              </ul>
              <LegacyLink to={annualPlan || freePlan ? STARTER_ANNUAL_UPGRADE_PATH : STARTER_MONTHLY_UPGRADE_PATH}>
                <Button stretched size='sm'>{t('get_starter')}</Button>
              </LegacyLink>
            </div>

            <div className='pricing-modal-card professional'>
              <p className='title'>{t('professional')}</p>
              <p className='price'>{(annualPlan || freePlan) ?
                t('professional_price_monthly') : t('professional_price_billed_monthly')}
              </p>
              <p className='billing'>{(annualPlan || freePlan) && t('professional_billing_annually')}</p>
              <ul>
                <li>{t('professional_forms')}</li>
                <li>{t('professional_entries')}</li>
                <li>{t('and_more')}</li>
              </ul>
              <LegacyLink to={annualPlan || freePlan ? PROFESSIONAL_ANNUAL_UPGRADE_PATH : PROFESSIONAL_MONTHLY_UPGRADE_PATH}>
                <Button stretched size='sm'>{t('get_professional')}</Button>
              </LegacyLink>
            </div>

            <div className='pricing-modal-card advanced'>
              <p className='title'>{t('advanced')}</p>
              <p className='price'>{(annualPlan || freePlan) ?
                t('advanced_price_monthly') : t('advanced_price_billed_monthly')}
              </p>
              <p className='billing'>{(annualPlan || freePlan) && t('advanced_billing_annually')}</p>
              <ul>
                <li>{t('advanced_forms')}</li>
                <li>{t('advanced_entries')}</li>
                <li>{t('and_more')}</li>
              </ul>
              <LegacyLink to={annualPlan || freePlan ? ADVANCED_ANNUAL_UPGRADE_PATH : ADVANCED_MONTHLY_UPGRADE_PATH}>
                <Button stretched size='sm'>{t('get_advanced')}</Button>
              </LegacyLink>
            </div>

            <div className='pricing-modal-card ultimate'>
              <p className='title'>{t('ultimate')}</p>
              <p className='price'>{(annualPlan || freePlan) ?
                t('ultimate_price_monthly') : t('ultimate_price_billed_monthly')}
              </p>
              <p className='billing'>{(annualPlan || freePlan) && t('ultimate_billing_annually')}</p>
              <ul>
                <li>{t('ultimate_forms')}</li>
                <li>{t('ultimate_entries')}</li>
                <li>{t('and_more')}</li>
              </ul>
              <LegacyLink to={annualPlan || freePlan ? ULTIMATE_ANNUAL_UPGRADE_PATH : ULTIMATE_MONTHLY_UPGRADE_PATH}>
                <Button stretched size='sm'>{t('get_ultimate')}</Button>
              </LegacyLink>
            </div>
          </div>

        </ModalBody>
      </Modal>
    )
  }
}

PricingModal.propTypes = {
  plan: PropTypes.shape(PLAN_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  plan: state.plan,
})

PricingModal = connect(select)(PricingModal)
PricingModal = withTranslation('modals')(PricingModal)

export default PricingModal
