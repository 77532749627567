import { getSetting, setSetting } from '../services/settings/settings'
import {
  getDefaultColumns,
  getDefaultSelectedColumns,
  getUserFieldColumns,
  isValidColumn,
  updateSelectedColumns
} from '../models/entries'


const defaultState = {
  count: 0,
  defaultColumns: [],
  entries: [],
  emails: [],
  fieldColumns: [],
  fieldSets: [],
  isColumnSelectorOpen: false,
  isDownloadAllDisabled: false,
  isLoading: true,
  isEntriesSelectAllChecked: false,
  queryParams: {
    filterKey: 'all',
    sortKey: 'id',
    sortDirection: 'desc',
    pageSize: 25,
    page: 1,
    query: '',
    expression: {
      lastId: -1,
      operator: 'and',
      conditions: []
    }
  },
  selectedColumns: [],
  stats: {
    totalEntries: 0,
    entriesToday: 0
  },
  totalCount: 0
}

const changeQueryParam = (params, newParams) => {
  return {
    ...params,
    ...newParams
  }
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_MODAL_ENTRY':
      return { ...state, modalEntry: data }
    case 'SET_ENTRIES_LOADING':
      return { ...state, isLoading: data }
    case 'OPEN_CLOSE_COLUMN_SELECTOR':
      return { ...state, isColumnSelectorOpen: data }
    case 'SET_ENTRIES_SELECT_ALL_CHECKED':
      return { ...state, isEntriesSelectAllChecked: data }
    case 'CHANGE_ENTRIES_QUERY_PARAMS':
      if (!data) {
        return { ...state, queryParams: defaultState.queryParams }
      }
      return { ...state, queryParams: changeQueryParam(state.queryParams, data) }
    case 'SET_ENTRIES_STATS':
      return {
        ...state,
        stats: data
      }
    case 'SET_ENTRY_FIELDSETS':
      return {
        ...state,
        fieldSets: [ ...data.fieldSets ]
      }
    case 'SET_ENTRIES_COLUMNS': {
      const { fieldSets, entries, stats } = state
      const { columns = getSetting('entry', data.formId)('columns'), formId } = data

      const hasPayment = !!stats?.currentCurrency
      const hasSignature = !!entries?.find(e => !!e.signature)
      const defaultColumns = getDefaultColumns({ hasPayment, hasSignature })
      const fieldColumns = getUserFieldColumns(defaultColumns, fieldSets)

      let selectedColumns = columns ?
        updateSelectedColumns(columns, fieldSets).filter(c => isValidColumn(c)) :
        getDefaultSelectedColumns(defaultColumns, fieldColumns)

      setSetting('entry', formId)('columns', selectedColumns)

      return {
        ...state,
        defaultColumns,
        fieldColumns,
        selectedColumns
      }
    }
    case 'SET_ENTRIES': {
      const { entries=[], count=0, totalCount=0 } = data
      return {
        ...state,
        count: count,
        entries: entries,
        totalCount: totalCount,
        isLoading: false
      }
    }
    case 'SET_ENTRY_EMAILS':
      return {
        ...state,
        emails: data
      }
    case 'SET_DOWNLOAD_ALL_DISABILITY':
      return {
        ...state,
        isDownloadAllDisabled: data
      }
    default:
      return state
  }
}

