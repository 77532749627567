import './ManageLabels.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal'

import { Align, Button, Sheet } from '@sm/wds-react'
import { IconArrowDown, IconArrowUp, IconWarning } from '@sm/wds-icons'
import { Icon } from '../../../components/Icon'
import { Search } from '../../../components/Search'
import { Loader } from '../../../components/Loader'
import { Dummy } from '../../../components/Dummy'
import { EditableField } from '../../../components/EditableField'
import { LabelRow } from './LabelRow'
import { apiError } from '../../../actions/api'
import { createLabel, deleteLabel, fetchAllLabels, updateLabel } from '../../../actions/labels'
import { isNameEmpty, isNameTooLong, MAX_LENGTH_LABEL_NAME } from '../../../models/labels'
import { toID } from '../../../utils/string'
import { API_STATE_PROPS, LABELS_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class ManageLabels extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showCreateField: false,
      editingLabelId: null,
      deletingLabelId: null
    }
  }

  componentDidMount() {
    const { labels, dispatch } = this.props
    dispatch(fetchAllLabels(labels.managerQueryParams, 'SET_MANAGER_LABELS'))
  }

  componentDidUpdate(prevProps) {
    const { api, labels, dispatch } = this.props
    const { isCreating, isDeleting, isEditing, managerQueryParams } = labels

    if (prevProps.labels.isCreating && !isCreating && !api.errors['create-label']) {
      this.handleCreateCancel()
    }
    if (prevProps.labels.isEditing && !isEditing && !api.errors['edit-label']) {
      this.setState({ editingLabelId: null })
    }
    if (prevProps.labels.isDeleting && !isDeleting && !api.errors['delete-label']) {
      this.setState({ deletingLabelId: null })
    }
    if (!isEqual(prevProps.labels.managerQueryParams, managerQueryParams)) {
      dispatch(fetchAllLabels(labels.managerQueryParams, 'SET_MANAGER_LABELS'))
    }
  }

  handleSearch = query => {
    const { dispatch } = this.props
    dispatch({ type: 'CHANGE_LABELS_MANAGER_QUERY_PARAMS', data: { query }})
  }

  handleSort = (sortKey, sortDirection) => {
    const { dispatch } = this.props
    dispatch({ type: 'CHANGE_LABELS_MANAGER_QUERY_PARAMS', data: { sortKey, sortDirection }})
  }

  handleCreateInit = () => {
    this.setState({
      showCreateField: true,
      editingLabelId: null,
      deletingLabelId: null
    })
  }

  handleCreateCancel = () => {
    const { dispatch } = this.props
    dispatch(apiError('create-label', false))
    this.setState({ showCreateField: false })
  }

  handleCreateConfirm = name => {
    const { dispatch } = this.props
    if (isNameEmpty(name)) {
      dispatch(apiError('create-label', 'name_empty'))
    } else if (isNameTooLong(name)) {
      dispatch(apiError('create-label', 'name_too_long'))
    } else {
      dispatch(createLabel(name))
    }
  }

  handleDeleteInit = labelId => {
    this.setState({
      deletingLabelId: labelId,
      showCreateField: false,
      editingLabelId: null
    })
  }

  handleDeleteCancel = () => {
    const { dispatch } = this.props
    dispatch(apiError('delete-label', false))
    this.setState({ deletingLabelId: null })
  }

  handleDeleteConfirm = labelId => {
    const { dispatch } = this.props
    dispatch(deleteLabel(labelId))
  }

  handleEditInit = labelId => {
    this.setState({
      editingLabelId: labelId,
      showCreateField: false,
      deletingLabelId: null
    })
  }

  handleEditCancel = () => {
    const { dispatch } = this.props
    dispatch(apiError('edit-label', false))
    this.setState({ editingLabelId: null })
  }

  handleEditConfirm = (labelId, name) => {
    const { dispatch } = this.props
    if (isNameEmpty(name)) {
      dispatch(apiError('edit-label', 'name_empty'))
    } else if (isNameTooLong(name)) {
      dispatch(apiError('edit-label', 'name_too_long'))
    } else {
      dispatch(updateLabel(labelId, name))
    }
  }

  handleOnClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_SHEET' })
    dispatch(apiError('create-label', false))
  }

  render() {
    const { api, labels, t } = this.props
    const { sortKey, sortDirection } = labels.managerQueryParams
    const { showCreateField, editingLabelId, deletingLabelId } = this.state

    const createError = api.errors['create-label']
    const editError = api.errors['edit-label']
    return (
      <Sheet
        show={true}
        onClose={this.handleOnClose}
        size='md'
      >
        <div className='manage-labels-sheet'>

          <div className='sheet-header'>
            <span className='title'>{t('Manage_Labels_Title')}</span>
            <Search
              handleSearch={this.handleSearch}
              placeholder={t('Search')}
              query={labels.managerQueryParams.query}
              id='label-search-bar'
            />
          </div>

          <div className='sheet-body'>
            <Loader
              style={{ height: 50 * 8 }}
              dummy={<Dummy rows={8} />}
              loading={labels.isLoading}
            >
              {api.errors['all-labels'] && !showCreateField &&
                <span className='error-container'>{t('fetch_all_labels_error')}</span>
              }
              {labels.managerLabels && labels.managerLabels.length  === 0 && !showCreateField &&
                <span className='no-label-container'>{t('no_label_available')}</span>
              }
              {labels.managerLabels && labels.managerLabels.length > 0 &&
                <div className='table-container'>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className='table-header'>
                            <span>{t('Name')}</span>
                            <Icon handleClick={() => this.handleSort('name', sortDirection === 'asc' ? 'desc' : 'asc')}>
                              {sortDirection === 'asc' && <IconArrowUp color={(sortKey === 'name' && 'primary') || 'light-muted'} size='md' id='name-arrow-up' />}
                              {sortDirection === 'desc' && <IconArrowDown color={(sortKey === 'name' && 'primary') || 'light-muted'} size='md' id='name-arrow-down' />}
                            </Icon>
                          </div>
                        </th>
                        <th>
                          <div className='table-header'>
                            <span>{t('Forms')}</span>
                            <Icon handleClick={() => this.handleSort('relative_tagged_form_count', sortDirection === 'asc' ? 'desc' : 'asc')}>
                              {sortDirection === 'asc' && <IconArrowUp color={(sortKey === 'relative_tagged_form_count' && 'primary') || 'light-muted'} size='md' id='forms-arrow-up' />}
                              {sortDirection === 'desc' && <IconArrowDown color={(sortKey === 'relative_tagged_form_count' && 'primary') || 'light-muted'} size='md' id='forms-arrow-down' />}
                            </Icon>
                          </div>
                        </th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {labels.managerLabels.map(label => (
                        <tr key={label.id} id={toID(`label-${label.name}`)}>
                          <LabelRow
                            editError={editError && t(`${editError}_error`, { maxLength: MAX_LENGTH_LABEL_NAME })}
                            inEditMode={label.id === editingLabelId}
                            isEditPending={labels.isEditing}
                            inDeleteMode={label.id === deletingLabelId}
                            isDeletePending={labels.isDeleting}
                            handleDeleteInit={() => this.handleDeleteInit(label.id)}
                            handleDeleteCancel={this.handleDeleteCancel}
                            handleDeleteConfirm={() => this.handleDeleteConfirm (label.id)}
                            handleEditInit={() => this.handleEditInit(label.id)}
                            handleEditCancel={this.handleEditCancel}
                            handleEditConfirm={name => this.handleEditConfirm(label.id, name)}
                            label={label}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }
              <div className={`create-label-container${createError ? ' has-error': ''}`}>
                {showCreateField &&
                  <EditableField
                    editing={true}
                    pending={labels.isCreating}
                    handleCancel={this.handleCreateCancel}
                    handleEdit={this.handleCreateConfirm}
                    value=''
                  />
                }
                {createError &&
                <span className='error'>
                  <IconWarning />
                  {t(`${createError}_error`, { maxLength: MAX_LENGTH_LABEL_NAME })}
                </span>}
              </div>
            </Loader>
          </div>

          <div className='sheet-footer'>
            <Align placement='left'>
              <Button
                size='md'
                variant='ghost'
                onClick={this.handleCreateInit}
                disabled={showCreateField}
                id='new-label-button'
              >
                {t('NEW_LABEL')}
              </Button>
            </Align>
            <Align placement='right'>
              <Button
                size='md'
                onClick={this.handleOnClose}
                id='done-button'
              >
                {t('DONE')}
              </Button>
            </Align>
          </div>

        </div>
      </Sheet>
    )
  }
}

ManageLabels.propTypes = {
  api: PropTypes.shape(API_STATE_PROPS).isRequired,
  dispatch: PropTypes.func.isRequired,
  labels: PropTypes.shape(LABELS_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  api: state.api,
  labels: state.labels,
})

ManageLabels = withTranslation('sheets')(ManageLabels)
ManageLabels = connect(select)(ManageLabels)

export default ManageLabels
