import { api, getTableQueryParams } from '../services/api'
import { apiError } from './api'


export const fetchLabels = (queryObj={}) => {
  const { sortKey = 'name', sortDirection = 'asc', page = 1, pageSize = 100, labelIds } = queryObj

  let params = {
    sortBy: sortKey,
    sortDirection,
    page,
    pageSize,
  }

  if (labelIds && labelIds.length > 0) {
    params = { ...params, labelIds }
  }

  return dispatch => {
    dispatch(apiError('label-list', false))

    api.get(`/form_labels?${getTableQueryParams(params)}`)
      .then(({ data }) => {
        dispatch({ type: 'SET_REMAINING_LABELS', data: data.formLabels })
      })
      .catch(() => {
        dispatch(apiError('label-list', true))
      })
  }
}

export const fetchAllLabels = (queryObj={}, actionType='SET_LABELS') => {
  const { sortKey = 'name', sortDirection = 'asc', page = 1, pageSize = 100, query = '' } = queryObj

  const conditions = []

  if (query) {
    conditions.push({
      key: 'name',
      value: query,
      op: 'like'
    })
  }

  const params = {
    conditions,
    sortBy: sortKey,
    sortDirection,
    page,
    pageSize,
  }

  return (dispatch, getState) => {
    const { labels } = getState()
    dispatch({ type: 'SET_FETCH_LABELS_LOADING', data: true })
    dispatch(apiError('all-labels', false))
    api.get(`/form_labels?${getTableQueryParams(params)}`)
      .then(({ data }) => {
        dispatch({ type: actionType, data: data.formLabels })
        if (actionType === 'SET_LABELS' && labels.filterQueryParams.labelIds.length === 0) {
          dispatch({ type: 'SET_REMAINING_LABELS', data: data.formLabels })
        }
      })
      .catch(() => {
        dispatch(apiError('all-labels', true))
        dispatch({ type: 'SET_FETCH_LABELS_LOADING', data: false })
      })
  }
}

export const createLabel = name => {
  return (dispatch, getState) => {
    const { labels } = getState()
    dispatch({ type: 'SET_CREATE_LABEL_LOADING', data: true })
    dispatch(apiError('create-label', false))
    api.post('/form_labels', { name })
      .then(() => {
        dispatch(fetchAllLabels(labels.queryParams))
        dispatch(fetchAllLabels(labels.dialogueQueryParams, 'SET_DIALOGUE_LABELS'))
        dispatch(fetchAllLabels(labels.managerQueryParams, 'SET_MANAGER_LABELS'))
        dispatch(fetchLabels(labels.filterQueryParams))
        dispatch({ type: 'SET_CREATE_LABEL_LOADING', data: false })
      })
      .catch(({ response }) => {
        const error = response && response.data && response.data.errors && response.data.errors[0]
        const errorKey = error ? error.issue_code_description.toLowerCase().split(' ').join('_') : 'create_generic'
        dispatch(apiError('create-label', errorKey))
        dispatch({ type: 'SET_CREATE_LABEL_LOADING', data: false })
      })
  }
}

export const updateLabel = (labelId, name) => {
  return (dispatch) => {
    dispatch({ type: 'SET_EDIT_LABEL_LOADING', data: true })
    dispatch(apiError('edit-label', false))
    api.patch(`/form_labels/${labelId}`, { name })
      .then(({ data }) => {
        dispatch({ type: 'UPDATE_LABEL', data })
        dispatch({ type: 'SET_EDIT_LABEL_LOADING', data: false })
      })
      .catch(({ response }) => {
        const error = response && response.data && response.data.errors && response.data.errors[0]
        const errorKey = error ? error.issue_code_description.toLowerCase().split(' ').join('_') : 'edit_generic'
        dispatch(apiError('edit-label', errorKey))
        dispatch({ type: 'SET_EDIT_LABEL_LOADING', data: false })
      })
  }
}

export const deleteLabel = labelId => {
  return (dispatch, getState) => {
    const { labels } = getState()
    dispatch({ type: 'SET_DELETE_LABEL_LOADING', data: true })
    dispatch(apiError('delete-label', false))
    api.delete(`/form_labels/${labelId}`)
      .then(() => {
        const newLabelIds = labels.filterQueryParams.labelIds.filter(id => id !== labelId)
        dispatch({ type: 'SET_SELECTED_LABELS', data: labels.selectedLabels.filter(label => label.id !== labelId) })
        dispatch({ type: 'CHANGE_LABELS_FILTER_QUERY_PARAMS', data: { labelIds: newLabelIds }})
        dispatch({ type: 'CHANGE_FORMS_QUERY_PARAMS', data: { labelIds: newLabelIds }})
        dispatch(fetchAllLabels(labels.queryParams))
        dispatch(fetchAllLabels(labels.dialogueQueryParams, 'SET_DIALOGUE_LABELS'))
        dispatch(fetchAllLabels(labels.managerQueryParams, 'SET_MANAGER_LABELS'))
        dispatch(fetchLabels(labels.filterQueryParams))
        dispatch({ type: 'SET_DELETE_LABEL_LOADING', data: false })
      })
      .catch(() => {
        dispatch(apiError('delete-label', true))
        dispatch({ type: 'SET_DELETE_LABEL_LOADING', data: false })
      })
  }
}
