import './Search.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Input } from '@sm/wds-react'
import { IconSearch, IconX } from '@sm/wds-icons'
import { Icon } from '../Icon'

import { keyPressed } from '../../utils/a11y'

export class Search extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      query: props.query || ''
    }
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props

    if (prevProps.query !== query) {
      this.setState({ query })
    }
  }

  render() {
    const { className, handleSearch, placeholder, id } = this.props
    const { query } = this.state

    const classNames = classnames(
      'search',
      className,
    )

    return (
      <span
        role='search'
        aria-label={placeholder}
        className={classNames}
      >
        <Input
          inputRef={input => { this.input = input }}
          placeholder={placeholder}
          onChange={e => this.setState({ query: e.target.value })}
          onKeyPress={keyPressed('Enter', () => handleSearch(query))}
          value={query}
          id={id}
        />

        <span className='search-actions'>
          {query &&
            <Icon
              id='search-query-delete'
              clickable
              handleClick={e => {
                e.preventDefault()
                this.setState({ query: '' })
                handleSearch('')
              }}
            >
              <IconX />
            </Icon>
          }
          <Icon
            id='search-query-icon'
            clickable
            handleClick={e => {
              e.preventDefault()
              handleSearch(query)
            }}
          >
            <IconSearch />
          </Icon>
        </span>
      </span>
    )
  }
}

Search.propTypes = {
  className: PropTypes.string,
  handleSearch: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  query: PropTypes.string
}

Search.defaultProps = {
  className: '',
  query: ''
}

export default Search
