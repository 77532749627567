import PropTypes from 'prop-types'
import { PLAN, PLAN_FEATURES } from './user'


/* ENUM PROPS */

export const EMBED_TAB_PROP = [ 'javascript', 'iframe', 'wordpress', 'download' ]
export const FILE_LOCATION_PROP = [ 'asset', 'respondent' ]
export const FILE_TYPE_PROP = [ 'folder', 'file' ]
export const POPOUT_SOURCE_PROP = [ 'users', 'forms', 'entries', 'general', 'storage' ]
export const SIZE_PROP = [ 'sm', 'md', 'lg', 'xl' ]
export const SORT_DIRECTION_PROP = [ 'asc', 'desc' ]

/* DATA MODEL PROPS */

export const BREADCRUMB_PROPS = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export const COLUMN_LABEL_PROPS = {
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  label: PropTypes.string.isRequired
}

export const PAYMENT_PROPS = {
  status:PropTypes.string.isRequired,
  purchaseTotal: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  transactionId: PropTypes.string,
  merchantType: PropTypes.string.isRequired
}

export const SIGNATURE_PROPS = {
  state: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired
}

export const ENTRY_PROPS = {
  id: PropTypes.number.isRequired,
  dateCreated: PropTypes.string.isRequired,
  ip: PropTypes.string,
  payment: PropTypes.shape(PAYMENT_PROPS),
  signature: PropTypes.shape(SIGNATURE_PROPS)
}

export const FILE_ROW_PROPS = {
  dateCreated: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  link: PropTypes.string,
  location: PropTypes.oneOf(FILE_LOCATION_PROP),
  filename: PropTypes.string.isRequired,
  size: PropTypes.number,
  type: PropTypes.oneOf(FILE_TYPE_PROP).isRequired
}

export const FORM_PROPS = {
  dateCreated: PropTypes.string,
  dateUpdated: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  entriesToday: PropTypes.number.isRequired,
  formLabelIds: PropTypes.arrayOf(PropTypes.number),
  hash: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isNamed: PropTypes.bool,
  isPublic: PropTypes.bool.isRequired,
  maxUploadSize: PropTypes.number,
  name: PropTypes.string.isRequired,
  offsetHeight: PropTypes.number,
  password: PropTypes.string,
  themeId: PropTypes.number,
  title: PropTypes.string,
  totalEntries: PropTypes.number.isRequired,
  totalFields: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
}

export const LABEL_PROPS = {
  dateCreated: PropTypes.string,
  dateUpdated: PropTypes.string,
  editable: PropTypes.bool,
  id: PropTypes.number,
  isPublic: PropTypes.bool,
  name: PropTypes.string,
  relativeTaggedFormCount: PropTypes.number
}



export const ROOT_FILE_TABLE_PROPS = {
  depthStack: PropTypes.array.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  rootSize: PropTypes.number.isRequired,
  selectedFile: PropTypes.object
}

/* REDUX STATE PROPS */

const QUERY_PARAMS_PROPS = {
  filterKey: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  query: PropTypes.string,
  sortDirection: PropTypes.oneOf(SORT_DIRECTION_PROP),
  sortKey: PropTypes.string,
}

export const API_STATE_PROPS = {
  errors: PropTypes.object
}

export const APP_STATE_PROPS = {
  authenticationFailed: PropTypes.bool,
  firstTimeLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  loadingOnLogin: PropTypes.bool,
}

export const EXPERIMENTS_STATE_PROPS = {
  isLoading: PropTypes.bool,
  experiments: PropTypes.object,
}

export const FLAGS_STATE_PROPS = {
  configs: PropTypes.shape({
    apiUrl: PropTypes.string,
    autoSaveInterval: PropTypes.number,
    debugMode: PropTypes.bool,
    domain: PropTypes.string,
    errorMode: PropTypes.bool,
    legacyUrl: PropTypes.string,
    mixpanel: PropTypes.bool,
    prodMode: PropTypes.bool,
    protocol: PropTypes.string,
    submissionApiUrl: PropTypes.string
  }),
  isEnabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  selectedTab: PropTypes.string
}

export const FORMS_STATE_PROPS = {
  isLoading: PropTypes.bool,
  forms: PropTypes.arrayOf(PropTypes.shape(FORM_PROPS)),
  queryParams: PropTypes.shape(QUERY_PARAMS_PROPS),
}

export const LABELS_STATE_PROPS = {
  dialogueLabels: PropTypes.arrayOf(PropTypes.shape(LABEL_PROPS)),
  dialogueQueryParams: PropTypes.shape({
    query: PropTypes.string,
    ...QUERY_PARAMS_PROPS
  }),
  filterQueryParams: PropTypes.shape({
    labelIds: PropTypes.arrayOf(PropTypes.number),
    ...QUERY_PARAMS_PROPS
  }),
  isCreating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.shape(LABEL_PROPS)),
  managerLabels: PropTypes.array,
  managerQueryParams: PropTypes.shape({
    query: PropTypes.string,
    ...QUERY_PARAMS_PROPS
  }),
  remainingLabels: PropTypes.arrayOf(PropTypes.shape(LABEL_PROPS)),
  selectedLabels: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.number, PropTypes.object ])),
  showMultiselect: PropTypes.bool
}

export const MODAL_STATE_PROPS = {
  activeModal: PropTypes.string,
  props: PropTypes.object
}

export const PLAN_STATE_PROPS = {
  isLoading: PropTypes.bool,
  plan: PropTypes.shape(PLAN),
  planFeatures: PropTypes.shape(PLAN_FEATURES),
  planWarningDismissed: PropTypes.bool,
  stats: PropTypes.shape({
    entries: PropTypes.number,
    forms: PropTypes.number,
    reports: PropTypes.number,
    respondents: PropTypes.number,
    uploads: PropTypes.number,
    users: PropTypes.number,
  }),
  storageWarningDismissed: PropTypes.bool,
}

export const SELECTIONS_STATE_PROPS = {
  entries: PropTypes.array,
  files: PropTypes.array,
  forms: PropTypes.array,
}

export const USER_STATE_PROPS = {
  adminAccess: PropTypes.bool,
  accountOwner: PropTypes.bool,
  email: PropTypes.string,
  optedOutOfBeta: PropTypes.bool,
  profile: PropTypes.number,
  permissions: PropTypes.shape({
    createFileFolders: PropTypes.bool,
    createFormLabels: PropTypes.shape({
      private: PropTypes.bool,
      public: PropTypes.bool
    }),
    createForms: PropTypes.bool,
    createReports: PropTypes.bool,
    createThemes: PropTypes.bool,
    uploadAssets: PropTypes.bool,
  }),
  subdomain: PropTypes.string,
  systemNotifications: PropTypes.array,
}
