import './LegacyRedirect.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Loader } from '../../components/Loader'
import { getJwt, deauthenticate, isJwtExpired, reauthenticate } from '../../services/api'
import { getQueryParams } from '../../utils/browser'
import { FLAGS_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


export class LegacyRedirect extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch({ type: 'SET_LEGACY_REDIRECT_LOADING', data: true })

    if (this.form) {
      this.form.submit()
    }
  }

  renderLoader(loading) {
    return (
      <div className='legacy-redirect'>
        <Loader
          fixed
          loading={loading}
        >
          <div />
        </Loader>
      </div>
    )
  }

  render() {
    const { legacy, url, showLoader, subdomain, flags } = this.props
    const location = url || getQueryParams().url

    const legacyUrl = flags.configs.legacyUrl
    const protocol = flags.configs.protocol
    const jwt = getJwt()

    if (url === '/logout') {
      deauthenticate()
    } else {
      if (!jwt || isJwtExpired()) {
        reauthenticate()
        return this.renderLoader(legacy.isRedirectLoading)
      }
    }

    if (!jwt) return null

    return (
      <div>
        <div className='hidden'>
          <form
            ref={form => this.form = form}
            method='POST'
            action={`${protocol}://${subdomain}.${legacyUrl}/transfer/`}
          >
            <input type='text' value={jwt.token} name='token' readOnly />
            <input type='text' value={location} name='location' readOnly />
          </form>
        </div>

        {showLoader && this.renderLoader(legacy.isRedirectLoading)}
      </div>
    )
  }
}

LegacyRedirect.propTypes = {
  dispatch: PropTypes.func.isRequired,
  legacy: PropTypes.object.isRequired,
  flags: PropTypes.shape(FLAGS_STATE_PROPS).isRequired,
  url: PropTypes.string,
  showLoader: PropTypes.bool,
  subdomain: PropTypes.string,
}

LegacyRedirect.defaultProps = {
  showLoader: true,
  subdomain: 'secure',
  url: '',
}

const select = state => ({
  legacy: state.legacy,
  flags: state.flags,
})

LegacyRedirect = connect(select)(LegacyRedirect)
LegacyRedirect = withTranslation('global')(LegacyRedirect)

export default LegacyRedirect
