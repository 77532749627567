const defaultState = {
  isRedirectLoading: false,
  didRedirectFail: false,
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_LEGACY_REDIRECT_LOADING':
      return { ...state, isRedirectLoading: data }
    case 'SET_LEGACY_REDIRECT_FAILED':
      return { ...state, didRedirectFail: data }
    default:
      return state
  }

}
