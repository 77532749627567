import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'

import bbbImage from './images/logo-bbb.png'

const SecurityBadges = props => {
  const { t } = props

  return (
    <ul className='security-badges'>
      <li>
        <a
          href='https://www.bbb.org/greater-san-francisco/business-reviews/market-survey-companies/surveymonkey-in-san-mateo-ca-876628'
          target='_blank'
          rel='noopener noreferrer'
          id='footer-bbb-link'
        >
          <img
            src={bbbImage}
            alt={t('bbb_logo_alt')}
            title={t('bbb_logo_title')}
          />
        </a>
      </li>
      <li>
        <a
          rel='noopener noreferrer'
          target='_blank'
          title='View TrustedSite Certification'
          href='https://www.trustedsite.com/verify?host=wufoo.com'
        >
          <img
            border='0'
            height='50'
            width='120'
            src='https://cdn.ywxi.net/meter/wufoo.com/202.svg'
            onContextMenu={() => {
              alert(i18n.t('footer:trustedsite_notice'))
              return false
            }}
          />
        </a>
      </li>
    </ul>
  )
}

SecurityBadges.propTypes = {
  t: PropTypes.func
}

export default withTranslation('footer')(SecurityBadges)
