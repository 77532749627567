const defaultState = {
  errors: {
    alias: null
  }
}

export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_API_ERROR': {
      if (state.errors[data.type] !== data.value) {
        const errors = { ...state.errors }
        errors[data.type] = data.value
        return { ...state, errors }
      } if ('alias' in data) {
        return {
          ...state,
          errors: {
            ...state.errors,
            ...data
          }
        }
      }
      return state
    }
    default: {
      return state
    }
  }

}
