import './FormLabelModal.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import { toID } from '../../../utils/string'

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Align,
  Button,
  Checkbox
} from '@sm/wds-react'

import { IconArrowDown,
  IconArrowUp,
  IconGear,
  IconWarning
} from '@sm/wds-icons'
import isEqual from 'lodash.isequal'
import union from 'lodash.union'

import { Dummy } from '../../../components/Dummy'
import { EditableField } from '../../../components/EditableField'
import { Icon } from '../../../components/Icon'
import { Loader } from '../../../components/Loader'
import { Modal } from '../Modal'
import { Search } from '../../../components/Search'
import { apiError } from '../../../actions/api'
import { blurClick } from '../../../utils/a11y'
import { fetchAllLabels, createLabel } from '../../../actions/labels'
import { getActionPerformedEventDetails } from '../../../models/mixpanel'
import { hasPermission } from '../../../models/permissions'
import { isNameEmpty, isNameTooLong, MAX_LENGTH_LABEL_NAME } from '../../../models/labels'
import { mixpanel } from '../../../services/telemetry/mixpanel'
import { updateFormLabels } from '../../../actions/forms'
import {
  API_STATE_PROPS,
  FORMS_STATE_PROPS,
  LABELS_STATE_PROPS,
  SELECTIONS_STATE_PROPS,
  USER_STATE_PROPS
} from '../../../constants/propTypes/coreWebPropTypes'
import { CREATE_FORM_LABELS_PERMISSION } from '../../../constants/permissions'


export class FormLabelModal extends React.Component {

  constructor(props) {
    super(props)
    this.labelRef = React.createRef()
    props.dispatch(fetchAllLabels(props.labels.dialogueQueryParams, 'SET_DIALOGUE_LABELS'))
    this.state = {
      isCreatingLabel: false,
      labelsWithStatus: this.getLabelsWithStatus(),
      newLabelName: ''
    }
  }

  componentDidUpdate(prevProps) {
    const { api, labels, dispatch } = this.props
    const { isCreating, dialogueQueryParams } = labels
    const { labelsWithStatus, newLabelName } = this.state

    if (prevProps.labels.isCreating && !isCreating && !api.errors['create-label']) {
      this.handleNewLabelCancel()
    }
    if (!isEqual(prevProps.labels.dialogueQueryParams, dialogueQueryParams)) {
      dispatch(fetchAllLabels(labels.dialogueQueryParams, 'SET_DIALOGUE_LABELS'))
    }
    if (!isEqual(labels.dialogueLabels, prevProps.labels.dialogueLabels)) {
      this.setState({ labelsWithStatus: this.getLabelsWithStatus(labelsWithStatus) })
    }
    if (newLabelName) {
      const newLabelObject = labelsWithStatus.find(l => l.name === newLabelName)
      if (newLabelObject) {
        this.setState({ newLabelName: '' })
        this.handleCheckboxChange(newLabelObject)
        this.handleScrollIntoView()
      }
    }
  }

  handleSearch = query => {
    const { dispatch } = this.props
    dispatch({ type: 'CHANGE_LABELS_DIALOGUE_QUERY_PARAMS', data: { query }})
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
    dispatch(apiError('create-label', false))
  }

  handleSort = (sortKey, sortDirection) => {
    const { dispatch } = this.props
    dispatch({ type: 'CHANGE_LABELS_DIALOGUE_QUERY_PARAMS', data: { sortKey, sortDirection }})
  }

  handleNewLabel = () => {
    this.setState({ isCreatingLabel: true })
  }

  handleNewLabelRequest = labelName => {
    const { dispatch, labels } = this.props
    if (isNameEmpty(labelName)) {
      dispatch(apiError('create-label', 'name_empty'))
    } else if (isNameTooLong(labelName)) {
      dispatch(apiError('create-label', 'name_too_long'))
    } else {
      dispatch(createLabel(labelName, labels.dialogueQueryParams))
      dispatch(apiError('create-label', false))
      this.setState({ newLabelName: labelName })
    }
  }

  handleScrollIntoView = () => {
    if (this.labelRef) {
      this.labelRef.current && this.labelRef.current.scrollIntoView()
    }
  }

  handleNewLabelCancel = () => {
    const { dispatch } = this.props
    dispatch(apiError('create-label', false))
    this.setState({ isCreatingLabel: false })
  }

  handleCheckboxChange = label => {
    const { labelsWithStatus } = this.state
    const newLabel = { ...label }
    const indeterminateLabel = label.checkedStatus === 'indeterminate'
    const unselectedLabel = label.checkedStatus === 'unselected'

    if (indeterminateLabel || unselectedLabel) {
      newLabel.checkedStatus = 'selected'
    } else {
      newLabel.checkedStatus = 'unselected'
    }
    this.setState({ labelsWithStatus: labelsWithStatus.map(l => newLabel.id === l.id ? newLabel : l) })
  }

  getSelectedForms = () => {
    const { form, selections, forms } = this.props

    return (form.modalForm && [ form.modalForm ]) ||
      (selections.forms && selections.forms.map(formId => forms.forms.find(form => form.id === formId)))
  }

  getLabelsWithStatus = (previousLabelsWithStatus) => {
    const { labels } = this.props
    const forms = this.getSelectedForms()

    return labels.dialogueLabels.map(label => {
      const existingStatus = previousLabelsWithStatus && previousLabelsWithStatus.find(l => label.id === l.id)
      const selectionStatus = (existingStatus && [ existingStatus.checkedStatus === 'selected' ]) || forms.map(form => {
        return form.formLabelIds.includes(label.id)
      })
      return { ...label, checkedStatus: this.getSelectionStatus(selectionStatus) }
    })
  }

  getSelectionStatus = selectionStatus => {
    if (selectionStatus && selectionStatus.every(status => status === true)) {
      return 'selected'
    } else if (selectionStatus && selectionStatus.every(status => status === false)) {
      return 'unselected'
    } else {
      return 'indeterminate'
    }
  }

  getFilteredLabelsWithStatus = status => {
    const { labelsWithStatus } = this.state

    return labelsWithStatus.filter(label => label.checkedStatus === status)
  }

  handleApplyLabels = () => {
    const { dispatch, labels, selections, forms } = this.props
    const selectedLabelIds = this.getFilteredLabelsWithStatus('selected').map(label => label.id)
    const unselectedLabelIds = this.getFilteredLabelsWithStatus('unselected').map(label => label.id)
    const selectedForms = this.getSelectedForms()
    const selectedFormsIds = selectedForms.map(form => form.id)

    selectedForms.map(form => {
      const updatedFormLabelIds = form.formLabelIds.filter(id => labels.labels.includes(id))
      const labelIds = (union(selectedLabelIds, updatedFormLabelIds)).filter(label => !unselectedLabelIds.includes(label))
      return dispatch(updateFormLabels([ form.id ], labelIds))
    })

    if (isEqual(selectedFormsIds, selections.forms)) {
      const eventDetails = getActionPerformedEventDetails('Assign Labels', {
        formNum: forms.forms.length,
        selectedFormNum: selectedFormsIds.length
      })
      mixpanel.track(eventDetails.event, eventDetails.properties)
    }

    this.handleClose()
  }

  renderBody = () => {
    const { isCreatingLabel, labelsWithStatus, newLabelName } = this.state
    const {
      api,
      t,
      labels,
      dispatch,
      selections,
      user
    } = this.props

    const { sortKey, sortDirection } = labels.dialogueQueryParams
    const labelCount = labels.dialogueLabels && labels.dialogueLabels.length
    const numForms = selections.forms.length
    const createError = api.errors['create-label']

    const selectedArray = labelsWithStatus.map(l => l.checkedStatus === 'selected')
    const selectedCount = selectedArray.filter(value => value).length
    const canManageLabels = hasPermission(CREATE_FORM_LABELS_PERMISSION, {}, user)
    const emptyModal = (
      <Trans t={t} i18nKey='no_labels_assigned'>
        <span>You don’t have any labels yet. Click&nbsp;</span>
        <b>New Label</b>
        <span>&nbsp;to create one.</span>
      </Trans>
    )

    return (
      <div className='form-label-modal'>
        <Loader
          style={{ height: 40 * 5 }}
          dummy={<Dummy rows={5} />}
          loading={labels.isLoading}
        >

          {labelCount === 0 ? (
            canManageLabels ? emptyModal : t('no_labels_assigned_no_permission')) :
            (<div className='form-label-table'>

              <table>
                <thead>
                  <tr>
                    <th>
                      <div id='labels-modal-sort-name'>
                        <span>{t('Name')}</span>
                        <Icon handleClick={() => this.handleSort('name', sortDirection === 'asc' ? 'desc' : 'asc')}>
                          {sortDirection === 'asc' && <IconArrowUp color={(sortKey === 'name' && 'primary') || 'light-muted'} size='md' id='sort-name-arrow-up' />}
                          {sortDirection === 'desc' && <IconArrowDown color={(sortKey === 'name' && 'primary') || 'light-muted'} size='md' id='sort-name-arrow-down' />}
                        </Icon>
                      </div>

                      <div id='labels-modal-sort-number'>
                        <span>{t('Forms')}</span>
                        <Icon handleClick={() => this.handleSort('relative_tagged_form_count', sortDirection === 'asc' ? 'desc' : 'asc')}>
                          {sortDirection === 'asc' && <IconArrowUp color={(sortKey === 'relative_tagged_form_count' && 'primary') || 'light-muted'} size='md' id='sort-forms-arrow-up' />}
                          {sortDirection === 'desc' && <IconArrowDown color={(sortKey === 'relative_tagged_form_count' && 'primary') || 'light-muted'} size='md' id='sort-forms-arrow-down' />}
                        </Icon>
                      </div>
                    </th>
                    <th id='labels-manage-icon'>
                      {canManageLabels && (
                        <Icon
                          handleClick={() => dispatch({ type: 'OPEN_SHEET', data: 'manage-labels-sheet' })}
                        >
                          <IconGear color='primary' size='md' />
                        </Icon>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {labelsWithStatus.map(label => {
                    const checked = label.checkedStatus === 'selected' || label.name === newLabelName

                    return (
                      <tr key={label.id}>
                        <td  className={`checkbox-${label.checkedStatus} label-name`} ref={this.labelRef} id={`label-row-${toID(label.name)}`}>
                          <Checkbox
                            checked={checked}
                            onClick={blurClick(() => {})}
                            onChange={() => this.handleCheckboxChange(label)}
                            id={`checkbox-${toID(label.name)}`}
                          />
                          {label.name}
                        </td>
                        <td>
                          {label.relativeTaggedFormCount}
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>

            </div>
            )}

          <div className='label-modal-bottom' id='label-selected'>
            {numForms > 1 ?
              <span style={{ display: !isCreatingLabel ? 'block' : 'none' }}>{numForms} {t('forms_selected')}</span> :
              <span style={{ display: !isCreatingLabel ? 'block' : 'none' }}>{selectedCount} {selectedCount === 1 ? t('label_selected') : t('labels_selected')}</span>
            }

            {isCreatingLabel &&
              <EditableField
                value=''
                editing={true}
                handleCancel={this.handleNewLabelCancel}
                handleEdit={this.handleNewLabelRequest}
              />
            }
            {createError &&
            <div className='error form-label-modal-error'>
              <IconWarning />
              {t(`${createError}_error`, { maxLength: MAX_LENGTH_LABEL_NAME })}
            </div>}

          </div>
        </Loader>

      </div>
    )
  }

  render() {
    const { t, labels, user } = this.props
    const { isCreatingLabel } = this.state
    const canManageLabels = hasPermission(CREATE_FORM_LABELS_PERMISSION, {}, user)

    return (
      <Modal
        size='md'
        name='form-label-modal'
        id='form-label-modal'
        className='form-label-modal-header'
      >
        <ModalHeader
          header={t('Labels')}
          addOn={(
            <Search
              handleSearch={this.handleSearch}
              placeholder={t('Search')}
              query={labels.dialogueQueryParams.query}
              id='label-search-bar'
            />
          )}
        />

        <ModalBody>
          {this.renderBody()}
        </ModalBody>

        <ModalFooter>
          {canManageLabels &&
          <Align placement='left'>
            <Button
              variant='ghost'
              id='labels-modal-new-label'
              disabled={isCreatingLabel || !canManageLabels}
              onClick={this.handleNewLabel}
            >
              {t('NEW_LABEL')}
            </Button>
          </Align>
          }
          <Align placement='right'>
            <Button
              variant='ghost'
              color='secondary'
              id='labels-modal-cancel'
              onClick={this.handleClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              id='labels-modal-apply'
              onClick={() => this.handleApplyLabels(labels.id)}
            >
              {t('APPLY')}
            </Button>
          </Align>

        </ModalFooter>
      </Modal>
    )
  }
}

FormLabelModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  api: PropTypes.shape(API_STATE_PROPS).isRequired,
  form: PropTypes.object.isRequired,
  forms: PropTypes.shape(FORMS_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
  labels: PropTypes.shape(LABELS_STATE_PROPS).isRequired,
  selections: PropTypes.shape(SELECTIONS_STATE_PROPS).isRequired,
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
}

const select = state => ({
  api: state.api,
  form: state.form,
  forms: state.forms,
  labels: state.labels,
  selections: state.selections,
  user: state.user,
})

FormLabelModal = withTranslation('modals')(FormLabelModal)
FormLabelModal = connect(select)(FormLabelModal)

export default FormLabelModal
