import { setConfig, resetConfig, setFlagsAction } from '../services/settings/featureFlags'
import { DEFAULT_FLAGS } from '../constants/flags'

export const saveConfig = (key, value) => {
  setConfig(key, value)
}

export const restoreConfig = () => dispatch => {
  dispatch(setFlagsAction({ configs: DEFAULT_FLAGS }))

  resetConfig(DEFAULT_FLAGS)
}
