import './SetPassword.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import {
  Button,
  Col,
  Input
} from '@sm/wds-react'

import { ConfirmModal } from '../ConfirmModal'

import { setPassword } from '../../../actions/form'
import { conditional } from '../../../utils/conditional'
import { a11yClick } from '../../../utils/a11y'
import { PLAN_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class SetPassword extends React.Component {

  handleSetPassword = () => {
    const { t, dispatch, form } = this.props
    const { newPassword } = this.state

    dispatch(setPassword(
      newPassword,
      form.modalForm.id,
      t('Setting_password')
    ))

    this.finish()
  }

  finish = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  constructor(props) {
    super(props)

    this.state = {
      newPassword: ''
    }
  }

  render() {
    const {
      t,
      form,
      plan
    } = this.props

    const { password } = form.modalForm
    const { newPassword } = this.state
    const hasPassword = !!password

    const header = t(
      hasPassword ? 'set_password_header_change' : 'set_password_header_create'
    )

    const okayButtonText = t(conditional([
      [ hasPassword && newPassword, 'CHANGE_PASSWORD' ],
      [ hasPassword && !newPassword, 'REMOVE_PASSWORD' ],
      [ !hasPassword && newPassword, 'SET_PASSWORD' ]
    ]))

    return (
      <ConfirmModal
        size='sm'
        name='set-password-modal'
        className='set-password-modal'
        header={header}
        isUpgrade={!plan?.planFeatures?.classicForms?.setFormPassword}
        cancelButton={(
          <Button
            id='forms-password-modal-cancel'
            variant='ghost'
            color='secondary'
            onClick={this.finish}
            size='sm'
          >
            {t('CANCEL')}
          </Button>
        )}
        okayButton={okayButtonText && (
          <Button
            id='forms-password-modal-save'
            onClick={this.handleSetPassword}
            size='sm'
          >
            {okayButtonText}
          </Button>
        )}
      >
        <div className='row'>
          <Col>
            <Input
              id='forms-password-modal-input'
              type='password'
              autoFocus
              placeholder={t('Enter_a_password')}
              onChange={e => this.setState({ newPassword: e.target.value })}
              onKeyPress={a11yClick(this.handleSetPassword)}
              stretched
              value={newPassword}
            />
          </Col>
        </div>
      </ConfirmModal>
    )
  }
}

SetPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  plan: PropTypes.shape(PLAN_STATE_PROPS).isRequired,
  t: PropTypes.func.isRequired,
}

const select = state => ({
  form: state.form,
  plan: state.plan,
})

SetPassword = withTranslation('modals')(SetPassword)
SetPassword = connect(select)(SetPassword)

export default SetPassword
