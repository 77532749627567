import { createFlags } from 'flag'
import { createReduxBindings } from 'flag/redux'
import { store, FLAGS } from '../storage'
import { DEFAULT_FLAGS } from '../../constants/flags'


const { FlagsProvider } = createFlags()
const { ConnectedFlagsProvider, createFlagsReducer, setFlagsAction } = createReduxBindings(FlagsProvider)

const getFlags = () => {
  return {
    ...DEFAULT_FLAGS,
    ...(store.get(FLAGS) || {})
  }
}

const isFeatureEnabled = (feature) => {
  const flags = getFlags()
  return flags ? flags[feature] === true : false
}

const isToolEnabled = () => {
  const flags = store.get(FLAGS)
  return flags ? true : false
}

const getConfig = (key) => {
  const flags = getFlags()
  return flags ? flags[key] : null
}

const setConfig = (key, value) => {
  const flags = getFlags()
  flags[key] = value
  store.set(FLAGS, flags)
}

const resetConfig = (config) => {
  store.set(FLAGS, config)
}

export {
  ConnectedFlagsProvider,
  createFlagsReducer,
  setFlagsAction,
  isFeatureEnabled,
  isToolEnabled,
  getConfig,
  setConfig,
  resetConfig
}

