import './NewFileDetails.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Modal } from '../Modal'
import { keyPressed } from '../../../utils/a11y'

import {
  Align,
  Button,
  Input,
  Textarea,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@sm/wds-react'
import { isSingleFileUpload } from '../../../models/files'

export class NewFileDetails extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
    dispatch({ type: 'RESET_FILE_REQUEST' })
  }

  handleInputChange = (key) => (e) => {
    const { dispatch } = this.props
    dispatch({ type: 'SET_FILE_REQUEST', data: { [key]: e.target.value }})
  }

  handleButtonClick = () => {
    const { t, dispatch, files, hasNext, type } = this.props
    if (files.fileRequest.filename) {
      if (hasNext) {
        dispatch({ type: 'SET_MODAL_PROPS', data: {
          buttonText: isSingleFileUpload(files) ? t('UPLOAD') : t('CREATE'),
          sourceType: type,
          isCreateFolderEnabled: isSingleFileUpload(files) ? true : false
        }})
        dispatch({ type: 'OPEN_MODAL', data: 'folder-select-modal' })
      } else {
        dispatch({ type: 'CLOSE_MODAL' })
        dispatch({ type: 'SET_FILE_REQUEST', data: { status: 'pending' }})
      }
    }
  }

  componentDidMount() {
    if (this.input) {
      this.input.focus()
    }
  }

  render(){
    const { t, files, buttonText, type } = this.props
    const { filename = '', description = '' } = files.fileRequest

    return (
      <Modal
        className='new-file-details-modal'
        name='new-file-details'
        size='sm'
      >
        <ModalHeader
          header={(
            <div className='new-file-details-header'>
              <h1 className='wds-modal__title'>
                {type === 'folder' ? t('new_folder_details_header') : t('new_file_details_header')}
              </h1>
            </div>
          )}
        />

        <ModalBody>
          <div className='filename-input-container'>
            <label
              className='wds-label'
              htmlFor='filename-input'
            >
              {t('new_file_details_name')}
            </label>

            <Input
              id='filename-input'
              inputRef={input => { this.input = input }}
              stretched
              placeholder={type === 'folder' ? t('default_foldername') : t('default_filename')}
              value={filename}
              onChange={this.handleInputChange('filename')}
              onKeyPress={keyPressed('Enter', this.handleButtonClick)}
            />
          </div>

          <div className='description-input-container'>
            <label
              className='wds-label'
              htmlFor='description-input'
            >
              {t('new_file_details_description')}
            </label>

            <Textarea
              id='description-input'
              stretched
              placeholder={t('default_file_description')}
              value={description}
              onChange={this.handleInputChange('description')}
              size='lg'
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Align placement='right'>
            <Button
              variant='ghost'
              color='secondary'
              size='sm'
              onClick={this.handleClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              size='sm'
              disabled={!filename}
              onClick={this.handleButtonClick}
            >
              { buttonText ? buttonText : t('NEXT')}
            </Button>
          </Align>
        </ModalFooter>
      </Modal>
    )
  }
}

const select = (state) => ({
  files: state.files
})

NewFileDetails.propTypes = {
  buttonText: PropTypes.string,
  dispatch: PropTypes.func,
  files: PropTypes.object,
  hasNext: PropTypes.bool,
  t: PropTypes.func,
  type: PropTypes.string
}

NewFileDetails = withTranslation('modals')(NewFileDetails)
NewFileDetails = connect(select)(NewFileDetails)

export default NewFileDetails
