import './FolderSelector.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'
import {
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Align
} from '@sm/wds-react'
import { ApiError } from '../../../components/ApiError'
import { FolderTable } from '../../../components/Files/FolderTable'
import { Modal } from '../Modal'
import { Search } from '../../../components/Search'
import { createFolder, fetchFolders } from '../../../actions/files'
import { toAPIParentId, getSelectedFolderId, shouldRefreshFolder, isFolderOpened } from '../../../models/files'
import { toID } from '../../../utils/string'
import { API_STATE_PROPS, PLAN_STATE_PROPS } from '../../../constants/propTypes/coreWebPropTypes'


export class FolderSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      newFolder: {}
    }
  }
  componentDidMount() {
    this.fetchFolders({})
  }

  componentDidUpdate() {
    const { newFolder } = this.state
    const { dispatch, files } = this.props
    const folders = files.folders
    const refreshFolder = folders.refreshFolder

    if (shouldRefreshFolder(refreshFolder)) {
      this.setState({ newFolder: {}})
      this.fetchFolders({
        id: toAPIParentId(refreshFolder.id),
        parentId: folders[refreshFolder.id].parentId
      })

      dispatch({ type: 'SELECT_FOLDER', data: { //select the newly created folder
        parentId: folders.refreshFolder.id,
        filename: newFolder.filename
      }})
      dispatch({ type: 'SET_REFRESH_FOLDER', data: {}})
      dispatch({ type: 'SET_REFRESH', data: true })
    }
  }

  fetchFolders = ({ id, parentId, query }) => {
    const { dispatch } = this.props
    dispatch(fetchFolders({ id, parentId, query }))
  }

  handleFolderToggle = (id, parentId) => {
    const { dispatch, files } = this.props
    const folders = files.folders

    if (id in folders) {
      const status = folders[id].status === 'open' ? 'closed' : 'open'

      dispatch({
        type: 'SET_FOLDERS',
        data: { ...folders[id], id, status }
      })
    } else {
      this.fetchFolders({ id, parentId })
    }
  }

  handleBack = () => {
    const { dispatch, sourceType } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
    dispatch( { type: 'SET_MODAL_PROPS', data: { hasNext: true, type: sourceType }})
    dispatch({ type: 'OPEN_MODAL', data: 'new-file-details-modal' })
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
    dispatch({ type: 'RESET_FILE_REQUEST' })
  }

  handleRowClick = (id, filename, parentId) => {
    const { dispatch } = this.props
    dispatch({ type: 'SELECT_FOLDER', data: { id, filename, parentId }})
  }

  handleComplete = () => {
    const { files, dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })


    dispatch({ type: 'SET_FILE_REQUEST', data: {
      parentFolder: {
        ...files.folders.selectedFolder,
        id: toAPIParentId(getSelectedFolderId(files.folders.selectedFolder, files.folders)),
        type: 'folder'
      },
      status: 'pending'
    }})
  }

  handleNewFolderInit = () => {
    const { files } = this.props
    const selectedFolder = files.folders.selectedFolder
    const parentId = !selectedFolder.id ? 'root' : selectedFolder.id

    if (!isFolderOpened(parentId, files.folders)) {
      this.handleFolderToggle(selectedFolder.id, selectedFolder.parentId)
    }
    this.setState({ newFolder: { parentId, status: 'editing' }})
  }

  handleNewFolderCancel = () => {
    this.setState({ newFolder: {}})
  }

  handleNewFolderRequest = (filename) => {
    const { dispatch } = this.props
    let { newFolder } = this.state
    const parentFolder = { id: newFolder.parentId }

    if (filename) {
      newFolder = { ...newFolder, parentFolder, filename, location: 'modal', status: 'pending' }
      this.setState({ newFolder })

      dispatch(createFolder(newFolder, {}))

    } else { //cancel create folder if filename is empty
      this.setState({ newFolder: {}})
    }
  }

  renderBody = () => {
    const { newFolder } = this.state
    const { api, files, exclude, plan }  = this.props
    const folders = files.folders

    let errorState

    if (api.errors['folder-list'])
      errorState = <ApiError reload={() => this.fetchFolders({})} />

    return (
      <div className='folder-selector-body'>
        {!errorState &&
          <FolderTable
            folders={folders}
            newFolder={newFolder}
            exclude={exclude}
            handleExpand={this.handleFolderToggle}
            handleSelect={this.handleRowClick}
            handleCreate={this.handleNewFolderRequest}
            handleCancel={this.handleNewFolderCancel}
            totalSizeUsed={plan.stats.uploads + plan.stats.respondents}
          />
        }
        {errorState}
      </div>
    )
  }

  render() {
    const { newFolder } = this.state
    const { t, files, buttonText, hasBack, isCreateFolderEnabled } = this.props
    const selected = files.folders.selectedFolder

    return (
      <Modal
        className='folder-selector-modal'
        name='folder-selector'
        size='md'
      >
        <ModalHeader
          header={(
            <div className='folder-selector-header'>
              <h1 className='wds-modal__title'>
                {t('folder_selector_header')}
              </h1>
            </div>
          )}
          addOn={(
            <Search
              handleSearch={query => this.fetchFolders({ query })}
              placeholder={t('Search')}
              id='files-search-bar'
            />
          )}
        />

        <ModalBody>
          {this.renderBody()}
        </ModalBody>

        <ModalFooter>
          <Align placement='left'>
            {hasBack === true &&
              <Button
                variant='ghost'
                color='secondary'
                size='sm'
                onClick={this.handleBack}
              >
                {t('BACK')}
              </Button>
            }

            {isCreateFolderEnabled &&
              <Button
                size='sm'
                id='files-folder-modal-new'
                disabled={!isEmpty(files.folders.loadingMap) || newFolder.status === 'editing'}
                onClick={this.handleNewFolderInit}
              >
                {t('NEW_FOLDER')}
              </Button>
            }
            <i className='selected-filename'>{selected.filename}</i>
          </Align>

          <Align placement='right'>
            <Button
              variant='ghost'
              color='secondary'
              size='sm'
              id='files-folder-modal-cancel'
              onClick={this.handleClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              size='sm'
              id={toID(`files-folder-modal-${buttonText}`)}
              disabled={isEmpty(files.folders.selectedFolder)}
              onClick={this.handleComplete}
            >
              {buttonText}
            </Button>
          </Align>
        </ModalFooter>
      </Modal>
    )
  }
}

const select = state => ({
  files: state.files,
  plan: state.plan,
  api: state.api,
})

FolderSelector.propTypes = {
  api: PropTypes.shape(API_STATE_PROPS).isRequired,
  buttonText: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  exclude: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.object.isRequired,
  hasBack: PropTypes.bool,
  isCreateFolderEnabled: PropTypes.bool,
  plan: PropTypes.shape(PLAN_STATE_PROPS).isRequired,
  sourceType: PropTypes.string,
  t: PropTypes.func.isRequired,
}

FolderSelector.defaultProps = {
  hasBack: true,
  isCreateFolderEnabled: true,
}

FolderSelector = withTranslation('modals')(FolderSelector)
FolderSelector = connect(select)(FolderSelector)
export default FolderSelector
