import './SkipLink.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

export class SkipLink extends React.PureComponent {
  render() {
    const { t, handleSkip } = this.props

    return (
      <a
        href='#/'
        className='skip-link'
        onClick={e => {
          e.preventDefault()
          handleSkip()
        }}
      >
        {t('skip_to_main')}
      </a>
    )
  }
}

SkipLink.propTypes = {
  handleSkip: PropTypes.func.isRequired,
  t: PropTypes.func
}

export default withTranslation('global')(SkipLink)

