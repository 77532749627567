const CURRENCY_MAP = {
  baht: '฿' ,
  euro: '€',
  forint: 'Ft',
  franc: 'CHF',
  koruna: 'Kč',
  krona: 'kr',
  pesos: '$',
  pound: '£',
  ringgit: 'RM',
  shekel: '₪',
  yen: '¥',
  zloty: 'zł',
}

export const getCurrencySymbol = currencyType => {
  return CURRENCY_MAP[currencyType] || '$'
}
