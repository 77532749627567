const IS_TRUTHY = [ 'yes', 'y', 'true', '1' ]

export const isTruthy = (str) => {
  if (str === undefined) {
    return false
  }

  if (IS_TRUTHY.includes(str.trim().toLowerCase())) {
    return true
  }

  return false
}
