import isEmpty from 'lodash.isempty'

import { api, getTableQueryParams } from '../services/api'
import { getQueryParamsEventDetails } from '../models/mixpanel'
import { mixpanel } from '../services/telemetry/mixpanel'

import { getPlan } from './plan'
import { apiError } from './api'
import { fetchAllLabels } from './labels'

export const fetchForms = (queryObj={}, queryDiff={}) => {
  const { sortKey, sortDirection, filterKey, labelIds, query, page, pageSize } = queryObj

  const conditions = []

  if (query) {
    conditions.push({
      key: 'name',
      value: query,
      op: 'like'
    })
  }

  if (filterKey !== 'all') {
    conditions.push({
      key: 'isPublic',
      value: (filterKey === 'public'),
      op: '=='
    })
  }

  const params = {
    conditions,
    sortBy: sortKey,
    sortDirection,
    page,
    pageSize
  }

  if (labelIds && labelIds.length > 0) {
    params.labelIds = labelIds
  }

  return (dispatch, getState) => {
    const { plan } = getState()
    dispatch({ type: 'SET_FORMS_LOADING', data: true })
    dispatch(apiError('form-list', false))

    api.get(`/forms?${getTableQueryParams(params)}`)
      .then(({ data }) => {

        if (!isEmpty(queryDiff)) {
          const eventData = {
            totalCount: data.totalCount,
            countLimit: plan.numForms,
          }

          const eventDetails = getQueryParamsEventDetails(queryDiff, queryObj, eventData)
          eventDetails.forEach(eventDetail => {mixpanel.track(eventDetail.event, eventDetail.properties)})
        }

        dispatch({ type: 'SET_FORMS', data })
        dispatch({ type: 'RESET_SELECTED', data: 'forms' })
      })
      .catch(() => {
        dispatch(apiError('form-list', true))
        dispatch({ type: 'SET_FORMS_LOADING', data: false })
      })
  }
}

export const deleteForms = (formIds, successMessage) => {
  return (dispatch, getState) => {
    const { forms } = getState()
    dispatch({ type: 'SET_FORMS_LOADING', data: formIds })

    api.post('/forms/requests/deletes', { formIds })
      .then(() => {
        dispatch(fetchForms(forms.queryParams))
        dispatch(getPlan())
        dispatch({ type: 'SHOW_TOAST', data: {
          type: 'success',
          title: successMessage
        }})
      })
  }
}


export const updateFormLabels = (formIds, formLabelIds) => {
  return (dispatch, getState) => {
    const { forms } = getState()
    dispatch({ type: 'SET_FORMS_LOADING', data: formIds })
    api.post('/forms/requests/replace_form_labels', { formIds, formLabelIds })
      .then(() => {
        dispatch(fetchForms(forms.queryParams))
        dispatch(fetchAllLabels())
      })
  }
}
