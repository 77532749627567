import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@sm/wds-react'

export const SocialLink = props => {
  const { children, href, iconCode, size, id } = props

  return (
    <a
      id={id}
      href={href}
      target='_blank'
      rel='noreferrer noopener'
    >
      {children ? children : <Icon size={size}>{iconCode}</Icon>}
    </a>
  )
}

SocialLink.propTypes = {
  href: PropTypes.string,
  iconCode: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node
}

export default SocialLink
