import './MobileView.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button } from '@sm/wds-react'

import wufooLogo from './images/wufoo-logo.svg'

import {
  isMobileBypassEnabled,
  mobileRedirect,
  useDesktop
} from '../../services/api'

export const MobileView = props => {
  const { t } = props

  if (isMobileBypassEnabled()) {
    return null
  }

  return (
    <div className='mobile-view text-center hidden-md hidden-lg hidden-xl'>
      <div>
        <img src={wufooLogo} alt='' />
        <h2 className='mb-4'>{t('mobile_header')}</h2>
        <p>{t('browser_size_unsupported_message')}</p>
      </div>

      <div>
        <Button
          color='secondary'
          onClick={useDesktop}
        >
          {t('use_desktop')}
        </Button>

        <Button
          onClick={mobileRedirect}
        >
          {t('mobile_redirect')}
        </Button>
      </div>
    </div>
  )
}

MobileView.propTypes = {
  t: PropTypes.func
}

export default withTranslation('global')(MobileView)
