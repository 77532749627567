import {
  CREATE_FILE_PERMISSION,
  CREATE_FOLDER_PERMISSION,
  CREATE_FORMS_PERMISSION,
  CREATE_FORM_LABELS_PERMISSION,
  EDIT_FILE_PERMISSION,
} from '../constants/permissions'


export const hasPermission = (permission, plan, resource) => {
  const shouldUpgradePlan = shouldUpgrade(permission, plan)

  switch (permission) {
    case CREATE_FILE_PERMISSION: // user
      return !shouldUpgradePlan && !!resource?.permissions?.uploadAssets
    case CREATE_FOLDER_PERMISSION: // user
      return !shouldUpgradePlan && !!resource?.permissions?.createFileFolders
    case CREATE_FORMS_PERMISSION: // user
      return !!resource?.permissions?.createForms
    case CREATE_FORM_LABELS_PERMISSION: //labels
      return !!resource?.permissions?.createFormLabels?.public
    case EDIT_FILE_PERMISSION: // file
      return !shouldUpgradePlan && resource?.editable
    default:
      throw new Error('Could not determine permission')
  }
}


export const shouldUpgrade = (feature, plan) => {
  switch (feature) {
    case CREATE_FILE_PERMISSION:
      return !plan?.planFeatures?.account?.uploadAssets
    case CREATE_FOLDER_PERMISSION:
      return !plan?.planFeatures?.account?.createFileFolders
    case EDIT_FILE_PERMISSION:
      return !plan?.planFeatures?.account?.editFiles
    default:
      return false
  }
}

export const getFilesWithPermission = (files, plan, user) => {
  const canUpload = hasPermission(CREATE_FILE_PERMISSION, plan, user)
  const shouldUpgradeToUpload = shouldUpgrade(CREATE_FILE_PERMISSION, plan)
  const canCreateFolder = hasPermission(CREATE_FOLDER_PERMISSION, plan, user)
  const shouldUpgradeToCreate = shouldUpgrade(CREATE_FOLDER_PERMISSION, plan)
  return files && files.map(file => {
    const canEdit = hasPermission(EDIT_FILE_PERMISSION, plan, file)
    const shouldUpgradeToEdit = shouldUpgrade(EDIT_FILE_PERMISSION, plan)
    return {
      ...file,
      canUpload,
      shouldUpgradeToUpload,
      canCreateFolder,
      shouldUpgradeToCreate,
      canEdit,
      shouldUpgradeToEdit
    }
  })
}

export const canBatchMove = (files, plan) => {
  return files.every(file => hasPermission(EDIT_FILE_PERMISSION, plan, file))
}

export const canBatchCopy = (files, plan, user) => {
  return hasPermission(CREATE_FILE_PERMISSION, plan, user) && files.every(file => file.type !== 'folder')
}

export const canBatchDelete = (files) => {
  return files.every(file => file.editable)
}
