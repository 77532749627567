import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { isLoggedOutExperience } from '../../utils/urls'


const isEntryNavigation = (oldPath, newPath) => {
  const entryDetailRegex = /\/entry-manager\/\d+\/entries\/\d+/
  return entryDetailRegex.test(oldPath) && entryDetailRegex.test(newPath)
}

const isPathTheSame = (oldPath, newPath) => {
  return oldPath === newPath
}

const SCROLL_TO_TOP_EXCEPTIONS = [
  isEntryNavigation,
  isPathTheSame
]

export class ScrollToTop extends React.Component {

  componentDidMount() {
    const { location } = this.props

    window.onbeforeunload = () => {
      if (!isLoggedOutExperience(location)) {
        window.scrollTo(0, 0)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (!SCROLL_TO_TOP_EXCEPTIONS.some(fn => fn(prevProps.location.pathname, location.pathname))) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export default withRouter(ScrollToTop)
