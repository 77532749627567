import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash.isempty'
import { LegacyRedirect } from '../../containers/LegacyRedirect'
import { blurClick } from '../../utils/a11y'
import { getLaunchFBEventDetails } from '../../models/mixpanel'
import { mixpanel } from '../../services/telemetry/mixpanel'
import { FLAGS_STATE_PROPS, USER_STATE_PROPS } from '../../constants/propTypes/coreWebPropTypes'


export class LegacyLink extends React.Component {

  constructor(props) {
    super(props)
    this.state = { isRedirecting: false }
  }

  shouldRenderNewTab = (e) => {
    const isPlatform = (platform) => (window.navigator.platform.toLowerCase().indexOf(platform) !== -1)
    return (e.metaKey && isPlatform('mac') !== -1) ||
      (e.ctrlKey && isPlatform('win') !== -1)
  }

  getLegacyUrl(path) {
    const { flags, user } = this.props

    const legacyUrl = flags.configs.legacyUrl
    const protocol = flags.configs.protocol
    const subdomain = user.subdomain

    return `${protocol}://${subdomain}.${legacyUrl}${path}`
  }

  render() {
    const {
      children,
      className,
      id,
      newTab,
      to,
      target,
      withTransfer
    } = this.props

    const { isRedirecting } = this.state
    const href = withTransfer ? `/redirect?url=${to}` : this.getLegacyUrl(to)

    const handleClick = event => {
      const { form, location, createFormType } = this.props

      if (!isEmpty(form) && location) {
        const eventDetails = getLaunchFBEventDetails(location, { form: form })
        mixpanel.track(eventDetails.event, eventDetails.properties)
      }
      else if (createFormType && location) {
        const eventDetails = getLaunchFBEventDetails(location, { createFormType })
        mixpanel.track(eventDetails.event, eventDetails.properties)
      }

      if (withTransfer) {
        blurClick(e => {
          if (!newTab && !this.shouldRenderNewTab(e)) {
            if (target === '_blank') {
              return
            }
            e.preventDefault()
            this.setState({ isRedirecting: true })
          }
        })(event)
      }
    }
    const isNewTab = withTransfer && !newTab ? '_self' : '_blank'
    const rel = withTransfer && !newTab ? null : 'noopener noreferrer'

    return (
      <a
        id={id}
        className={className}
        href={href}
        rel={rel}
        target={isNewTab}
        onClick={handleClick}
      >
        {children}
        {isRedirecting &&
          <LegacyRedirect
            url={to}
            showLoader={false}
          />
        }
      </a>
    )
  }
}

LegacyLink.defaultProps = {
  createFormType: '',
  form: { }, // default prop for form?
  location: '',
  newTab: false,
  withTransfer: true
}

LegacyLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  createFormType: PropTypes.string,
  flags: PropTypes.shape(FLAGS_STATE_PROPS).isRequired,
  form: PropTypes.object,
  id: PropTypes.string,
  location: PropTypes.string,
  newTab: PropTypes.bool,
  user: PropTypes.shape(USER_STATE_PROPS).isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  withTransfer: PropTypes.bool
}

const select = state => ({
  flags: state.flags,
  user: state.user,
})

LegacyLink = connect(select)(LegacyLink)

export default LegacyLink
