import './Thumbnail.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@sm/wds-react'
import { IconEye } from '@sm/wds-icons'
import { isImage } from '../../../models/files'
import { conditional } from '../../../utils/conditional'

import folderClosedImg from '../images/folder-closed.svg'
import folderOpenImg from '../images/folder-open.svg'

const FONT_SCALE_FACTOR = 45


export class Thumbnail extends React.Component {

  generateImage = (link, className='') => {
    return <img crossOrigin='anonymous' className={className} src={link} alt='' />
  }

  generateAvatar = (ext, scaleFontSize=true) => {
    const fontSizeScaled = FONT_SCALE_FACTOR / ext.length
    const fontSize = scaleFontSize ? fontSizeScaled : undefined

    return (
      <Avatar >
        <span style={{ fontSize }}>{ext}</span>
      </Avatar>
    )
  }

  render() {
    const {
      ext,
      link,
      type,
      isFocus,
      isFocusEnabled
    } = this.props

    const defaultFocusImg = (
      <Avatar><IconEye /></Avatar>
    )

    const focusedImg = conditional([
      [ type === 'folder', this.generateImage(folderOpenImg, 'folder') ]
    ], defaultFocusImg)

    const image = conditional([
      [ type === 'folder', this.generateImage(isFocus === true ? folderOpenImg : folderClosedImg, 'folder') ],
      [ isImage(ext) && link, this.generateImage(link, 'thumbnail-image') ]
    ], this.generateAvatar(ext === 'unknown' ? '?' : ext, false))

    return (
      <React.Fragment>
        <div className='thumbnail'>
          {image}
        </div>

        {isFocusEnabled === true &&
          <div className='thumbnail thumbnail-focused'>
            {focusedImg}
          </div>
        }

      </React.Fragment>
    )
  }
}

Thumbnail.propTypes = {
  ext: PropTypes.string,
  link: PropTypes.string,
  isFocus: PropTypes.bool,
  isFocusEnabled: PropTypes.bool,
  type: PropTypes.oneOf([ 'folder', 'file' ]).isRequired,
}

Thumbnail.defaultProps = {
  isFocus: false,
  isFocusEnabled: true

}

export default Thumbnail
