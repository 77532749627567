export const downloadFromUrl = (url) => {
  simulateTempLinkClick(url)
}

export const downloadFromBlob = (data, filename) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(data, filename)
  } else {
    const blobURL = window.URL?.createObjectURL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
    simulateTempLinkClick(blobURL, filename, true)
  }
}

const simulateTempLinkClick = (url, filename=null, isBlobUrl=false) => {
  let tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = url
  tempLink.setAttribute('download', filename)

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  if (isBlobUrl) {
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 50)
  }
}