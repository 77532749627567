import { api, getTableQueryParams } from '../services/api'
import { apiError } from './api'
import { CSV } from '../utils/mimetypes'
import { fetchForm } from './form'
import { getEntriesDeletedEventDetails } from '../models/mixpanel'
import history from '../services/history'
import { parameterize } from '../utils/string'
import { withToastDisplay } from '../utils/toast'
import { urls, getUrl } from '../utils/urls'
import { ENTRY_SORT_KEY_MAP, buildEntryFetchParams } from '../models/entries'
import { mixpanel } from '../services/telemetry/mixpanel'


export const fetchEntries = (formId, queryObj={}) => {
  const {
    filterKey,
    query,
    page,
    pageSize,
    sortKey,
    sortDirection,
    expression,
    userTimezone
  } = queryObj

  let params = {
    ...buildEntryFetchParams(filterKey, expression, query, userTimezone),
    page,
    pageSize,
    sortBy: ENTRY_SORT_KEY_MAP[sortKey] || sortKey,
    sortDirection
  }

  return dispatch => {
    dispatch({ type: 'SET_ENTRIES_LOADING', data: true })
    dispatch(apiError('entries-list', false))

    const requests = [
      api.get(`/forms/${formId}/entries/stats`),
      api.get(`/forms/${formId}/fieldsets`)
    ]

    Promise.all(requests)
      .then(results => {
        const [ stats, fieldSets ] = results
        dispatch({ type: 'SET_ENTRIES_STATS', data: stats.data })
        dispatch({ type: 'SET_ENTRY_FIELDSETS', data: fieldSets.data })
      })
      .then(() => api.get(`/forms/${formId}/entries?${getTableQueryParams(params)}`))
      .then(entries => {
        dispatch({ type: 'SET_ENTRIES', data: entries.data })
        dispatch({ type: 'SET_ENTRIES_COLUMNS', data: { formId }})
        dispatch({ type: 'RESET_SELECTED', data: 'entries' })
      })
      .catch(({ response }) => {
        dispatch(apiError('entries-list', (response && response.data && response.data.errors) || true))
        dispatch({ type: 'SET_ENTRIES', data: {}})
        dispatch({ type: 'SET_ENTRIES_LOADING', data: false })
      })
  }
}

export const deleteEntries = (formId, queryObj={}, toastMessages) => {
  return async (dispatch, getState) => {
    const { entries, user } = getState()
    dispatch({ type: 'SET_ENTRIES_LOADING', data: true })

    const userTimezone = user.timezone

    await withToastDisplay(dispatch, toastMessages, async () => {
      try {
        const { filterKey, query, expression } = queryObj
        const params = buildEntryFetchParams(filterKey, expression, query, userTimezone)
        await api.delete(`/forms/${formId}/entries?${getTableQueryParams(params)}`)

        const eventDetails = getEntriesDeletedEventDetails(formId, {
          isSelectedAll: entries.totalCount === (entries.stats?.totalEntries || entries.totalCount),
          numOfEntries: entries.stats?.totalEntries || entries.totalCount,
          numOfEntriesDeleted: entries.totalCount,
        })
        mixpanel.track(eventDetails.event, eventDetails.properties)
      } catch (e) {
        dispatch({ type: 'SET_ENTRIES_LOADING', data: false })
        throw e
      } finally {
        history.push(getUrl(urls.ENTRY_MANAGER, { formId }))
        dispatch(fetchForm(formId))
        dispatch(fetchEntries(formId, { ...entries.queryParams, userTimezone }))
      }
    })
  }
}

export const deleteEntriesByEntryIds = (formId, entryIds, { successMessage, errorMessage }) => {
  return async (dispatch, getState) => {
    const { entries, user } = getState()
    dispatch({ type: 'SET_ENTRIES_LOADING', data: true })

    const userTimezone  = user.timezone

    try {
      await api.post(`/forms/${formId}/entries/requests/deletes`, { entryIds })
      dispatch(fetchEntries(formId, { ...entries.queryParams, userTimezone }))
      dispatch({ type: 'SHOW_TOAST', data: {
        type: 'success',
        body: successMessage
      }})

      const eventDetails = getEntriesDeletedEventDetails(formId, {
        isSelectedAll: entryIds.length === (entries.stats?.totalEntries || entries.totalCount),
        numOfEntries: entries.stats?.totalEntries || entries.totalCount,
        numOfEntriesDeleted: entryIds.length,
      })
      mixpanel.track(eventDetails.event, eventDetails.properties)
    } catch (e) {
      dispatch({
        type: 'SHOW_TOAST', data: {
          type: 'error',
          body: errorMessage,
          autoClose: false
        }
      })
    } finally {
      dispatch({ type: 'SET_ENTRIES_LOADING', data: false })
      history.push(getUrl(urls.ENTRY_MANAGER, { formId }))
      dispatch(fetchForm(formId))
    }
  }
}

export const downloadEntries = (form, entryIds) => {
  const { id, name='form' } = form
  return () => {
    api.download(
      `/forms/${id}/entries/download`,
      `${parameterize(name)}_entries.csv`, {
        data: {
          entryIds,
        },
        headers: {
          Accept: CSV
        }
      },
      'post'
    )
  }
}

export const downloadPaginatedEntries = (form, queryObj={}, toastMessages={}) => {
  const {
    filterKey,
    query,
    expression
  } = queryObj

  let params = buildEntryFetchParams(filterKey, expression, query)

  return async dispatch => {
    await withToastDisplay(dispatch, toastMessages, async () => {
      await new Promise((resolve, reject) => {
        api.download(
          `/forms/${form.id}/entries/download?${getTableQueryParams(params)}`,
          `${parameterize(form.name)}_entries.csv`, {
            headers: {
              Accept: CSV
            },
            callback: actionFunc => {
              actionFunc()
              resolve()
            }
          })
          .catch(reject)
      })
    })
  }
}

export const sendEntryEmail = (formId, emails, entryIds, toastMessages) => {
  return dispatch => {
    withToastDisplay(dispatch, toastMessages,
      () => api.post(`/forms/${formId}/entries/requests/email`, { emails, entryIds }))
  }
}
