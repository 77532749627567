const defaultState = {
  activeModal: null,
  props: null
}

const modal = (state=defaultState, { type, data }) => {

  switch (type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        activeModal: data
      }
    case 'CLOSE_MODAL':
      return {
        ...state,
        activeModal: null,
        props: null
      }
    case 'CLOSE_MODAL_TYPE':
      if (data === state.activeModal) {
        return {
          ...state,
          activeModal: null,
          props: null
        }
      }
      return state
    case 'SET_MODAL_PROPS':
      return {
        ...state,
        props: { ...data }
      }
    default:
      return state
  }

}

export default modal
