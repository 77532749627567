import './index.sass'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './utils/polyfills'

import { initSplunkOtel } from './services/telemetry/splunk'

import React from 'react'
import { render } from 'react-dom'
import App from './containers/App'
import reduxStore from './store/reduxStore'


import('./utils/destroyer').then(destroyer => destroyer.initialize())

render(
  <App store={reduxStore} />,
  document.getElementById('root')
)

initSplunkOtel()
