const defaultState = {
  comments: {
    isLoading: true,
    comments: []
  },
  entry: {},
  files: {},
  modalForm: null,
  isEditing: false,
  isSaving: false,
  previousEntry: null,
  nextEntry: null
}


export default (state=defaultState, { type, data }) => {

  switch (type) {
    case 'SET_CURRENT_ENTRY':
      return {
        ...state,
        entry: data,
        comments: {
          isLoading: true,
          comments: [],
        },
        files: {},
      }
    case 'SET_PREVIOUS_ENTRY':
      return { ...state, previousEntry: data }
    case 'SET_NEXT_ENTRY':
      return { ...state, nextEntry: data }
    case 'SET_ENTRY_COMMENTS':
      return {
        ...state,
        comments: {
          isLoading: false,
          ...data
        }
      }
    case 'SET_ENTRY_COMMENTS_LOADING':
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: data,
        }
      }
    case 'SET_ENTRY_EDITING':
      return { ...state, isEditing: data }
    case 'SET_ENTRY_LOADING':
      return { ...state, isLoading: data }
    case 'SET_ENTRY_SAVING':
      return { ...state, isSaving: data }
    case 'UPDATE_CURRENT_ENTRY':
      return { ...state, entry: { ...state.entry, ...data }}
    case 'UPDATE_ENTRY_FIELDS':
      return { ...state, entry: {
        ...state.entry,
        fields: data
      }}

    case 'UPDATE_ENTRY_PAYMENT':
      return { ...state, entry: {
        ...state.entry,
        payment: {
          ...state.entry.payment,
          ...data
        }
      }}
    case 'UPDATE_ENTRY_SIGNATURE':
      return { ...state, entry: {
        ...state.entry,
        signature: {
          ...state.entry.signature,
          ...data
        }
      }}
    default:
      return state
  }

}
