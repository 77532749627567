import './DeleteFile.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getSelectedFiles } from '../../../models/files'

import { Button, Col } from '@sm/wds-react'
import { IconWarning } from '@sm/wds-icons'
import { ConfirmModal } from '../ConfirmModal'


export class DeleteFile extends React.Component {

  handleDelete = () => {
    const { dispatch, selections, fileIds, options } = this.props
    const selectedFileIds = selections.files.length > 0 ? selections.files : fileIds
    dispatch({ type: 'SET_FILE_REQUEST', data: {
      action: 'delete_all_files',
      fileIds: selectedFileIds,
      status: 'pending',
      options
    }})
    this.finish()

  }

  finish = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  render() {
    const {
      t,
      files,
      fileIds,
      selections
    } = this.props

    const selectedFiles = getSelectedFiles(files, selections, fileIds)
    const fileName = (selectedFiles?.length === 1 && selectedFiles[0].filename) || ''

    const classNames = classnames(
      'delete-file-modal',
      { 'delete-multiple': selectedFiles && selectedFiles.length !== 1 }
    )

    return (
      <ConfirmModal
        size='md'
        name='delete-file-modal'
        className={classNames}
        header={(
          <span className='title-body'>
            <IconWarning />
            {selectedFiles?.length === 1 && t('delete_file_header', { fileName })}
            {selectedFiles?.length > 1 && t('delete_files_header', { count: selectedFiles.length })}
          </span>
        )}
        cancelButton={(
          <Button variant='ghost' color='secondary' id='files-delete-modal-cancel' onClick={this.finish}>
            {t('CANCEL')}
          </Button>
        )}
        okayButton={(
          <Button
            color='warning'
            id='files-delete-modal-save'
            onClick={this.handleDelete}
          >
            <span>
              {selectedFiles && selectedFiles.length > 1 ? t('DELETE_FILES') : t('DELETE_FILE')}

            </span>
          </Button>
        )}
      >
        <div className='row'>
          <Col className='confirm-column'>

            {selectedFiles && selectedFiles.length > 1 ?
              <div>
                <p>{t('delete_following_files')}</p>
                <ul>
                  {selectedFiles.map(file => (<li key={file.id}>{file.filename}</li>))}
                </ul>
              </div>
              :
              <div>
                <p>{t('delete_file')}</p>
              </div>
            }
            <p>{t('delete_file_undone_warning')}</p>
          </Col>
        </div>
      </ConfirmModal>
    )
  }
}

DeleteFile.propTypes = {
  dispatch: PropTypes.func,
  fileIds: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.object,
  options: PropTypes.object,
  selections: PropTypes.object,
  t: PropTypes.func
}

const select = state => ({
  files: state.files,
  selections: state.selections
})

DeleteFile = withTranslation('modals')(DeleteFile)
DeleteFile = connect(select)(DeleteFile)

export default DeleteFile
