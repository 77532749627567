import './UploadDetailsRow.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { ProgressBar } from '../../Progress/ProgressBar'
import { Thumbnail } from '../Thumbnail'
import { getFileType } from '../../../models/files'
import { humanFileSize } from '../../../utils/file'
import { urls } from '../../../utils/urls'
import { FILE_UPLOAD_STATUS } from '../../../constants/files'


export class UploadDetailsRow extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isCanceling: false
    }
  }
  handleCancel = () => {
    const { file, handleCancel } = this.props
    const { isCanceling } = this.state
    if (!isCanceling) {
      this.setState({ isCanceling: true })
      handleCancel(file)
    }
  }

  renderStatus = () => {
    const { t, file } = this.props
    switch (file.status) {
      case FILE_UPLOAD_STATUS.CANCELLED:
        return t('Canceled')
      case FILE_UPLOAD_STATUS.COMPLETE:
        return t('Complete')
      case FILE_UPLOAD_STATUS.ERROR:
        return t('Error')
      default:
        return (<a href={urls.FILE_MANAGER} onClick={this.handleCancel}>{t('Cancel')}</a>)
    }
  }

  renderProgress = () => {
    const { file } = this.props
    switch (file.status) {
      case FILE_UPLOAD_STATUS.CANCELLED:
        return <ProgressBar className='pt-2 pb-1' percent={0} />
      case FILE_UPLOAD_STATUS.ERROR:
        return <ProgressBar className='pt-2 pb-1' percent={50} color='warning' />
      case FILE_UPLOAD_STATUS.COMPLETE:
        return <ProgressBar className='pt-2 pb-1' percent={100} color='success' />
      default:
        return <ProgressBar className='pt-2 pb-1' percent={25} />
    }
  }

  addClassNames = () => {
    const { file } = this.props
    switch (file.status) {
      case FILE_UPLOAD_STATUS.CANCELLED:
        return 'upload-canceled'
      case FILE_UPLOAD_STATUS.ERROR:
        return 'upload-error'
      case FILE_UPLOAD_STATUS.COMPLETE:
        return 'upload-complete'
      default:
        return ''
    }
  }

  render() {
    const { file } = this.props

    const classNames = classnames(
      'upload-details-row',
      this.addClassNames()
    )

    return (
      <tr className={classNames}>
        <td className='upload-file'>
          <Thumbnail
            name={file.name}
            type={'file'}
            ext={getFileType(file)}
            isFocusEnabled={false}
          />
          <span className='filename'>
            {file.name}
          </span>
        </td>
        <td className='upload-size'>
          {humanFileSize(file.size)}
        </td>
        <td className='upload-status'>
          {this.renderStatus()}
        </td>
        <td className='upload-progress'>
          {this.renderProgress()}
        </td>
      </tr>
    )
  }
}

UploadDetailsRow.propTypes = {
  file: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default withTranslation('files')(UploadDetailsRow)
