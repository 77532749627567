const DEFAULT_CACHE_TIME = 10 * 60
const cache = {}

export const cacheUrl = (url, value, timeInSeconds=DEFAULT_CACHE_TIME) => {
  cache[url] = value

  setTimeout(() => {
    clearCacheForUrl(url)
  }, (timeInSeconds || DEFAULT_CACHE_TIME) * 1000)
}

export const getCachedValueForUrl = url => {
  return cache[url]
}

export const clearCacheForUrl = url => {
  delete cache[url]
}

export const hasCachedValueForUrl = url => {
  return !!cache[url]
}