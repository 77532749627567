import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

const Copyright = props => {
  const { t } = props

  return (
    <div>
      <span className='wds-type--muted'>
        {t('copyright')} {`© 2006-${moment().format('YYYY')}`} | &nbsp;
      </span>
      <a
        href='https://surveymonkey.com/'
        target='_blank'
        rel='noopener noreferrer'
        id='footer-SurveyMonkey'
      >
        {t('surveymonkey')}
      </a>
    </div>
  )
}

Copyright.propTypes = {
  t: PropTypes.func
}

export default withTranslation('footer')(Copyright)

