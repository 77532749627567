import './DeleteEntry.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Col,
  Typography
} from '@sm/wds-react'
import { IconWarning } from '@sm/wds-icons'

import { ConfirmModal } from '../ConfirmModal'

import { blurClick } from '../../../utils/a11y'
import { deleteEntries, deleteEntriesByEntryIds, downloadEntries } from '../../../actions/entries'
import { downloadEntry } from '../../../actions/entry'
import { exportEntries } from '../../../actions/exports'
import { EXPORT_TYPES } from '../../../constants/exports'

const initialState = {
  paymentInformationChecked: false,
  commentsAndHistoryChecked: false,
  entryDataAndAttachmentsChecked: false
}

export class DeleteEntry extends React.Component {

  handleDelete = () => {
    const { t, form, dispatch, deleteAll, entries, selections } = this.props
    const selectedEntryIds = entries.modalEntry ? [ entries.modalEntry.id ] : selections.entries
    const formId = form.form.id

    const successMessage = (
      selectedEntryIds.length > 1 ? t('entries_deleted_toast') : t('entry_deleted_toast')
    )
    const errorMessage = (
      selectedEntryIds.length > 1 ? t('delete_entries_failure_toast') : t('delete_entry_failure_toast')
    )

    if (this.areAllCheckboxesChecked()) {
      deleteAll ?
        dispatch(deleteEntries(formId, entries.queryParams, {
          loadingMessage: t('delete_entries_loading_toast'),
          successMessage,
          errorMessage
        })) :
        dispatch(deleteEntriesByEntryIds(formId, selectedEntryIds, { successMessage, errorMessage }))
      this.finish()
    }
  }

  finish = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL' })
  }

  constructor(props) {
    super(props)
    this.state = initialState
  }

  areAllCheckboxesChecked() {
    const {
      paymentInformationChecked,
      commentsAndHistoryChecked,
      entryDataAndAttachmentsChecked
    } = this.state
    return (
      paymentInformationChecked &&
      commentsAndHistoryChecked &&
      entryDataAndAttachmentsChecked
    )
  }

  downloadEntries = () => {
    const { dispatch, form, entries, selections } = this.props
    const selected = entries.modalEntry ? [ entries.modalEntry.id ] : selections.entries
    dispatch(downloadEntries(form.form, selected))
  }

  downloadEntry = () => {
    const { dispatch, form, entries, selections } = this.props
    const selected = entries.modalEntry ? [ entries.modalEntry.id ] : selections.entries
    dispatch(downloadEntry(form.form, selected[0]))
  }

  closeDeleteEntryModal = () => {
    const { dispatch } = this.props
    dispatch({ type: 'CLOSE_MODAL_TYPE', data: 'delete-entry-modal' })
  }

  downloadXlsExport = () => {
    const { dispatch, entries, form, selections } = this.props

    const selected = entries.modalEntry ? [ entries.modalEntry.id ] : selections.entries

    dispatch(exportEntries(form.form, selected, EXPORT_TYPES.ENTRIES_TO_XLS))
    this.closeDeleteEntryModal()
  }

  render() {
    const { t, count } = this.props

    const {
      paymentInformationChecked,
      commentsAndHistoryChecked,
      entryDataAndAttachmentsChecked
    } = this.state

    const classNames = classnames(
      'delete-entry-modal',
      { 'delete-multiple': count !== 1 }
    )

    return (
      <ConfirmModal
        size='lg'
        name='delete-entry-modal'
        className={classNames}
        header={(
          <span className='title-body'>
            <IconWarning />
            {t('delete_entry_header', { count })}
          </span>
        )}
        cancelButton={(
          <Button id='entries-delete-modal-cancel' variant='ghost' color='secondary' onClick={this.finish}>
            {t('CANCEL')}
          </Button>
        )}
        okayButton={(
          <Button
            color='warning'
            id='entries-delete-modal-save'
            onClick={this.handleDelete}
            disabled={!this.areAllCheckboxesChecked()}
          >
            <span>
              {count === 1 &&
                t('DELETE_ENTRY')
              }
              {count !== 1 &&
                t('DELETE_ENTRIES')
              }
            </span>
          </Button>
        )}
      >
        <div className='row'>
          <Col md={9} className='confirm-column'>
            <div>
              {t('delete_entry_has_all', { count })}
            </div>

            <h3 className='warning'>
              {t('delete_entry_undone_warning')}
            </h3>
            <p>
              {t('delete_entries_acknowledge')}
            </p>

            <p>
              <Checkbox
                checked={paymentInformationChecked}
                id='entries-delete-modal-checkbox1'
                label={t('delete_entry_payment_warning')}
                onClick={blurClick(() => {})}
                onChange={() => this.setState({ paymentInformationChecked: !paymentInformationChecked })}
              />
            </p>

            <p>
              <Checkbox
                checked={commentsAndHistoryChecked}
                id='entries-delete-modal-checkbox2'
                label={t('delete_entry_comments_warning')}
                onClick={blurClick(() => {})}
                onChange={() => this.setState({ commentsAndHistoryChecked: !commentsAndHistoryChecked })}
              />
            </p>

            <p>
              <Checkbox
                checked={entryDataAndAttachmentsChecked}
                id='entries-delete-modal-checkbox3'
                label={t('delete_entry_attachment_warning')}
                onClick={blurClick(() => {})}
                onChange={() => this.setState({ entryDataAndAttachmentsChecked: !entryDataAndAttachmentsChecked })}
              />
            </p>
          </Col>

          <Col md={3} className='download-column'>
            <p className={'download-text'}>
              {t('delete_entry_download_suggestion')}
            </p>

            <Typography className='download-text'>
              {t('Download_as')}...
            </Typography>
            <ul>
              <li>
                <Typography
                  variant='link'
                  onClick={count === 1 ? this.downloadEntry : this.downloadEntries}
                >
                  CSV
                </Typography>
              </li>
              <li>
                <Typography
                  variant='link'
                  onClick={this.downloadXlsExport}
                >
                  Excel
                </Typography>
              </li>
            </ul>
          </Col>
        </div>
      </ConfirmModal>
    )
  }
}

DeleteEntry.propTypes = {
  dispatch: PropTypes.func,
  deleteAll: PropTypes.bool,
  count: PropTypes.number,
  entries: PropTypes.object,
  selections: PropTypes.object,
  form: PropTypes.object,
  t: PropTypes.func
}

const select = state => ({
  entries: state.entries,
  selections: state.selections,
  form: state.form
})

DeleteEntry = withTranslation('modals')(DeleteEntry)
DeleteEntry = connect(select)(DeleteEntry)

export default DeleteEntry
