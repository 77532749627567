

export const getFoldersInSelection = (selections, items) => {
  return selections.filter(id => isFolder(id, items))
}

export const getSelectionCount = (selections, items) => {
  return selections.includes('all') ? items.length : selections.length
}

const isFolder = (id, items) => {
  const selectedFile = items.find(item => item.id === id)
  return selectedFile && selectedFile.type === 'folder'
}

export const isFolderSelected = (selections, items) => {
  return selections.findIndex(id => isFolder(id, items)) !== -1
}

export const isPartiallySelected = (id, type, selections, sourceItems) => {
  const sourceItem = sourceItems?.find(i => i.id === id)
  if (!sourceItem) {
    return false
  }

  let isSelected = false
  recursiveActOnSelection(type, sourceItem[type], (item) => {
    if (selections.includes(item.id)) {
      isSelected = true
    }
  })
  return isSelected
}

export const isSelected = (id, items) => {
  return items.includes(id) || items.includes('all')
}

export const recursiveActOnSelection = (type, items, cb) => {
  if (!items || items?.length === 0) {
    return
  }
  items.forEach(s => {
    cb?.(s)
    recursiveActOnSelection(type, s[type], cb)
  })
}