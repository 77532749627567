import './OperatorChain.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { Tooltip, Button } from '@sm/wds-react'

export class OperatorChain extends React.PureComponent {

  getAlternativeOperator = (operator) => {
    return operator === 'and' ? 'or' : 'and'
  }

  getClassNames = (className, node) => {
    return classnames(
      className,
      { 'extended-height': node.extendHeight }
    )
  }

  render() {
    const { t, chain, operator, handleChange } = this.props

    if (chain.length === 0) {
      return null
    }
    return (
      <div className='operator-chain'>
        {chain.map((node, i) => {
          return (
            <React.Fragment key={i}>
              <div className={this.getClassNames('upper-link', node)} />
              <Tooltip
                text={t('change_to_alternative_operator', { alternative: t(`operator_${this.getAlternativeOperator(operator)}`) })}
                placement='bottom'
              >
                <Button
                  className='operator-btn'
                  variant='text'
                  onClick={handleChange}
                >
                  {t(`operator_${operator}`)}
                </Button>
              </Tooltip>
              <div className={this.getClassNames('lower-link', node)} />
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}

OperatorChain.propTypes = {
  t: PropTypes.func,
  chain: PropTypes.arrayOf(PropTypes.object),
  operator: PropTypes.string,
  handleChange: PropTypes.func
}

export default withTranslation('entries')(OperatorChain)
